import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import { maxName, minName } from "../config/Validators";

export const AddStackCategoryRequest = (t: any): RequestProps => ({
	duplicate: [
		{
			required: true,
			message: Message(t).required.select(t("labels_subcategory")),
		},
	],
	name: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_subcategory")),
		},
		{
			min: minName,
			message: Message(t).min.string(minName, t("labels_subcategory")),
		},
		{
			max: maxName,
			message: Message(t).max.string(maxName, t("labels_subcategory")),
		},
	],
});

export const EditAllStackCategoryRequest = (t: any): RequestProps => ({
	name: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_subcategory")),
		},
		{
			min: minName,
			message: Message(t).min.string(minName, t("labels_subcategory")),
		},
		{
			max: maxName,
			message: Message(t).max.string(maxName, t("labels_subcategory")),
		},
	],
});
