import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import { Regex, maxName, maxTableValue, minRoleName } from "../config/Validators";

export const createStackRequest = (t: any): RequestProps => ({
	name: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_stack_name")),
		},
		{
			min: minRoleName,
			message: Message(t).min.string(minRoleName, t("labels_stack_name")),
		},
		{
			max: maxName,
			message: Message(t).max.string(maxName, t("labels_stack_name")),
		},
	],
	stack_modules_id: [
		{
			required: true,
			message: Message(t).required.select(t("labels_stack_category_name")),
		},
	],
	value:[
		{
			pattern:Regex.twoDecimal,
			message:"Invalid characters, allow numeric or decimal value upto 2 digits"
			//message:Message(t).regex.decimal(t("value"))
		},
		{
			max: maxTableValue,
			message: `Maximum ${maxTableValue - 3} digits & decimal value upto 2 digits.`,
		},
	],
	goal:[
		{
			pattern:Regex.twoDecimal,
			message:"Invalid characters, allow numeric or decimal value upto 2 digits"
			//message:Message(t).regex.decimal(t("goal"))
		}
	]
});
