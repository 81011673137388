import { Button, message, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { observer } from "mobx-react";
import useStore from "../../store";
import { ReactComponent as AddSvg } from "../../assets/images/plusgrey.svg";
import { ReactComponent as MenuOpenIcon } from "../../assets/images/menuOpenIcon.svg";
import { ReactComponent as MenuCloseIcon } from "../../assets/images/menuCloseIcon.svg";
import AddStackModule from "./AddStack/AddStackModule";
import EditAllStackModuleModal from "./SharedComponent/EditAllStackModuleModal";
import { ScorecardStackProps } from "../../store/ScoreCardStack/ScorecardStackStore";
import Message from "../../config/Message";
import { useTranslation } from "react-i18next";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const Dashboard: React.FC = observer(() => {
	const { t } = useTranslation();
	const {
		AUTH,
		COMPANY_PROJECT,
		GOAL_STACK,
		TEAM_STACK,
		SCORECARD_STACK,
		COMMON,
	} = useStore();
	const { collapsed, setCollapsed } = COMMON;
	const { setAddEditProjectModal, setEditProjectDetail } = COMPANY_PROJECT;
	const {
		company,
		project,
		setStackModule,
		stackModule,
		stackModuleList,
		setStackModuleCategoryId,
		stackModuleCategoryId,
		setStackType,
		setStackModuleCategoryList,
		stackType,
	} = AUTH;
	const {
		scorecardStackDetails,
		tab,
		//setTab,
		showAllData,
		scoreDataState,
	}: {
		showAllData: boolean;
		tab: "01" | "02" | "03";
		setTab: any;
		scorecardStackDetails: (data: ScorecardStackProps) => void;
		scoreDataState: 0 | 1 | 2;
	} = SCORECARD_STACK;
	const { goalDataState } = GOAL_STACK;
	const { teamStackDetails, teamDataState } = TEAM_STACK;

	const [addTemplate, setAddTemplate] = useState<any>(false);
	const [editStackModule, setEditStackModule] = useState(false);

	const openAddTemplate = () => {
		if (scoreDataState != 0 || goalDataState != 0 || teamDataState != 0) {
			message.error(Message(t).notSaved.common);
		} else {
			setAddTemplate(true);
		}
	};

	const closeAddTemplate = () => {
		setAddTemplate(false);
	};

	const closeEditStackModule = () => {
		setEditStackModule(false);
	};

	const operations = (
		<div className="tabButton">
			<div
				className={`addIcon ${
					stackModuleList && stackModuleList.length <= 0
						? `animatedAddIcon`
						: ``
				}`}
				onClick={openAddTemplate}
			>
				<AddSvg />
			</div>
			<AddStackModule
				visible={addTemplate}
				closeAddTemplate={closeAddTemplate}
			/>
			{stackModuleList && stackModuleList.length > 0 && (
				<div
					className="editIcon"
					onClick={() => {
						if (
							scoreDataState != 0 ||
							goalDataState != 0 ||
							teamDataState != 0
						) {
							message.error(Message(t).notSaved.common);
						} else {
							setEditStackModule((editStackModule) => !editStackModule);
						}
					}}
				>
					<svg
						width="19"
						height="18"
						viewBox="0 0 19 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g opacity="0.4">
							<path
								d="M17.7426 15.9533H12.0483C11.4927 15.9533 11.0409 16.4123 11.0409 16.9766C11.0409 17.5421 11.4927 18 12.0483 18H17.7426C18.2981 18 18.75 17.5421 18.75 16.9766C18.75 16.4123 18.2981 15.9533 17.7426 15.9533Z"
								fill="#676A78"
							/>
							<path
								d="M15.8706 5.66544L14.8304 6.96401C14.7256 7.09623 14.5372 7.11773 14.4071 7.01238C13.1425 5.98901 9.90435 3.36285 9.00592 2.63509C8.8747 2.52759 8.85671 2.33625 8.96253 2.20295L9.96572 0.957057C10.8758 -0.214663 12.4631 -0.322161 13.7436 0.699064L15.2145 1.87078C15.8177 2.34377 16.2198 2.96726 16.3574 3.62299C16.5161 4.3443 16.3468 5.0527 15.8706 5.66544Z"
								fill="#676A78"
							/>
						</g>
						<path
							d="M8.05902 3.90388L13.4549 8.26397C13.585 8.36824 13.6073 8.55959 13.5057 8.69288L7.10874 17.0282C6.70662 17.5431 6.11402 17.8345 5.47908 17.8452L1.98696 17.8882C1.80071 17.8904 1.63775 17.7614 1.59542 17.5765L0.801755 14.1258C0.664186 13.4916 0.801755 12.8358 1.20388 12.3306L7.63256 3.95548C7.73627 3.82111 7.9278 3.79746 8.05902 3.90388"
							fill="#676A78"
						/>
					</svg>
				</div>
			)}
		</div>
	);

	useEffect(() => {
		if (stackModuleCategoryId && stackType == "TEAM_STACK") {
			teamStackDetails(
				company.company_id,
				project.id,
				stackModule,
				stackModuleCategoryId
			);
		}

		if (stackModuleCategoryId && stackType == "SCORECARD_STACK") {
			//setTab("01");
			scorecardStackDetails({
				company_id: company.company_id,
				project_id: project.id,
				company_stack_modules_id: stackModule,
				company_stack_category_id: stackModuleCategoryId,
				scorecard_type: 1,
				display_data: showAllData ? "all" : "selected",
				type:
					tab == "01"
						? "MapView"
						: tab == "02"
						? "TableView"
						: tab == "03"
						? "GraphView"
						: "",
			});
		}
	}, [stackModule, stackModuleCategoryId]);

	const toggleCollapsed = () => {
		setCollapsed(!collapsed);
	};
	return (
		<div className="modContainerDashboard">
			<div className="headerSection">
				<Button type="primary" className="menuIcon" onClick={toggleCollapsed}>
					{collapsed ? <MenuOpenIcon /> : <MenuCloseIcon />}
				</Button>
				<h1>{project && project.name}</h1>
				{project && (company.is_owner || company.is_admin) && (
					<div
						className="settingIcon"
						onClick={() => {
							setAddEditProjectModal(true);
							setEditProjectDetail(project);
						}}
					>
						<svg
							width="21"
							height="20"
							viewBox="0 0 21 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect width="21" height="20" fill="#F5F5F5" />
							<path
								opacity="0.4"
								d="M20.2159 12.37C20.0115 12.07 19.7211 11.77 19.3446 11.58C19.0434 11.44 18.8497 11.21 18.6776 10.94C18.129 10.08 18.4517 8.95 19.3661 8.44C20.4418 7.87 20.7861 6.6 20.1621 5.61L19.4414 4.43C18.8282 3.44 17.4835 3.09 16.4185 3.67C15.4719 4.15 14.2563 3.83 13.7077 2.98C13.5356 2.7 13.4387 2.4 13.4603 2.1C13.4925 1.71 13.3634 1.34 13.1698 1.04C12.7718 0.42 12.051 0 11.255 0H9.73818C8.95288 0.02 8.23213 0.42 7.83411 1.04C7.62972 1.34 7.51138 1.71 7.5329 2.1C7.55441 2.4 7.4576 2.7 7.28548 2.98C6.73685 3.83 5.52126 4.15 4.58536 3.67C3.50961 3.09 2.17569 3.44 1.55176 4.43L0.831009 5.61C0.217835 6.6 0.562073 7.87 1.62706 8.44C2.54144 8.95 2.86417 10.08 2.32629 10.94C2.14342 11.21 1.94978 11.44 1.64858 11.58C1.28282 11.77 0.960099 12.07 0.78798 12.37C0.389954 12.99 0.411469 13.77 0.809495 14.42L1.55176 15.62C1.94978 16.26 2.69205 16.66 3.46658 16.66C3.83234 16.66 4.26263 16.56 4.60687 16.36C4.87581 16.19 5.19853 16.13 5.55353 16.13C6.61852 16.13 7.51138 16.96 7.5329 17.95C7.5329 19.1 8.52258 20 9.77045 20H11.2335C12.4706 20 13.4603 19.1 13.4603 17.95C13.4925 16.96 14.3854 16.13 15.4504 16.13C15.7946 16.13 16.1173 16.19 16.397 16.36C16.7413 16.56 17.1608 16.66 17.5373 16.66C18.3011 16.66 19.0434 16.26 19.4414 15.62L20.1944 14.42C20.5817 13.75 20.614 12.99 20.2159 12.37"
								fill="#676A78"
							/>
							<path
								d="M10.5124 12.8302C8.82343 12.8302 7.45724 11.5802 7.45724 10.0102C7.45724 8.44018 8.82343 7.18018 10.5124 7.18018C12.2013 7.18018 13.5352 8.44018 13.5352 10.0102C13.5352 11.5802 12.2013 12.8302 10.5124 12.8302"
								fill="#676A78"
							/>
						</svg>
					</div>
				)}
			</div>

			<div className="dashboardHeader">
				{editStackModule && (
					<EditAllStackModuleModal
						closeEditStackModule={closeEditStackModule}
						visible={editStackModule}
						editStackList={stackModuleList.map((stack: any, id: number) => {
							return {
								company_stack_modules_id: stack.key,
								is_deleted: stack.is_deleted ?? 0,
								name: stack.label,
								sequence: stack.sequence ?? id + 1,
							};
						})}
					/>
				)}
				<Tabs
					className="headerTabs"
					activeKey={stackModule}
					onChange={(key) => {
						if (
							scoreDataState != 0 ||
							goalDataState != 0 ||
							teamDataState != 0
						) {
							message.error(Message(t).notSaved.stack);
						} else {
							const parseKey = parseInt(key);
							setStackModule(parseKey);
							stackModuleList?.map((item: any) => {
								if (item.key == parseKey) {
									setStackType(item.stackType);
									setStackModuleCategoryList(item.categoryList);
									setStackModuleCategoryId(item.categoryList[0]?.category_id);
								}
							});
						}
					}}
					tabBarExtraContent={
						company && company.is_owner && project && operations
					}
					items={stackModuleList}
					hideAdd
					// onEdit={(d: any) => {
					// 	const parseKey = parseInt(d);
					// 	setStackModule(parseKey);
					// 	stackModuleList?.map((item: any) => {
					// 		if (item.key == parseKey) {
					// 			setStackType(item.stackType);
					// 			setStackModuleCategoryList(item.categoryList);
					// 			setStackModuleCategoryId(item.categoryList[0]?.id);
					// 		}
					// 	});
					// 	setDeleteModal(true);
					// 	setDeleteModalDetail(
					// 		"Stack Module",
					// 		deleteCompanyStackModule,
					// 		stackModule
					// 	);
					// }}
				/>
			</div>
		</div>
	);
});

export default Dashboard;
