import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { observer } from "mobx-react";
import { Button, Form } from "antd";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import useStore from "../../../store";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trashIcon.svg";
import { ReactComponent as SortIcon } from "../../../assets/images/sort.svg";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { createStackRequest } from "../../../requests/StackRequest";

interface AddProps {
	closeEditStackModule: () => void;
	visible: boolean;
	editStackList: any;
}

const EditAllStackModuleModal: React.FC<AddProps> = observer(
	({ visible, closeEditStackModule, editStackList }) => {
		const { AUTH, ROOT, COMPANY_STACK_MODULE ,SCORECARD_STACK} = useStore();
		const { fetchAuthUser,project, stackModule,stackModuleCategoryId,company } = AUTH;
		const { bulkUpdateStackModule } = COMPANY_STACK_MODULE;
		const { assignErrorToInput } = ROOT;
		const {
			scorecardStackDetails,
			tab,
			dateRange,
			showAllData
		} = SCORECARD_STACK;
		const { t } = useTranslation();
		const [stackList, setStackList] = useState<any[]>(editStackList);
		const [form] = Form.useForm();

		useEffect(() => {
			form.setFieldsValue({
				data: stackList,
			});
		}, [editStackList]);

		const handleSequenceChange = (result: any) => {
			if (result.source?.index >= 0 && result.destination?.index >= 0) {
				setStackList((prevStackList: any[]) => {
					const dragged = prevStackList.splice(result.source.index, 1)[0];
					prevStackList.splice(result.destination.index, 0, dragged);
					form.setFieldsValue({
						data: prevStackList.map((category: any, index: any) => {
							return {
								...category,
								sequence: index + 1,
							};
						}),
					});
					return prevStackList.map((stack: any, id: number) => ({
						...stack,
						sequence: id + 1,
					}));
				});
			}
		};

		return (
			<>
				<Modal
					forceRender
					title={"Edit Stack"}
					className="addEditModel"
					open={visible}
					footer={
						<Button
							htmlType="submit"
							className="btn-primary"
							style={{ width: "100%" }}
							onClick={form.submit}
						>
							Save
						</Button>
					}
					onCancel={closeEditStackModule}
				>
					<FormBox
						form={form}
						onFinish={(formData: any) => {
							bulkUpdateStackModule(formData)
								.then(() => {
									fetchAuthUser();
									closeEditStackModule();
									setTimeout(()=>{
										tab=="03" &&scorecardStackDetails({
											company_id: company.company_id,
											project_id: project.id,
											company_stack_modules_id: stackModule,
											company_stack_category_id: stackModuleCategoryId,
											from_date: dateRange.fromDate,
											to_date: dateRange.toDate,
											display_data: showAllData ? "all" : "selected",
											type:
												tab == "01"
													? "MapView"
													: tab == "02"
													? "TableView"
													: tab == "03"
													? "GraphView"
													: "",
										});
									},100)
								})
								.catch(({ data }: any) => {
									assignErrorToInput(form, data.errors);
								});
						}}
					>
						<div className="infoField editSubcategories">
							<DragDropContext onDragEnd={handleSequenceChange}>
								<Droppable droppableId="stackSequence">
									{(provided) => (
										<>
											<Form.List {...provided.droppableProps} name="data">
												{(fields) => (
													<div ref={provided.innerRef}>
														{fields.map((field, index) => (
															<Draggable
																key={index}
																draggableId={index.toString()}
																index={index}
															>
																{(innerProvided) => (
																	<div
																		ref={innerProvided.innerRef}
																		{...innerProvided.draggableProps}
																		{...innerProvided.dragHandleProps}
																		key={`EditAllStackCategoryModal-${index}`}
																	>
																		<InputBox.Text
																			name={[index, "company_stack_modules_id"]}
																			hidden
																		/>
																		<InputBox.Text
																			name={[index, "is_deleted"]}
																			hidden
																		/>
																		<InputBox.Text
																			required
																			hidden={form.getFieldValue([
																				"data",
																				index,
																				"is_deleted",
																			])}
																			name={[index, "name"]}
																			onChange={(e) => {
																				const { data } = form.getFieldsValue();
																				Object.assign(data[index], {
																					name: e.target.value,
																				});
																				setStackList(data);
																			}}
																			label={"Stack Name"}
																			addonBefore={<SortIcon />}
																			rules={createStackRequest(t).name}
																			extra={
																				<DeleteIcon
																					onClick={() => {
																						const fields =
																							form.getFieldsValue();
																						const { data } = fields;
																						Object.assign(data[index], {
																							is_deleted: 1,
																						});
																						setStackList(data);
																						form.setFieldsValue({ data });
																					}}
																				/>
																			}
																		/>
																	</div>
																)}
															</Draggable>
														))}
													</div>
												)}
											</Form.List>
											{provided.placeholder}
										</>
									)}
								</Droppable>
							</DragDropContext>

							<Button htmlType="submit" className="btn-primary" hidden>
								Save
							</Button>
						</div>
					</FormBox>
				</Modal>
			</>
		);
	}
);

export default EditAllStackModuleModal;
