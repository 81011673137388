/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import useStore from "../../../../store";
import moment from "moment";
import { displayFormats } from "../../../../config/Global";
import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
// import { MoreOutlined } from "@ant-design/icons";

const ListComponent = observer(() => {
	const { ARCHIVE, AUTH } = useStore();
	const { setupGrid, restoreArchive } = ARCHIVE;
	const { fetchAuthUser } = AUTH;
	const [archive, setArchive] = useState<any>();

	const [restoreModal, setRestoreModal] = useState(false);

	const actionRendererUp = (params: any) => {
		return (
			<div className="ArchiveBtn">
				<span className="restore">
					<Link
						to="#"
						onClick={() => {
							setArchive(params.data);
							setRestoreModal(true);
						}}
					>
						Restore
					</Link>
				</span>
				<span className="view">
					<Link to={`/archive/${params.data.id}/view`}>View</Link>
				</span>
			</div>
		);
	};

	const rowHeight = 58;

	return (
		<>
			<div style={{ height: "100vh" }}>
				<AgGridWrapper
					type="serverSide"
					rowHeight={rowHeight}
					className="ag-grid-height"
					pagination={true}
					onGridReady={setupGrid}
				>
					<AgGridColumn field="type" headerName="Type" maxWidth={600} />
					<AgGridColumn field="name" headerName="Name" maxWidth={600} />
					<AgGridColumn
						maxWidth={500}
						field="created_at"
						headerName="Date"
						cellRenderer={(data:any) => {
							return moment(data.data.created_at).format(displayFormats.DATE_FORMAT);
						}}
						filter={"agDateColumnFilter"}
					/>

					<AgGridColumn
						cellRenderer={actionRendererUp}
						maxWidth={180}
						field="action"
						headerName="Actions"
						suppressMenu={true}
						sortable={false}
					/>
				</AgGridWrapper>
			</div>
			{archive && (
				<Modal
					open={restoreModal}
					className="restoreModal"
					onCancel={() => setRestoreModal(false)}
					footer={[
						<Button
							key="delete"
							className="deleteBtn"
							onClick={() => {
								restoreArchive(archive.id).then(() => {
									fetchAuthUser();
									setRestoreModal(false);
								});
							}}
						>
							Yes, Restore{" "}
							{archive.type == "Scorecard" ? "stack category" : "" || archive.type=="Metric"?"metric":"" || archive.type=="Graph"?"Graph":""}
						</Button>,
						<Button
							onClick={() => {
								setRestoreModal(false);
							}}
							key="cancel"
							className="cancelBtn"
						>
							Cancel
						</Button>,
					]}
				>
					<div className="deleteContent">
						<span className="deleteTitle">
							Restore{" "}
							{archive.type == "Scorecard" ? "stack category":"" || archive.type=="Metric"?"metric":"" || archive.type=="Graph"?"Graph":""} ?
						</span>

						<span className="deleteMtBoxContent">
							Are you sure you want to restore this{" "}
							{archive.type == "Scorecard" ? "stack category" : "" || archive.type=="Metric"?"metric":"" || archive.type=="Graph"?"Graph":""}?
						</span>
					</div>
				</Modal>
			)}
		</>
	);
});

export default ListComponent;
