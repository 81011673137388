import React from "react";
import { useRoutes } from "react-router-dom";
import LoginAsCompany from "../views/app_pages/auth/LoginAsCompany";
import RecoverPassword from "../views/app_pages/auth/RecoverPassword";
import ResetPasswordViaLink from "../views/app_pages/auth/ResetPasswordViaLink";
import SetMyPassword from "../views/app_pages/auth/SetMyPassword";
import ReferClients from "../views/app_pages/ReferClient";
import AuthLayout from "../views/layouts/AuthLayout";
import MainLayout from "../views/layouts/MainLayout";
import Archive from "../views/app_pages/Archive";
import ViewArchive from "../views/app_pages/ViewArchive";
import Dashboard from "../views/app_pages/Dashboard";
import Login from "../views/app_pages/auth/Login";
const UserView = React.lazy(
	() => import("../views/app_pages/UserManagement/UserView")
);
const PageNotFound = React.lazy(() => import("../views/errors/PageNotFound"));
const Unauthorized = React.lazy(() => import("../views/errors/Unauthorized"));
const AllTransaction = React.lazy(
	() => import("../views/app_pages/AllTransaction")
);
const Setting = React.lazy(() => import("../views/app_pages/Setting"));
const TeamActivity = React.lazy(
	() =>
		import("../views/app_pages/StackViews/TeamStack/components/TeamActivity")
);
const EditTeam = React.lazy(
	() => import("../views/app_pages/StackViews/TeamStack/components/EditTeam")
);
export default function Router() {
	const element = useRoutes(RouterConfig);
	return element;
}

export const RouterConfig = [
	{
		element: <AuthLayout />,
		children: [
			{
				path: "/login",
				element: <Login />,
			},
			{
				path: "/login-as-company",
				element: <LoginAsCompany />,
			},
			{
				path: "/recover-password",
				element: <RecoverPassword />,
			},
			{
				path: "/reset-password/:otp",
				element: <ResetPasswordViaLink />,
			},
			{
				path: "/set-my-password/:email",
				element: <SetMyPassword />,
			},
			{
				path: "/unauthorized",
				element: <Unauthorized />,
			},
		],
	},
	{
		element: <MainLayout />,
		children: [
			{
				path: "/",
				element: <Dashboard />,
			},
			{
				path: "/dashboard",
				element: <Dashboard />,
			},
			{
				path: "/setting",
				element: <Setting />,
			},
			{
				path: "/setting/:tab",
				element: <Setting />,
			},
			{
				path: "/user-management",
				element: <UserView />,
			},
			{
				path: "/transaction-models", // for server side grid example
				element: <AllTransaction />,
			},
			{
				path: "/teamActivity",
				element: <TeamActivity />,
			},
			{
				path: "/edit-team",
				element: <EditTeam />,
			},
			{
				path: "/refer-client",
				element: <ReferClients />,
			},
			{
				path: "/archive",
				element: <Archive />,
			},
			{
				path: "/archive/:id/view",
				element: <ViewArchive />,
			},
		],
	},
	{
		path: "*",
		element: <PageNotFound />,
	},
];
