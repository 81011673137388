import React from "react";
import { observer } from "mobx-react";

const ReferClients: React.FC = observer(() => {
	return (
		<iframe
			src="https://estack.co/refer/"
			style={{
				width: "100%",
				height: "600px",
				border: "none",
				margin: "0 auto",
			}}
		></iframe>
	);
});
export default ReferClients;
