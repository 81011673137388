import React, { useEffect, useMemo, useState } from "react";
import { Segmented } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BackTo } from "../../../assets/images/backIcon.svg";
import { ReactComponent as MapViewIcon } from "../../../assets/images/mapViewIcon.svg";
import { ReactComponent as TableViewIcon } from "../../../assets/images/tableViewIcon.svg";
import { ReactComponent as GraphViewIcon } from "../../../assets/images/graphViewIcon.svg";
import { observer } from "mobx-react";
import useStore from "../../../store";
import {
	ConnectionLineType,
	Controls,
	MarkerType,
	ReactFlow,
	ReactFlowProvider,
} from "reactflow";
import CustomNode from "../StackViews/ScorecardStack/components/ScoreCardViews/MapViewNodes/CustomNode";
// import TableView from "../StackViews/ScorecardStack/components/ScoreCardViews/TableView";
//import GraphView from "../StackViews/ScorecardStack/components/ScoreCardViews/GraphView";
import moment from "moment";
import { displayFormats } from "../../../config/Global";
//import OldTableView from "../StackViews/ScorecardStack/components/ScoreCardViews/OldTableView";
import VerticalBar from "../StackViews/ScorecardStack/components/ScoreCardViews/ChartLayout/Components/VerticalBar";
import HorizontalBar from "../StackViews/ScorecardStack/components/ScoreCardViews/ChartLayout/Components/HorizontalBar";
import AdSpend from "../StackViews/ScorecardStack/components/ScoreCardViews/ChartLayout/Components/AdSpend";
import DifferenceGraph from "../StackViews/ScorecardStack/components/ScoreCardViews/ChartLayout/Components/DifferenceGraph";
import LineWithDotGraph from "../StackViews/ScorecardStack/components/ScoreCardViews/ChartLayout/Components/LineWithDotGraph";
import LineGraph from "../StackViews/ScorecardStack/components/ScoreCardViews/ChartLayout/Components/LineGraph";
import PieChart from "../StackViews/ScorecardStack/components/ScoreCardViews/ChartLayout/Components/PieChart";
import TableView from "../StackViews/ScorecardStack/components/ScoreCardViews/TableView";

const ViewArchive = observer(() => {
	const { ARCHIVE } = useStore();
	const { archiveDetails, fetchArchiveDetails, graphDetails } = ARCHIVE;

	const navigate = useNavigate();
	const params = useParams();

	const [tab, setTab] = useState("01");
	const [nodes, setNodes] = useState([]);
	const [edges, setEdges] = useState([]);
	useEffect(() => {
		fetchArchiveDetails(params.id, { type: "MapView" });
	}, []);

	useEffect(() => {
		if (archiveDetails?.project_id) {
			setNodes(JSON.parse(archiveDetails.scorecard_data).nodes);
			setEdges(JSON.parse(archiveDetails.scorecard_data).edges);
		} else {
			if (archiveDetails) {
				setNodes(JSON.parse(archiveDetails.scorecard_data)?.nodes);
				setEdges(JSON.parse(archiveDetails.scorecard_data)?.edges);
			}
		}
	}, [archiveDetails]);
	useEffect(()=>{
		if(archiveDetails?.type==="03"){
			setTab("03")
		}else{
			setTab("01")
		}
	},[graphDetails])

	const items = [
		{
			value: "01",
			label: (
				<span>
					<MapViewIcon /> Map View
				</span>
			),
		},
		{
			value: "02",
			label: (
				<span>
					<TableViewIcon /> Table View
				</span>
			),
		},
		
	];
	const items1=[
		{
			value: "03",
			label: (
				<span>
					<GraphViewIcon /> Graph View
				</span>
			),
		},
	]

	const nodeTypes = useMemo(
		() => ({
			custom: (props: any) => <CustomNode mode={"view"} {...props} />,
		}),
		[]
	);

	const onFitView = () => {
		return true;
	};

	return (
		<>
			<div className="modContainerDashboard restoreContainer">
				<div className="restoreLayout">
					{/* <PageHeader
				title={"ScoreCard Stack,Trade Show"}
				className="restoreHeading"
				extra={
					<div
						className="backTo"
						style={{marginLeft:"0"}}
						onClick={() => {
							navigate("/client-management");
						}}
					>
						<BackTo />
					</div>

				}
				
			/> */}
					<div className="restoreHeading">
						<div
							className="backTo"
							style={{ marginLeft: "0" }}
							onClick={() => {
								navigate("/archive");
							}}
						>
							<BackTo />
						</div>
						<div className="restoreHeader">
							<h1>
								{archiveDetails?.stack_name},{archiveDetails?.category_name}
							</h1>
							<p>
								{moment(archiveDetails?.created_at).format(
									displayFormats.DATE_FORMAT
								)}
							</p>
						</div>
					</div>
					<Segmented
						value={tab}
						options={archiveDetails?.type!="03"?items:items1}
						onChange={(tab: any) => {
							fetchArchiveDetails(params.id, {
								type: `${
									tab == "01"
										? "MapView"
										: tab == "02"
										? "TableView"
										: tab == "03"
										? "GraphView"
										: ""
								}`,
							}).then(() => {
								setTab(tab);
							});
						}}
					/>
				</div>
				{/* {tab == "03" && <GraphView nodes={nodes} />} */}
				{tab == "02" && (archiveDetails?.type=="02"||"01") && (
					<TableView edges={edges} mode={"view"} nodes={nodes} />
				)}
				
				{/* {tab == "01" && (
				<div className="mainSectionWrap" style={{ backgroundColor: "red" }}>
					<MapView mode={"view"} nodes={nodes} edges={edges} />
				</div>
			)} */}

				{tab === "03" && archiveDetails?.type=="03" && (
					<div className="mainGraphViewContainer">
						{graphDetails?.map((d: any) => {
							if (d?.bar_name == 1) {
								return (
									<div key={d?.graph_id}>
										<VerticalBar graphAxes={d} />
									</div>
								);
							} else if (d?.bar_name == 2) {
								return (
									<div key={d?.graph_id}>
										<HorizontalBar graphAxes={d} />
									</div>
								);
							} else if (d?.bar_name == 3) {
								return (
									<div key={d?.graph_id}>
										<AdSpend graphAxes={d} />
									</div>
								);
							} else if (d?.bar_name == 4) {
								return (
									<div key={d?.graph_id}>
										<DifferenceGraph graphAxes={d} />
									</div>
								);
							} else if (d?.bar_name == 5) {
								return (
									<div key={d?.graph_id}>
										<LineWithDotGraph graphAxes={d} />
									</div>
								);
							} else if (d?.bar_name == 6) {
								return (
									<div key={d?.graph_id}>
										<LineGraph graphAxes={d} />
									</div>
								);
							} else if (d?.bar_name == 7) {
								return (
									<div key={d?.graph_id}>
										<PieChart graphAxes={d} />
									</div>
								);
							}
						})}
					</div>
				)}

				{tab == "01" && (archiveDetails?.type=="01"||"02") && (
					<div className="mainSectionWrap">
						<ReactFlowProvider>
							<div
								style={{ height: "80vh" }}
								className="horizontalFlow reactflow-wrapper"
							>
								<ReactFlow
									nodes={[
										...nodes.filter(
											(node: any) =>
												node?.position != undefined &&
												node?.type != "spacer" &&
												(node?.data?.type == "metricBoxGroup" ||
													node?.data?.type == "dynamicGroup")
										),
										...nodes
											.filter(
												(node: any) =>
													node?.position != undefined &&
													node?.data?.type != "metricBoxGroup" &&
													node?.type != "spacer" &&
													node?.data?.type != "dynamicGroup"
											)
											.sort(
												(a: any, b: any) => a?.position?.y - b?.position?.y
											),
									]}
									edges={edges}
									fitView
									nodeTypes={nodeTypes}
									panOnScroll={true}
									// elementsSelectable={mode == "edit"}
									nodesConnectable={false}
									nodesDraggable={false}
									defaultEdgeOptions={{
										type: ConnectionLineType.SimpleBezier,
										markerEnd: { type: MarkerType.Arrow },
									}}
									deleteKeyCode={null}
								>
									<Controls showInteractive={false} onFitView={onFitView} />
								</ReactFlow>
							</div>
						</ReactFlowProvider>
					</div>
				)}
			</div>
		</>
	);
});

export default ViewArchive;
