import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import Config from "../../config/Config";
import { convertTextToID } from "../../config/Global";
import CommonStore from "../CommonStore/CommonStore";
import RootStore from "../RootStore/RootStore";

export type ArchiveProps = {
	id: number;
	type: string;
	company_id: number;
	project_id: number;
	name: string;
	company_stack_modules_id: number;
	company_stack_category_id: number;
	scorecard_stack_id: number;
	node_id: string;
	created_at: string;
};

export type AddArchiveProps = {
	company_id: number;
	type: 1 | 2 | 3;
	project_id: number;
	company_stack_modules_id: number;
	company_stack_category_id: number;
	scorecard_stack_id: number;
	node_id?: string;
	graph_id?:string
};

export class ArchiveStore {
	archiveList?: ArchiveProps[];
	archiveDetails?: ArchiveProps;
	public grid?: any;
	private commonStore: CommonStore;
	private rootStore: RootStore;
	graphDetails?:any

	constructor(commonStore: CommonStore) {
		makeAutoObservable(this);
		this.commonStore = commonStore;
		this.rootStore = new RootStore();
	}

	private setGrid = (value?: any) => {
		this.grid = value;
	};

	setArchiveList = (list: ArchiveProps[]) => {
		this.archiveList = list;
	};

	setArchiveDetails = (data?: ArchiveProps) => {
		this.archiveDetails = data;
	};

	setGraphDetails=(data?:any)=>{
		this.graphDetails=data
	}
	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		const dataSource = this.createDataSource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(dataSource);
	};

	public changeFilterAndSort = (params: any) => {
		if (params.filterModel && params.filterModel["is_active"]) {
			params.filterModel["is_active"].values = convertTextToID(
				params.filterModel["is_active"],
				this.commonStore.statusFilter
			);
		}
	};

	private createDataSource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				this.changeFilterAndSort(params.request);
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchArchiveList(payload).then((data) => {
					params.success({
						rowData: data.data?.rows,
						rowCount: data.data?.count,
					});
					if (data.data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	fetchArchiveList = async (payload?: any): Promise<any> => {
		return await axios.post(API_URL.ARCHIVE.LIST, payload).then(({ data }) => {
			this.setArchiveList(data.data.rows);
			return data;
		});
	};

	saveArchive = async (newData: AddArchiveProps) => {
		return await axios.post(API_URL.ARCHIVE.SAVE, newData).then(({ data }) => {
			return data;
		});
	};

	restoreArchive = async (id: number) => {
		return await axios.delete(API_URL.ARCHIVE.RESTORE(id)).then(({ data }) => {
			this.setupGrid(this.grid)
			return data;
		});
	};

	fetchArchiveDetails = async (id: number, payload: any) => {
		this.setArchiveDetails();
		return await axios
			.post(API_URL.ARCHIVE.DETAILS(id), payload)
			.then(({ data }) => {
				this.setArchiveDetails(data.data);
				this.setGraphDetails(data.data?.graphNewData)
				return data;
			}).catch(()=>{
				this.setArchiveDetails();
			});
	};
}
