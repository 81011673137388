import moment from "moment";
import React from "react";
import { NodeToolbar, Position, useStore as FlowStore } from "reactflow";
import {
	createNewNode,
	displayFormats,
	NewNodeProps,
} from "../../../../../../../config/Global";
import useStore from "../../../../../../../store";

const selectedNodesSelector = (state: any) => {
	return Array.from(state.nodeInternals.values()).filter(
		(node: any) => node.selected
	);
};

export default function MultiSelectionToolbar({
	nodes,
	setNodes,
	reactFlowInstance,
}: any) {
	const selectedNodes = FlowStore(selectedNodesSelector);
	const isVisible = selectedNodes.length > 1;
	const { GOAL_STACK } = useStore();
	const { setGoalDataState } = GOAL_STACK;

	const handleGroup = () => {
		const position: any = {};
		let maxWidth: any = 0;
		let maxHeight = 0;
		const groupId = `dynamicGroup-${moment()
			.utc()
			.format(displayFormats.NODE_DATE_TIME_FORMAT)}`;

		const x = { max: 0, min: 0 };
		const y = { max: 0, min: 0 };

		selectedNodes.map((node: any) => {
			if (!position?.x || (position?.x && node.position.x < position?.x)) {
				position.x = node.position.x;
				x.min = node.position.x;
			}

			if (!position?.y || (position?.y && node.position.y < position?.y)) {
				position.y = node.position.y;
				y.min = node.position.y;
			}

			if (node.width > maxWidth) {
				maxWidth = node.width;
			}

			if (node.height > maxHeight) {
				maxHeight = node.height;
			}

			if (!x.max || (x.max && node.position.x > position?.x)) {
				x.max = node.position.x;
			}

			if (!y.max || (y.max && node.position.y > position?.y)) {
				y.max = node.position.y;
			}
			return "";
		});
		setNodes(() => {
			const defaultValue = 25;
			let groupPositionY = defaultValue;
			let totalNodeHeight = defaultValue;

			const parentNodes: string[] = [];
			nodes.map((node: any) => {
				if (
					selectedNodes.findIndex((selected: any) => selected.id === node.id) >=
						0 &&
					node.data.type !== "dynamicGroup" &&
					node.type !== "group" &&
					reactFlowInstance
				) {
					totalNodeHeight +=
						defaultValue + reactFlowInstance.getNode(node.id).height;
					if (node?.parentNode) {
						if (
							nodes
								.filter(
									(curr: any) =>
										selectedNodes.findIndex(
											(selected: any) => selected.id == curr.id
										) == -1
								)
								.findIndex(
									(curr: any) => curr?.parentNode == node.parentNode
								) == -1
						) {
							parentNodes.push(node.parentNode);
						}
					}
					node.parentNode = groupId;
					node.position.x = defaultValue;
					node.position.y = groupPositionY;
					node.selected = false;
					node.extent = "parent";
					node.data.isGrouped = true;
					if (node?.style?.width) {
						node.style.width = "auto";
					} else {
						Object.assign(node, { style: { width: "auto" } });
					}

					groupPositionY =
						groupPositionY +
						defaultValue +
						reactFlowInstance?.getNode(node.id).height;
				}
				return node;
			});

			const newNodeProps: NewNodeProps = {
				nodeId: groupId,
				nodeType: `default`,
				xPos: position.x,
				yPos: position.y,
				height: totalNodeHeight,
				width: 250,
				targetPosition: Position.Top,
				sourcePosition: Position.Bottom,
				metricType: `dynamicGroup`,
				metricId: groupId,
			};

			const newNodes = [createNewNode(newNodeProps)].concat(nodes);
			return newNodes
				.filter((node: any) => parentNodes.findIndex((id) => id == node.id) < 0)
				.sort((a: any, b: any) =>
					a?.data?.type != "dynamicGroup" && a?.data?.type != "metricBoxGroup"
						? a?.position?.y - b?.position?.y
						: Number.NEGATIVE_INFINITY
				);
		});
		setGoalDataState(1);
	};

	return (
		<NodeToolbar
			nodeId={selectedNodes.map((node: any) => node.id)}
			isVisible={isVisible}
		>
			<button onClick={handleGroup}>Group</button>
		</NodeToolbar>
	);
}
