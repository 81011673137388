import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import useStore from "../../../store";
import { Outlet, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import SidebarView from "./Sidebar/SidebarView";
import AppHeaderView from "./AppHeader/AppHeaderView";
import Scrollbars from "react-custom-scrollbars-2";
import AddEditCompanyProject from "./Sidebar/components/AddEditCompanyProject";
import DeleteModalComponent from "../../app_pages/SharedComponent/DeleteModalComponent";

const MainLayout: React.FC = observer(() => {
	const [collapsed, SetCollapsed] = useState<boolean>(false);
	const { AUTH, COMPANY_PROJECT, COMMON } = useStore();
	const { token } = AUTH;
	const { deleteModal } = COMMON;
	const { createCompanyProject } = COMPANY_PROJECT;
	const navigate = useNavigate();

	useEffect(() => {
		if (!token) {
			navigate("/login");
		}
	}, [token, history]);

	return (
		<Layout className={`main__page__wrapper has__appsidebar`}>
			<SidebarView collapsed={collapsed}></SidebarView>
			{createCompanyProject && <AddEditCompanyProject />}
			{deleteModal && <DeleteModalComponent />}
			<Scrollbars autoHeight autoHeightMin={"100vh"} autoHeightMax={"100vh"}>
				<Layout.Content className="main__page__content">
					<AppHeaderView collapsed={collapsed} SetCollapsed={SetCollapsed} />
					<Layout.Content className="mainAppContent">
						<Outlet />
					</Layout.Content>
				</Layout.Content>
			</Scrollbars>
		</Layout>
	);
});

export default MainLayout;
