import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export type SaveGoalStackProps = {
	company_id: number;
	project_id: number;
	company_stack_modules_id: number;
	company_stack_category_id: number;
	stack_data: any;
};

export type GoalStackDetailsProps = {
	company_id: number;
	project_id: number;
	company_stack_modules_id: number;
	company_stack_category_id: number;
};

export default class GoalStackStore {
	public goalStackData?: any;
	public userAccessList?: any;
	public stackTableId?: number;
	goalDataState?: 0 | 1 | 2 = 0;

	constructor() {
		makeAutoObservable(this);
	}

	setGoalDataState = (state: 0 | 1 | 2) => {
		this.goalDataState = state;
	};

	setGoalStackData = (stackData?: any) => {
		this.goalStackData = stackData;
	};

	setUserAccessList = (newList?: any) => {
		this.userAccessList = newList;
	};

	goalStackDetails = async (payload: GoalStackDetailsProps) => {
		this.setGoalStackData();
		this.setUserAccessList();
		return await axios
			.post(API_URL.GOAL_STACK.DETAILS, payload)
			.then(({ data }) => {
				this.setGoalStackData(data.data.stack_data);
				this.setUserAccessList(data.data.userAccess);
				return data;
			});
	};

	saveGoalStack = async (payload: SaveGoalStackProps) => {
		return await axios
			.post(API_URL.GOAL_STACK.SAVE, payload)
			.then(({ data }) => {
				return data;
			});
	};
}
