import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { AddStackCategoryRequest } from "../../../../../requests/AddStackCategoryRequest";
import useStore from "../../../../../store";

export type AddProps = {
	closeAddTemplate: () => void;
	form: any;
	// onFinish: any;
	// privileges?: any;
	// viewActive?: any;
};

const FormComponent: React.FC<AddProps> = ({ closeAddTemplate, form }) => {
	const { AUTH, COMPANY_STACK_MODULE_CATEGORY, ROOT } = useStore();
	const { fetchAuthUser, company, project, stackModule } = AUTH;
	const { createCompanyStackCategory } = COMPANY_STACK_MODULE_CATEGORY;
	const { assignErrorToInput } = ROOT;
	const {t} = useTranslation()

	return (
		<>
			<FormBox
				form={form}
				onFinish={(formData: any) => {
					formData.company_id = company.company_id;
					formData.project_id = project.id;
					formData.company_stack_modules_id = stackModule;
					createCompanyStackCategory(formData)
						.then(() => {
							fetchAuthUser();
							closeAddTemplate();
						})
						.catch(({ data }: any) => {
							assignErrorToInput(form, data.errors);
						});
				}}
			>
				<div className="infoField">
					<InputBox.Text
						rules={AddStackCategoryRequest(t).name}
						required
						name="name"
						label={"Subcategory Name"}
					/>
					<Button htmlType="submit">Create Subcategory</Button>
				</div>
			</FormBox>
		</>
	);
};

export default FormComponent;
