import { RcFile } from "antd/lib/upload";
import moment from "moment";
import CryptoJS from "crypto-js";
import dagre from "dagre";
import { debounce } from "lodash";

export const displayFormats = {
	DATE_FORMAT: "MM/DD/YYYY",
	TIME_FORMAT: "HH:mm",
	DATE_TIME_FORMAT: "MM/DD/YYYY HH:mm",
	POST_DATE_FORMAT: "YYYY-MM-DD",
	POST_TIME_FORMAT: "HH:mm",
	POST_DATE_TIME_FORMAT: "YYYY-MM-DD HH:mm",
	NODE_DATE_TIME_FORMAT: "YYYY-MM-DD-HH-mm-ss",
	GRAPH_VIEW_DATE_FORMAT: "MM/DD/YY",
};

export const CONSTANT = {
	API_KEY: "secretapikey",
	COLOR: {
		GREEN: "#9feb59",
		RED: "#e8524a",
		YELLOW: "#f0d83e",
	},
	GRID_MIN_HEIGHT: {
		INDIVIDUAL_CHART : 1,
		OTHER_CHART : 2
	},
	GRID_MIN_WIDTH: {
		INDIVIDUAL_CHART : 3,
		OTHER_CHART : 5
	}
};

export const uploadedFileOnPreview = async (file: any) => {
	let src = file.url as string;
	if (!src) {
		src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj as RcFile);
			reader.onload = () => resolve(reader.result as string);
		});
	}

	const image = new Image();
	image.src = src;
	const imgWindow: any = window.open(src);
	imgWindow.document.write(image.outerHTML);
};

const insertAt = (array: any[], index: number, ...elementsArray: any[]) => {
	array.splice(index, 0, ...elementsArray);
};

export const convertTextToID = (
	textArray: any,
	mainArray: any,
	textKey = "name",
	idKey = "id"
) => {
	const newArray: any = [];
	if (textArray && textArray.values && textArray.values.length > 0) {
		textArray.values.forEach((x: any) => {
			const temp = mainArray.find((y: any) => y[textKey] === x);
			if (x && temp) {
				newArray.push(temp[idKey]);
			} else {
				insertAt(newArray, 0, x);
			}
		});
	}

	return newArray;
};

export const copyTextToClipboard = (textToCopy: any) => {
	if (navigator.clipboard && window.isSecureContext) {
		// navigator clipboard api method'
		return navigator.clipboard.writeText(textToCopy);
	} else {
		// text area method
		const textArea = document.createElement("textarea");
		textArea.value = textToCopy;
		// make the textarea out of viewport
		textArea.style.position = "fixed";
		textArea.style.left = "-999999px";
		textArea.style.top = "-999999px";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		return new Promise<void>((res, rej) => {
			// here the magic happens
			document.execCommand("copy") ? res() : rej();
			textArea.remove();
		});
	}
};

export type NewNodeProps = {
	nodeId: string;
	to_date?: string;
	from_date?: string;
	nodeType: string;
	xPos?: number;
	yPos?: number;
	metricType: string;
	metricId: string;
	metricCategory?: string;
	metricNodeType?: string;
	targetPosition?: string;
	sourcePosition?: string;
	formatOfMatrix?: any;
	width?: number;
	height?: number | string;
	parentNode?: string;
	isChild?: boolean;
	metricName?: string;
	expression?: string;
	expression_ids?: number[];
	expression_readable?: string;
	expression_data?: string;
	zIndex?: number;
	isGrouped?: boolean;
	setLayout?: boolean;
};

export const createNewNode = (data: NewNodeProps) => {
	return {
		id: data.nodeId,
		sequence: Number.MAX_SAFE_INTEGER,
		type: data.nodeType,
		...(data.setLayout && { setLayout: data.setLayout }),
		...(data.isChild && { extent: "parent" }),
		...(data.parentNode && { parentNode: data.parentNode }),
		...(data.targetPosition && { targetPosition: data.targetPosition }),
		position: {
			x: data.xPos,
			y: data.yPos,
		},
		isHidden: false,
		...(data.height &&
			data.width && {
				style: {
					height: data.height,
					width: data.width,
					...(typeof data.zIndex == "number" && { zIndex: data.zIndex }),
				},
			}),
		data: {
			id: data.metricId,
			node_id: data.nodeId,
			...(data.metricNodeType && { node_type: data.metricNodeType }),
			value: "0",
			type: data.metricType,
			from_date: data.from_date,
			to_date: data.to_date,
			...(data.metricName && { name: data.metricName }),
			avatar: null,
			category: data.metricCategory,
			...(data.metricType == "metricCategory" && {
				category_name: data.metricCategory,
			}),
			is_goal: 0,
			goal_type: 1,
			node_created_date: moment(new Date()).format(
				displayFormats.POST_DATE_FORMAT
			),
			goal_achieve_in_number: 0,
			goal: 0,
			track_goal: 0,
			auto_assign_color: 0,
			is_assigned: 0,
			assigned_to: null,
			reminder: 0,
			format_of_metric: data.formatOfMatrix,
			note: "",
			font: 1,
			font_size: 4,
			assigned_color: "noColor",
			alignment: "alignCenter",
			...(data.expression && {
				expression: data.expression,
				expression_ids: data.expression_ids,
				expression_readable: data.expression_readable,
				expression_data: data.expression_data,
			}),
			isGrouped: data?.isGrouped,
		},
	};
};

export const stringEncryption = (string: string) => {
	return CryptoJS.AES.encrypt(string, CONSTANT.API_KEY)
		.toString()
		.replace(/\+/g, "xMl3Jk")
		.replace(/\//g, "Por21Ld")
		.replace(/=/g, "Ml32");
};

export const stringDecryption = (string: string) => {
	string = string
		.replace(/xMl3Jk/g, "+")
		.replace(/Por21Ld/g, "/")
		.replace(/Ml32/g, "=");
	return CryptoJS.AES.decrypt(string, CONSTANT.API_KEY).toString(
		CryptoJS.enc.Utf8
	);
};

export const getLayoutedElements = (
	nodes: any,
	edges: any,
	direction = "LR"
) => {
	try {
		const dagreGraph = new dagre.graphlib.Graph({ compound: true });
		dagreGraph.setDefaultEdgeLabel(() => ({}));

		const isHorizontal = direction === "LR";
		dagreGraph.setGraph({ rankdir: direction });

		nodes.forEach((node: any) => {
			dagreGraph.setNode(node.id, { width: node.width, height: node.height });
		});

		edges.forEach((edge: any) => {
			dagreGraph.setEdge(edge.source, edge.target);
		});

		dagre.layout(dagreGraph);

		nodes.forEach((node: any) => {
			if (node.parentNode) {
				dagreGraph.setParent(node.id, node.parentNode);
			}

			const nodeWithPosition = dagreGraph.node(node.id);
			node.targetPosition = isHorizontal ? "left" : "top";
			node.sourcePosition = isHorizontal ? "right" : "bottom";

			// We are shifting the dagre node position (anchor=center center) to the top left
			// so it matches the React Flow node anchor point (top left).

			if (node.setLayout) {
				node.position = {
					x: nodeWithPosition.x - node.width / 2,
					y: nodeWithPosition.y - node.height / 2,
				};

				delete node.setLayout;
			}

			return node;
		});
		return { nodes, edges };
	} catch (e: any) {
		return { nodes, edges };
	}
};

export const getFromDateAndToDate = (
	scorecardType: number,
	fromDate: string,
	toDate: string,
	scorecardStartFrom: number
): any => {
	let scorecardEnds;
	switch (scorecardStartFrom) {
		case moment().day("Sunday").isoWeekday():
			scorecardEnds = moment().day("Saturday").isoWeekday();
			break;
		case moment().day("Monday").isoWeekday():
			scorecardEnds = moment().day("Sunday").isoWeekday();
			break;
		case moment().day("Tuesday").isoWeekday():
			scorecardEnds = moment().day("Monday").isoWeekday();
			break;
		case moment().day("Wednesday").isoWeekday():
			scorecardEnds = moment().day("Tuesday").isoWeekday();
			break;
		case moment().day("Thursday").isoWeekday():
			scorecardEnds = moment().day("Wednesday").isoWeekday();
			break;
		case moment().day("Friday").isoWeekday():
			scorecardEnds = moment().day("Thursday").isoWeekday();
			break;
		case moment().day("Saturday").isoWeekday():
			scorecardEnds = moment().day("Friday").isoWeekday();
			break;
		default:
			scorecardStartFrom = moment().day("Sunday").isoWeekday();
			scorecardEnds = moment().day("Saturday").isoWeekday();
	}

	let from_date, to_date;
	if (scorecardType === 1) {
		from_date = moment(fromDate).clone().isoWeekday(scorecardStartFrom);
		to_date = moment(toDate).clone().isoWeekday(scorecardEnds);
	} else if (scorecardType === 2) {
		from_date = moment(fromDate).isoWeekday(scorecardStartFrom);
		to_date = moment(toDate).endOf("week").isoWeekday(scorecardEnds);
		// eslint-disable-next-line no-constant-condition
		while (true) {
			if ((to_date.diff(from_date, "days") + 1) % 14 !== 0) {
				to_date.add(1, "week");
			} else {
				break;
			}
		}
	} else if (scorecardType === 3) {
		if (parseInt(moment(fromDate).format("D")) > 15) {
			from_date = moment(fromDate).startOf("month").add(15, "days");
		} else {
			from_date = moment(fromDate).startOf("month");
		}
		if (parseInt(moment(toDate).format("D")) > 15) {
			to_date = moment(toDate).endOf("month");
		} else {
			to_date = moment(toDate).startOf("month").add(14, "days");
		}
	} else if (scorecardType === 4) {
		from_date = moment(fromDate).startOf("month");
		to_date = moment(toDate).endOf("month");
	} else if (scorecardType === 5) {
		from_date = moment(fromDate).startOf("quarter");
		to_date = moment(toDate).endOf("quarter");
	} else if (scorecardType === 6) {
		from_date = moment(fromDate).startOf("year");
		to_date = moment(toDate).endOf("year");
	} else {
		from_date = moment(fromDate).startOf("week").isoWeekday(scorecardStartFrom);
		to_date = moment(toDate).endOf("week").isoWeekday(scorecardEnds);
	}
	return [from_date, to_date];
};

export const validateFields = debounce((form, setDisabled) => {
	form
		.validateFields()
		.then(() => {
			setDisabled(false);
		})
		.catch(() => {
			setDisabled(true);
		});
}, 500);

export const getTrendlineLinear = (projection = false) => {
	return {
		colorMin: "#0000FF",
		lineStyle: "dotted",
		width: 2,
		projection: projection,
	};
};

export const displayNumberFormat = (value: any) => {
	return new Intl.NumberFormat().format(value);
};
