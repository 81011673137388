import axios from "axios";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import API_URL from "../../config/ApiUrl";
import { CONSTANT, displayFormats } from "../../config/Global";

export type DateListProps = { from_date: string; to_date: string }[];

export type NodeEntryProps = {
	scorecard_stack_id: number;
	node_id: string;
	from_date: string;
	to_date: string;
	value: string;
	assigned_color: string;
	comment: string;
	goal: any;
};

export type ChangeValueProps = {
	scorecard_node_data_id: number;
	from_date: string;
	to_date: string;
	value: string;
	assigned_color: string;
	comment: string;
	goal: any;
};

export type ScorecardStackProps = {
	company_id: number;
	project_id: number;
	company_stack_modules_id: number;
	company_stack_category_id: number;
	from_date?: string;
	to_date?: string;
	type?: string;
	scorecard_type?: number;
	scorecard_start_from?: number;
	scorecard_data?: any;
	tab?: "01" | "02" | "03";
	display_data?: "all" | "selected";
	graph_data?: any;
};
export type DateRangeProps = {
	fromDate?: string;
	toDate?: string;
};
export type DateRangeObjProps = {
	scorecard_start_from?: number;
	scorecard_type: number;
};

interface Component1Props {
	open: () => void;
	isDrag: any;
	data_graph: any;
	labels: any;
	graphAxes: any;
	chartId: any;
}
type ComponentType = React.FunctionComponent<Component1Props>;
export default class ScorecardStackStore {
	scorecardStackData?: any;
	userAccessList?: any[];
	stackTableId?: any;
	showAllData = false;
	tab?: "01" | "02" | "03" = "01";
	dateRange: DateRangeProps = {
		fromDate: moment().format(displayFormats.POST_DATE_FORMAT),
		toDate: moment().format(displayFormats.POST_DATE_FORMAT),
	};
	dateRangeObj?: DateRangeObjProps;
	canChangeScorecardType: any = false;
	scoreDataState?: 0 | 1 | 2 = 0;
	dateList?: DateListProps;
	matricsData?: any;
	graphsData?: any;
	graphAxes?: any;
	uniqueId?: ComponentType;
	graphsId?: any;
	layoutData?: any;
	fromGraphDate?: any;
	toGraphDate?: any;
	isOnChange?: any = false;
	chartMatricData?: any;
	defaultDateChecked?: any = true;
	dateLabel?: any = "Last 6 Months";
	openDrawer?: any = false;
	editGraphData?: any;
	isId?: any = false;
	scorecardStackDetail: any;
	graphLabel?: any;

	constructor() {
		makeAutoObservable(this);
	}

	setOpenDrawer = (state?: any) => {
		this.openDrawer = state;
	};

	setDateList = (newList: any) => {
		this.dateList = newList;
	};

	setTab = (tab: "01" | "02" | "03") => {
		this.tab = tab;
	};

	setScoreDataState = (state: 0 | 1 | 2) => {
		this.scoreDataState = state;
	};

	setScorecardStackData = (data?: any) => {
		this.scorecardStackData = data;
	};

	setScorecardStackDetail = (data?: any) => {
		this.scorecardStackDetail = data;
	};

	setUserAccessList = (values?: any) => {
		this.userAccessList = values;
	};

	setStackTableId = (value?: any) => {
		this.stackTableId = value;
	};

	setShowAllData = (val: boolean) => {
		this.showAllData = val;
	};

	setDateRange = (payload: DateRangeProps) => {
		this.dateRange = payload;
	};

	setDateRangeObj = (range: DateRangeObjProps) => {
		this.dateRangeObj = range;
	};

	setCanChangeScorecardType = (val: boolean) => {
		this.canChangeScorecardType = val;
	};
	setchartMatricData = (data?: any) => {
		this.chartMatricData = data;
	};
	setMatricsData = (data: any) => {
		this.matricsData = data;
	};
	setGraphLabel = (label?: any) => {
		this.graphLabel = label;
	};
	setGraphsData = (data: any) => {
		this.graphsData = data;
	};
	setIsId = (id?: any) => {
		this.isId = id;
	};
	setEditGraphData = (data?: any) => {
		this.editGraphData = data;
	};

	setGraphsAxes = (data: any, payload: any, layoutData: any) => {
		let graphAxesData: any = [];
		if (payload?.graphSelectedDateRageData === true) {
			const updatedArray = this.graphAxes?.map((obj: any) => {
				const matchingObj1 = data?.find(
					(obj1: any) => obj1.graph_id === obj.graph_id
				);
				return matchingObj1 ? { ...obj, ...matchingObj1 } : obj;
			});
			graphAxesData = updatedArray;
			this.setIsOnChange(false);
		} else {
			graphAxesData = data;
		}		

		
			graphAxesData &&
			(graphAxesData = graphAxesData.map((d: any) => {
				let newLayout: any = {};
				const dataGrid = layoutData ? layoutData?.filter(
					(item: any) => item.i == d?.graph_id
				) : null;
				if (!d?.layout) {
					let tempLayout: any = {};
					if (d?.bar_name === 3) {
						tempLayout = {
							x: Infinity,
							y: Infinity,
							w: CONSTANT.GRID_MIN_WIDTH.INDIVIDUAL_CHART,
							h: CONSTANT.GRID_MIN_HEIGHT.INDIVIDUAL_CHART,
							minW: CONSTANT.GRID_MIN_WIDTH.INDIVIDUAL_CHART,
							minH: CONSTANT.GRID_MIN_HEIGHT.INDIVIDUAL_CHART,
						};
					} else {
						tempLayout = {
							x: Infinity,
							y: Infinity,
							w: CONSTANT.GRID_MIN_WIDTH.OTHER_CHART,
							h: CONSTANT.GRID_MIN_HEIGHT.OTHER_CHART,
							minW: CONSTANT.GRID_MIN_WIDTH.OTHER_CHART,
							minH: CONSTANT.GRID_MIN_HEIGHT.OTHER_CHART,
						};
					}

					newLayout = dataGrid ? dataGrid[0] : tempLayout;
				} else {
					newLayout = d?.layout;
				}
				return { ...d, layout: newLayout };
			}));

		this.graphAxes = graphAxesData;
	};
	setUniqueId = (data: any) => {
		this.uniqueId = data;
	};
	setLayoutData = (data: any) => {
		this.layoutData = data;
	};
	setGraphsId = (id: any) => {
		this.graphsId = id;
	};
	setDefaultDateChacked = (checked?: any) => {
		this.defaultDateChecked = checked;
	};
	setDateLabel = (label?: any) => {
		this.dateLabel = label;
	};
	setGraphDate = (fromDate?: any, toDate?: any) => {
		this.fromGraphDate = fromDate;
		this.toGraphDate = toDate;
	};
	setIsOnChange = (state: any) => {
		this.isOnChange = state;
	};
	fetchScorecardStackList = async () => {
		return await axios.get(API_URL.SCORECARD_STACK.INDEX).then(() => {
			//
		});
	};

	createScorecardStack = async (data: ScorecardStackProps) => {
		return await axios.post(API_URL.SCORECARD_STACK.CREATE, data).then(() => {
			//
		});
	};

	setScorecardStackDetailsState = async (data?: any) => {
		const aa = data ? JSON.parse(data.data.graph_data) : {};
		this.setGraphsData(data ? aa?.graphData : undefined);
		this.setScorecardStackDetail(data ? data.data : undefined);
		this.setMatricsData(data ? JSON.parse(data.data.scorecard_data)?.nodes : undefined);
		this.setScorecardStackData(data ? data.data.scorecard_data : undefined);
		this.setUserAccessList(data ? data.data.userAccess : undefined);
		this.setDateList(data ? data.data.dateList : undefined);
		this.setStackTableId(data ? data.data.id : undefined);
		this.setCanChangeScorecardType(data ? data.data.canChangeScorecardType : false);
	};

	scorecardStackDetails = async (payload: ScorecardStackProps) => {
		this.setScorecardStackDetailsState();
		return await axios
			.post(API_URL.SCORECARD_STACK.DETAILS, payload)
			.then(({ data }) => {
				const layoutData = data && data.data.layout_data ? JSON.parse(data.data.layout_data) : [];
				this.setScorecardStackDetailsState(data);
				this.setGraphsAxes(data?.data?.graphNewData, payload, layoutData);
				this.setLayoutData(layoutData);
				this.setDateRange({
					fromDate: data.data.scorecard_from_and_to.from_date,
					toDate: data.data.scorecard_from_and_to.to_date,
				});
				this.setDateRangeObj({
					scorecard_type: data.data.scorecard_type,
					scorecard_start_from: data.data.scorecard_start_from,
				});
			});
	};

	changeValue = async (data: ChangeValueProps) => {
		return await axios
			.post(API_URL.SCORECARD_STACK.CHANGE_VALUE, data)
			.then((data) => {
				return data;
			});
	};

	nodeEntry = async (data: NodeEntryProps) => {
		return await axios
			.post(API_URL.SCORECARD_STACK.NODE_ENTRY, data)
			.then(({ data }) => {
				return data;
			});
	};

	updateScorecardStack = async (
		id: number,
		data: {
			company_id: number;
			project_id: number;
			company_stack_modules_id: number;
			company_stack_category_id: number;
			scorecard_data: any;
		}
	) => {
		return await axios.post(API_URL.SCORECARD_STACK.UPDATE(id), data);
	};

	deleteScorecardStack = async (id: number) => {
		return await axios.delete(API_URL.SCORECARD_STACK.DELETE(id));
	};

	saveScorecardStack = async (data: ScorecardStackProps) => {
		return await axios
			.post(API_URL.SCORECARD_STACK.SAVE, data)
			.then(({ data }) => {
				this.setScorecardStackData(data.data.scorecard_data);
			});
	};
}
