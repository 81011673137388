import React, { useState } from "react";
import { Button, Form } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../../store";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import MacImg from "../../../assets/images/macDashboardImg.png";
import { ExclamationOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import LogoComponent from "../../layouts/Components/LogoComponent";

const RecoverPassword: React.FC = observer(() => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const navigate = useNavigate();
	const { AUTH, ROOT } = useStore();
	const { forgetPassword } = AUTH;
	const { assignErrorToInput } = ROOT;
	const { t } = useTranslation();

	const handleSubmit = (data: any) => {
		setSaving(true);

		forgetPassword(data)
			.then(() => {
				setSaving(false);
				navigate("/login")
			})
			.catch((e: any) => {
				setSaving(false);
				assignErrorToInput(form, e.data.errors);
			});
	};

	return (
		<section className="authPagesSection">
			<div className="authLeft">
				<div className="thumb">
					<img src={MacImg} alt="" />
				</div>
				<div className="contentWrap">
					<h1>The Entrepreneur&apos;s Stack</h1>
					<p>Take Control of Your Business and Create Radical Results.</p>
				</div>
			</div>
			<div className="authRight">
			<LogoComponent />
				<div className="formWrap">
					<h2>Forgot Password</h2>
					<FormBox form={form} onFinish={handleSubmit}>
						<div className="reminderMsg">
							<i>
								<ExclamationOutlined />
							</i>
							<span>
								Enter your email when registering to which we will send a new
								password
							</span>
						</div>
						<InputBox.Text
							name="email"
							addonAfter={t("labels_email")}
							autoFocus={true}
							size="large"
						/>

						<Button
							className="btn-primary"
							loading={saving}
							size="large"
							htmlType="submit"
						>
							Send me a new password link
						</Button>
						<div className="registerNowText">
							<p>
								<Link to="/login">Back</Link>
							</p>
						</div>
					</FormBox>
				</div>
			</div>
		</section>
	);
});

export default RecoverPassword;
