import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Button, Tooltip } from "antd";
// import { ReactComponent as MapViewIcon } from "../../../../../assets/images/mapViewIcon.svg";
// import { ReactComponent as TableViewIcon } from "../../../../../assets/images/tableViewIcon.svg";
// import { ReactComponent as GraphViewIcon } from "../../../../../assets/images/graphViewIcon.svg";
// import { ReactComponent as SettingsIcon } from "../../../../../assets/images/settingIcon.svg";
// import userPic from "../../../../../assets/images/marryjohnson.png";
// import { Bar } from "react-chartjs-2";
// import { datagraph, options } from "../temp";
// import { InputBox } from "../../../../../components/AntdAddons";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import MapView from "./GraphViews/MapView";
import {
	applyEdgeChanges,
	applyNodeChanges,
	useEdgesState,
	useNodesState,
} from "reactflow";
import AccessSetting from "./AccessSetting";
import { SaveGoalStackProps } from "../../../../../store/GoalStackStore/GoalStackStore";

type TradeShowProps = {
	mode: string;
	category: any;
};

const GoalView = observer(({ mode, category }: TradeShowProps) => {
	const { TEAM_MEMBER, AUTH, GOAL_STACK } = useStore();
	const { company, project, stackModule } = AUTH;
	const {
		saveGoalStack,
		goalStackDetails,
		goalStackData,
		userAccessList,
		goalDataState,
		setGoalDataState,
	} = GOAL_STACK;
	const { fetchTeamMembers } = TEAM_MEMBER;
	const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

	const [nodes, setNodes] = useNodesState<any>([]);
	const [edges, setEdges] = useEdgesState<any>([]);
	const [open, setOpen] = useState(false);

	let current: any;

	const updateNodeSize = async (parentNodeId: string) => {
		await setNodes((nodes: any) => {
			const defaultValue = 25;
			let groupPositionY = defaultValue;
			let totalNodeHeight = defaultValue;
			nodes.map((node: any) => {
				if (node.parentNode == parentNodeId && reactFlowInstance) {
					totalNodeHeight +=
						defaultValue + reactFlowInstance.getNode(node.id).height;
				}
			});

			return nodes.map((node: any) => {
				if (node.id == parentNodeId) {
					node.style.height = totalNodeHeight;
					node.height = totalNodeHeight;
				}
				if (node.parentNode == parentNodeId && reactFlowInstance) {
					node.position.x = defaultValue;
					node.position.y = groupPositionY;
					groupPositionY =
						groupPositionY +
						defaultValue +
						reactFlowInstance.getNode(node.id).height;
				}
				return node;
			});
		});
	};

	const onNodesChange = useCallback(
		async (changes: any, currentMode: string) => {
			setNodes((nds: any) => {
				nds.sort((a: any, b: any) =>
					a.data.type != "dynamicGroup" && a.data.type != "metricBoxGroup"
						? a.position.y - b.position.y
						: Number.NEGATIVE_INFINITY
				);
				nds.forEach((element: any) => {
					if (
						element.data.type == "dynamicGroup" ||
						element.data.type == "metricBoxGroup"
					) {
						delete element.style.backgroundColor;
					}
					return element;
				});
				if (changes[0]?.dragging) {
					current = changes[0];
					if (current?.id && nds.length) {
						const currentNode: any = nds.find(
							(node: any) => node.id == current.id
						);
						if (!currentNode?.parentNode) {
							const parentNode: any = nds.find((node: any) => {
								if (
									(node.data.type == "dynamicGroup" ||
										node.data.type == "metricBoxGroup") &&
									current?.id != node.id
								) {
									const centerX =
										currentNode?.position.x + currentNode?.width / 2;
									const centerY =
										currentNode?.position.y + currentNode?.height / 2;
									if (
										centerX > node.position.x &&
										centerX < node.position.x + node.width &&
										centerY > node.position.y &&
										centerY < node.position.y + node.height
									) {
										return true;
									}
								}
							});
							if (parentNode) {
								nds.find(
									(node: any) => node.id == parentNode.id
								).style.backgroundColor = "grey";
								nds.find((node: any) => node.id == parentNode.id).style[
									"z-index"
								] = -1;
							}
						}
					}
				} else {
					if (current?.id && nds.length) {
						const currentNode: any = nds.find(
							(node: any) => node.id == current.id
						);
						if (currentNode && !currentNode?.parentNode) {
							const centerX = currentNode?.position.x + currentNode?.width / 2;
							const centerY = currentNode?.position.y + currentNode?.height / 2;
							const parentNode = nds.find((node: any) => {
								if (
									(node.data.type == "dynamicGroup" ||
										node.data.type == "metricBoxGroup") &&
									current?.id != node.id
								) {
									if (
										centerX > node.position.x &&
										centerX < node.position.x + node.width &&
										centerY > node.position.y &&
										centerY < node.position.y + node.height
									) {
										return true;
									}
								}
							});
							if (parentNode) {
								try {
									if (parentNode.id != currentNode.id) {
										if (
											currentNode.data.type == "dynamicGroup" ||
											currentNode.data.type == "metricBoxGroup"
										) {
											nds = nds.filter(
												(node: any) => node.id !== currentNode.id
											);
											nds.map((node: any) => {
												if (
													node.parentNode == currentNode.id &&
													parentNode.id != node.id
												) {
													node.parentNode = parentNode.id;
												}
											});
										} else {
											nds.map((node: any) => {
												if (
													node.id == currentNode.id &&
													parentNode.id != node.id
												) {
													node.parentNode = parentNode.id;
													node.expandParent = true;
													node.extent = "parent";
													node.data.isGrouped = true;
													node.position.x = 5;
													node.position.y =
														parentNode?.height && currentNode.height
															? parentNode?.height - currentNode.height
															: 100;
												}
											});
										}
									}
								} catch (e: any) {
									//
								}
							}
						}

						if (
							currentNode &&
							(currentNode.data.type == "dynamicGroup" ||
								currentNode.data.type == "metricBoxGroup")
						) {
							updateNodeSize(currentNode.id);
						}
					}
				}
				return applyNodeChanges(changes, nds);
			});
			for (const item of changes) {
				if (
					item.type != "dimensions" &&
					(item.type != "position" || item?.dragging !== false) &&
					item.type != "select" &&
					currentMode == "edit"
				) {
					setGoalDataState(1);
				}
			}
		},
		[setNodes, reactFlowInstance]
	);

	const onEdgesChange = useCallback(
		(changes: any, currentMode: string) => {
			setEdges((eds) => applyEdgeChanges(changes, eds));
			for (const item of changes) {
				if (item.type != "select" && currentMode == "edit") {
					setGoalDataState(1);
				}
			}
		},
		[setEdges]
	);

	const saveStackData = () => {
		if (mode == "edit") {
			setGoalDataState(2);
			const saveData: SaveGoalStackProps = {
				company_id: company.company_id,
				project_id: project.id,
				company_stack_modules_id: stackModule,
				company_stack_category_id: category.key,
				stack_data: JSON.stringify({
					nodes,
					edges,
				}),
			};
			saveGoalStack(saveData).then(() => {
				setOpen(false);
				setGoalDataState(0);
				goalStackDetails({
					company_id: company.company_id,
					project_id: project.id,
					company_stack_modules_id: stackModule,
					company_stack_category_id: category.key,
				});
			});
		}
	};

	useEffect(() => {
		fetchTeamMembers(company.company_id);
	}, [company]);

	useEffect(() => {
		const handler = (event: BeforeUnloadEvent) => {
			event.preventDefault();
			event.returnValue = "";
		};

		if (goalDataState !== 0) {
			window.addEventListener("beforeunload", handler);
			return () => {
				window.removeEventListener("beforeunload", handler);
			};
		}
		return () => {
			//
		};
	}, [goalDataState]);

	useEffect(() => {
		if (goalStackData) {
			try {
				setNodes(JSON.parse(goalStackData).nodes);
				setEdges(JSON.parse(goalStackData).edges);
			} catch {
				setNodes([]);
				setEdges([]);
			}
		} else {
			setNodes([]);
			setEdges([]);
		}
	}, [goalStackData]);

	return (
		<>
			<div className="childTabWrap">
				<div className="childLeft">
					<div className="groupWrap">
						<small>Has Access:</small>
						<Avatar.Group maxCount={4} maxPopoverTrigger="click" size="large">
							{userAccessList &&
								userAccessList.map((user: any) => {
									const name = user.name?.split(" ");
									return (
										<Tooltip
											key={user.id + "-tooltip-user-access-goal-stack"}
											title={user.name ?? user.email}
											placement="top"
										>
											<Avatar
												style={{ backgroundColor: "#4863f6" }}
												src={user.profile_image}
											>
												{name
													? name[0].charAt(0) + name[1].charAt(0)
													: user.email.charAt(0) + user.email.charAt(1)}
											</Avatar>
										</Tooltip>
									);
								})}
						</Avatar.Group>
						{(company.is_owner || company.is_admin) && (
							<div className="editGroup">
								<div className="accessSetting">
									<AccessSetting mode={""} category={category} />
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="childRight">
					<div className="groupWrap">
						{mode == "edit" && (
							<Button
								className={`publish-btn ${goalDataState === 1 ? "active" : ""}`}
								onClick={saveStackData}
								loading={goalDataState === 2}
							>
								Save
							</Button>
						)}
					</div>
				</div>
			</div>

			<div className="mainSectionWrap">
				<MapView
					mode={mode}
					category={category}
					saveStackData={saveStackData}
					nodes={nodes}
					open={open}
					setOpen={setOpen}
					setNodes={setNodes}
					edges={edges}
					setEdges={setEdges}
					onNodesChange={onNodesChange}
					onEdgesChange={onEdgesChange}
					setReactFlowInstance={setReactFlowInstance}
					reactFlowInstance={reactFlowInstance}
					updateNodeSize={updateNodeSize}
				/>
			</div>
		</>
	);
});

export default GoalView;
