import React, { memo, useEffect, useState } from "react";
import { Avatar, Tooltip } from "antd";
import { Handle, NodeToolbar, Position } from "reactflow";
import moment from "moment";
import { displayFormats } from "../../../../../../../config/Global";
import { observer } from "mobx-react";
// import {
// 	NodeResizer,
// 	// NodeResizeControl
// } from "@reactflow/node-resizer";
import "@reactflow/node-resizer/dist/style.css";

const TradeNode = observer(
	({ data, mode, reactFlowInstance, setNodes, id: nodeId }: any) => {
		const fullNodeObject = reactFlowInstance?.getNode(nodeId);
		const [nameInitial, setNameInitial] = useState<any>({
			name: "",
			initial: "",
		});
		useEffect(() => {
			if (data.user) {
				if (data.user.name && data.user.name !== " " && data.user?.last_name) {
					setNameInitial({
						name: data.user.name + " " + data.user?.last_name,
						initial: data.user.name.charAt(0) + data.user?.last_name.charAt(0),
					});
				} else if (data.user.name && data.user.name !== " ") {
					const name = data.user.name.split(" ");
					setNameInitial({
						name: data.user.name,
						initial: name[0].charAt(0) + name[1]?.charAt(0),
					});
				} else {
					setNameInitial({
						name: data.user.email,
						initial: data.user.email.charAt(0) + data.user.email.charAt(1),
					});
				}
			}
		}, [data.user]);

		const updateGroupSize = async (parentNodeId: any) => {
			try {
				const defaultValue = 25;
				let groupPositionY = defaultValue;
				let totalNodeHeight = defaultValue;
				await setNodes((nodes: any) => {
					let totalParent = 0;
					let parentData: any;
					nodes.map((node: any) => {
						if (node.parentNode == parentNodeId) {
							totalNodeHeight +=
								defaultValue + reactFlowInstance.getNode(node.id).height;
						}
						if (node.parentNode == parentNodeId) {
							totalParent++;
						}
						if (node.id == parentNodeId) {
							parentData = node;
						}
					});

					if (totalParent == 0 && parentData) {
						return nodes.filter((node: any) => node.id !== parentData.id);
					} else {
						return nodes.map((node: any) => {
							if (node.id == parentNodeId) {
								node.style.height = totalNodeHeight;
								node.height = totalNodeHeight;
							}
							if (node.parentNode == parentNodeId && reactFlowInstance) {
								node.position.x = defaultValue;
								node.position.y = groupPositionY;

								groupPositionY =
									groupPositionY +
									defaultValue +
									reactFlowInstance.getNode(node.id).height;
							}

							return node;
						});
					}
				});
			} catch (e: any) {
				//
			}
		};

		const handleUngroup = async () => {
			let parentNodeId: any = null;
			const totalNodeIdInParent: any[] = [];
			await setNodes((nodes: any) => {
				const currentNode: any = JSON.parse(
					JSON.stringify(
						nodes.find((node: any) => {
							return node.id == data.node_id;
						})
					)
				);
				const currentParentNode: any = currentNode.parentNode
					? nodes.find((node: any) => node.id == currentNode.parentNode)
					: undefined;

				parentNodeId = currentParentNode?.id ?? null;

				nodes.map((node: any) => {
					if (node.parentNode == parentNodeId) {
						totalNodeIdInParent.push(node.id);
					}
				});

				return nodes.map((node: any) => {
					if (node.id == currentNode.id) {
						delete node.expandParent;
						const nodeIndex = totalNodeIdInParent.indexOf(node.id);
						return {
							...node,
							parentNode: undefined,
							extent: undefined,
							data: { ...node.data, isGrouped: false },
							position: {
								x: currentParentNode.position.x + currentParentNode.width + 20,
								y:
									currentParentNode.position.y +
									85 * (nodeIndex > 0 ? nodeIndex + 1 : 0),
							},
						};
					}
					return node;
				});
			});
			(await parentNodeId) && updateGroupSize(parentNodeId);
		};

		// const handleCopy = async () => {
		// 	const currentId = moment()
		// 		.utc()
		// 		.format(displayFormats.NODE_DATE_TIME_FORMAT);
		// 	let parentNodeId = null;
		// 	await setNodes((nodes: any) => {
		// 		const newNode: any = nodes.find((node: any) => node.id == data.node_id);
		// 		if (newNode) {
		// 			const tempNew = JSON.parse(JSON.stringify(newNode));

		// 			parentNodeId = tempNew?.parentNode ?? null;
		// 			switch (tempNew.data.type) {
		// 				case "goal":
		// 					tempNew.id = `${currentId}-metricBox-${tempNew.data.id}`;
		// 					tempNew.data.node_id = `${currentId}-${tempNew.data.type}-${tempNew.data.id}`;
		// 					tempNew.data.node_created_date = moment(new Date()).format(
		// 						displayFormats.POST_DATE_FORMAT
		// 					);
		// 					tempNew.position.x = tempNew.position.x + 70;
		// 					tempNew.position.y = tempNew.position.y + 70;
		// 					tempNew.selected = false;
		// 					tempNew.data.value = 0;
		// 					tempNew.data.name = tempNew.data.name + " (Copy)";

		// 					return [...nodes, tempNew];
		// 				default:
		// 					break;
		// 			}
		// 		}
		// 		return nodes;
		// 	});

		// 	(await parentNodeId) && updateGroupSize(parentNodeId);
		// };
		return (
			<div className={`react-flow__node-default`}>
				{/* <NodeResizer minWidth={200} minHeight={100} /> */}
				<Handle
					style={{ backgroundColor: "#555", border: "1px solid white" }}
					type="target"
					id="left"
					position={Position.Left}
				/>
				<Handle
					style={{ backgroundColor: "#555", border: "1px solid white" }}
					type="target"
					id="top"
					position={Position.Top}
				/>
				<Handle
					style={{ backgroundColor: "#555", border: "1px solid white" }}
					type="source"
					id="right"
					position={Position.Right}
				/>
				<Handle
					style={{ backgroundColor: "#555", border: "1px solid white" }}
					type="source"
					id="bottom"
					position={Position.Bottom}
				/>
				{mode == "edit" && (
					<NodeToolbar
						isVisible={data.toolbarVisible}
						position={data.toolbarPosition}
					>
						{fullNodeObject?.parentNode && (
							<button onClick={handleUngroup}>ungroup</button>
						)}
						{/* <button className="toolBarBtn copyBtn" onClick={()=>{
							console.log('Copy');
						}}>Copy</button> */}
					</NodeToolbar>
				)}
				{/* header */}{" "}
				{data.type == "header" && (
					<div
						className="header"
						style={{
							wordWrap: "normal",
						}}
					>
						<h2
							style={{
								margin: "auto",
								textAlign: "center",
								textTransform: "none",
							}}
						>
							{data.title}
						</h2>
					</div>
				)}
				{/* goal */}
				
				{data.type == "goal" && (
					<div className="metricBox">
						<div className="wrap">
							<div className="Title" style={!data.user && data.assigned_color == "noColor" ? {
								width: "100%",
							} : {}}>
								{data.assigned_color !== "noColor" && <div className={`status ${data.assigned_color}`}></div>}
								<h5 className={`${data.font == 1
									? "fontRoboto"
									: data.font == 2
										? "fontInter"
										: data.font == 3
											? "fontPopins"
											: "defaultFont"
									} ${data.font_size == 1
										? "size14"
										: data.font_size == 2
											? "size16"
											: data.font_size == 3
												? "size18"
												: data.font_size == 4
													? "size20"
													: data.font_size == 5
														? "size22"
														: data.font_size == 6
															? "size24"
															: ""
									} ${data.alignment}`}
									style={{
										...(!data.user&&data.assigned_color=="noColor" ? {
											paddingLeft: "0px"
										} : {}),
										wordWrap: "normal",
										...(data.isBold ? { fontWeight: "bold" } : {}),
										...(data.isItalic ? { fontStyle: "italic" } : {}),
										...(data.isUnderlined
											? { textDecoration: "underline" }
											: {}),
										...(data.isCapitalize
											? { textTransform: "capitalize" }
											: {}),
										...(data.text_color == ""
											? {}
											: data.text_color == "colorGreen"
												? { color: "#AAD880" }
												: data.text_color == "colorPink"
													? { color: "#D88080" }
													: data.text_color == "colorOrange"
														? { color: "#ec9f58" }
														: {}),
									}}
								>
									{data.name}
								</h5>
								{data.description && (
									<h6 style={{ lineHeight: 1 }}>{data.description}</h6>
								)}
							</div>
							<div className="profilePic">
								{data.avatar ? (
									<Avatar size={24} src={data.avatar} />
								) : data.assigned_to ? (
									<Tooltip title={nameInitial.name} placement="top">
										<Avatar
											size={24}
											style={{ backgroundColor: "#4863f6" }}
											src={
												data.user?.profile_image ||
													data.user?.profile_image != ""
													? data.user?.profile_image
													: null
											}
										>
											{nameInitial.initial}
										</Avatar>
									</Tooltip>
								) : (
									""
								)}
							</div>
						</div>
						<div className="goalWrap">
							<div className="date">
								{data.achievement_date
									? `Due Date: ${moment(data.achievement_date).format(
										displayFormats.DATE_FORMAT
									)}`
									: ""}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
);
export default memo(TradeNode);
