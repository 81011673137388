import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export type CompanyStackModuleProps = {
	company_id: string;
	project_id: number;
	name: string;
};

export type BulkUpdateStackProps = {
	company_stack_modules_id: number;
	is_deleted: number;
	name: string;
	sequence: number;
}[];

export type DuplicateStackProps = {
	company_id: number;
	project_id: number;
	name: string;
	company_stack_modules_id: number;
};

export class CompanyStackModuleStore {
	constructor() {
		makeAutoObservable(this);
	}

	createCompanyStackModule = async (
		data: CompanyStackModuleProps
	): Promise<any> => {
		return await axios.post(API_URL.COMPANY_STACK_MODULE.CREATE, data);
	};

	companyStackModuleDetails = async (id: number): Promise<any> => {
		return await axios.get(API_URL.COMPANY_STACK_MODULE.DETAILS(id));
	};

	updateCompanyStackModule = async (
		id: number,
		data: CompanyStackModuleProps
	): Promise<any> => {
		return await axios.post(API_URL.COMPANY_STACK_MODULE.UPDATE(id), data);
	};

	deleteCompanyStackModule = async (id: number): Promise<any> => {
		return await axios.delete(API_URL.COMPANY_STACK_MODULE.DELETE(id));
	};

	changeStatusCompanyStackModule = async (
		id: number,
		is_active: 1 | 0
	): Promise<any> => {
		return await axios.post(
			API_URL.COMPANY_STACK_MODULE.CHANGE_STATUS(id, is_active)
		);
	};

	bulkUpdateStackModule = async (data: BulkUpdateStackProps): Promise<any> => {
		return await axios
			.post(API_URL.COMPANY_STACK_MODULE.BULK_UPDATE, data)
			.then(({ data }) => {
				return data;
			});
	};

	duplicateStackModule = async (data: DuplicateStackProps) => {
		return await axios
			.post(API_URL.COMPANY_STACK_MODULE.DUPLICATE_STACK, data)
			.then(({ data }) => {
				return data;
			});
	};
}
