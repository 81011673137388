import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Button, Form } from "antd";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { observer } from "mobx-react";
import useStore from "../../../store";
import { useTranslation } from "react-i18next";
import { createStackRequest } from "../../../requests/StackRequest";
import { ReactComponent as DropdownIcon } from "../../../assets/images/downIcon.svg";
import { DuplicateStackProps } from "../../../store/CompanyStackModuleStore/CompanyStackModuleStore";

interface AddProps {
	closeAddTemplate: () => void;
	visible?: boolean;
	type?: string;
	// setType: any;
}

const AddStackModule: React.FC<AddProps> = observer(
	({ visible, closeAddTemplate }) => {
		const { COMMON, COMPANY_STACK_MODULE, AUTH, ROOT } = useStore();
		const { assignErrorToInput } = ROOT;
		const { stackModuleList } = COMMON;
		const { company, project, fetchAuthUser, user } = AUTH;
		const { createCompanyStackModule, duplicateStackModule } =
			COMPANY_STACK_MODULE;
		const [form] = Form.useForm();
		const [dropDownList, setDropDownList] = useState<any[]>([]);
		const { t } = useTranslation();

		const [duplicate, setDuplicate] = useState(false);
		const [duplicateForm] = Form.useForm();

		const openDuplicateModal = () => setDuplicate(true);
		const closeDuplicateModal = () => {
			setDuplicate(false);
			form.resetFields();
			duplicateForm.resetFields();
		};

		useEffect(() => {
			if (stackModuleList) {
				setDropDownList(stackModuleList);
			}
		}, [stackModuleList, user]);

		const handleFinish = (formData: any) => {
			const data = {
				company_id: company.company_id,
				project_id: project.id,
				name: formData.name,
				stack_modules_id: formData.stack_modules_id,
			};
			createCompanyStackModule(data)
				.then(() => {
					fetchAuthUser();
					closeAddTemplate();
					form.resetFields();
				})
				.catch(({ data }: any) => {
					assignErrorToInput(form, data?.errors);
				});
		};

		const closeAddStackModel = () => {
			closeAddTemplate();
			form.resetFields();
			duplicateForm.resetFields();
		}

		return (
			<>
				<Modal
					title={"Add Stack"}
					className="addTemplateModel"
					// width="100%"
					open={visible}
					destroyOnClose
					footer={null}
					onCancel={closeAddStackModel}
				>
					<FormBox onFinish={handleFinish} form={form}>
						<div className="infoField">
							<InputBox.Text
								required
								name="name"
								rules={createStackRequest(t).name}
								label={t("labels_stack_name")}
							/>
							<InputBox.Select
								label="Select Stack Category"
								name="stack_modules_id"
								rules={createStackRequest(t).stack_modules_id}
								options={{
									list: dropDownList,
									textKey: "name",
									valueKey: "id",
								}}
								showArrow={false}
								extra={
									<svg
										width="12"
										height="8"
										viewBox="0 0 12 8"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1 1.5L6 6.5L11 1.5"
											stroke="#4A5CFB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								}
							/>
							<Button htmlType="submit">Create Stack</Button>
							<a
								className="existingLink"
								onClick={(e) => {
									e.preventDefault();
									closeAddStackModel();
									openDuplicateModal();
								}}
							>
								Duplicate an existing
							</a>
						</div>
					</FormBox>
				</Modal>
				<Modal
					destroyOnClose
					title="Duplicate an existing"
					onCancel={closeDuplicateModal}
					footer={null}
					open={duplicate}
					className="existingModal"
				>
					<FormBox
						form={duplicateForm}
						onFinish={(formData) => {
							const duplicateStack = project?.stackModule.find(
								(stack: any) => stack.id == formData.stackModule
							);
							const payload: DuplicateStackProps = {
								company_id: company.company_id,
								company_stack_modules_id: duplicateStack.id,
								project_id: project.id,
								name: duplicateStack.name,
							};
							duplicateStackModule(payload)
								.then(() => {
									fetchAuthUser();
									closeDuplicateModal();
								})
								.catch(() => {
									closeDuplicateModal();
								});
						}}
					>
						<InputBox.Select
							label="Stack Module Name"
							name={"stackModule"}
							showArrow={false}
							extra={<DropdownIcon />}
							options={{
								list: project?.stackModule ?? [],
								textKey: "name",
								valueKey: "id",
							}}
							// rules={AddStackCategoryRequest(t).duplicate}
						/>
						<Button htmlType="submit">Create Stack Module</Button>
					</FormBox>
				</Modal>
			</>
		);
	}
);

export default AddStackModule;
