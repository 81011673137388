import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import {
	maxEmail,
	maxName,
	maxMobile,
	minEmail,
	minName,
	minMobile,
	minPassword,
	maxPassword,
} from "../config/Validators";
// import {
// 	isPossiblePhoneNumber,
// 	isValidPhoneNumber,
// } from "react-phone-number-input";

export const RegisterRequest = (t: any): RequestProps => ({
	firstname: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_first_name")),
		},
		{
			min: minName,
			message: Message(t).min.string(minName, t("labels_first_name")),
		},
		{
			max: maxName,
			message: Message(t).max.string(maxName, t("labels_first_name")),
		},
	],
	lastname: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_last_name")),
		},
		{
			min: minName,
			message: Message(t).min.string(minName, t("labels_last_name")),
		},
		{
			max: maxName,
			message: Message(t).max.string(maxName, t("labels_last_name")),
		},
	],
	email: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_email")),
		},
		{ type: "email", message: Message(t).email() },
		{
			min: minEmail,
			message: Message(t).min.string(minEmail, t("labels_email")),
		},
		{
			max: maxEmail,
			message: Message(t).max.string(maxEmail, t("labels_email")),
		},
	],
	password: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_password")),
		},
	],
});

export const LoginRequest = (t: any): RequestProps => ({
	email: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_email")),
		},
		{ type: "email", message: Message(t).email() },
		{
			min: minEmail,
			message: Message(t).min.string(minEmail, t("labels_email")),
		},
		{
			max: maxEmail,
			message: Message(t).max.string(maxEmail, t("labels_email")),
		},
	],
	password: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_password")),
		},
		{
			min: minPassword,
			message: Message(t).min.string(minPassword, t("labels_password")),
		},
		{
			max: maxPassword,
			message: Message(t).max.string(maxPassword, t("labels_password")),
		},
	],
});

export const UpdateMobileRequest = (t: any): RequestProps => ({
	mobile_no: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_mobile_no")),
		},
		{
			min: minMobile,
			message: Message(t).min.string(minMobile, t("labels_mobile_no")),
		},
		{
			max: maxMobile,
			message: Message(t).max.string(maxMobile, t("labels_mobile_no")),
		},
		// () => ({
		// 	validator(rules, value) {
		// 		if (
		// 			value &&
		// 			(!isPossiblePhoneNumber(value) || !isValidPhoneNumber(value))
		// 		) {
		// 			return Promise.reject(Message(t).regex.other(t("labels_mobile_no")));
		// 		}
		// 		return Promise.resolve();
		// 	},
		// }),
	],
});

export const ChangePasswordRequest = (t: any): RequestProps => ({
	password: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_password")),
		},
		{
			min: minPassword,
			message: Message(t).min.string(minPassword, t("labels_password")),
		},
		{
			max: maxPassword,
			message: Message(t).max.string(maxPassword, t("labels_password")),
		},
	],
	password_confirmation: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_confirm_password")),
		},
		{
			min: minPassword,
			message: Message(t).min.string(minPassword, t("labels_confirm_password")),
		},
		{
			max: maxPassword,
			message: Message(t).max.string(maxPassword, t("labels_confirm_password")),
		},
		({ getFieldValue }) => ({
			validator(_, value) {
				if (!value || getFieldValue("password") === value) {
					return Promise.resolve();
				}
				return Promise.reject(
					new Error(
						Message(t).same(t("labels_password"), t("labels_confirm_password"))
					)
				);
			},
		}),
	],
});
