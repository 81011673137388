import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import useStore from "../../../store";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import MacImg from "../../../assets/images/macDashboardImg.png";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ChangePasswordRequest } from "../../../requests/AuthRequest";
import LogoComponent from "../../layouts/Components/LogoComponent";

const ResetPasswordViaLink: React.FC = observer(() => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const navigate = useNavigate();
	const { AUTH, ROOT } = useStore();
	const { resetPasswordViaLink } = AUTH;
	const { assignErrorToInput } = ROOT;
	const { t } = useTranslation();
	const { otp }: any = useParams();

	const handleSubmit = (data: any) => {
		setSaving(true);

		resetPasswordViaLink(otp, data)
			.then(() => {
				setSaving(false);
				navigate("/login");
			})
			.catch((e: any) => {
				setSaving(false);
				assignErrorToInput(form, e.data.errors);
			});
	};

	useEffect(() => {
		//
	}, [otp]);

	return (
		<>
			{otp && (
				<section className="authPagesSection">
					<div className="authLeft">
						<div className="thumb">
							<img src={MacImg} alt="" />
						</div>
						<div className="contentWrap">
							<h1>The Entrepreneur&apos;s Stack</h1>
							<p>Take Control of Your Business and Create Radical Results.</p>
						</div>
					</div>
					<div className="authRight">
					<LogoComponent />
						<div className="formWrap">
							<h2>Reset My Password</h2>
							<FormBox form={form} onFinish={handleSubmit}>
								<InputBox.Password
									name="password"
									label={t("labels_password")}
									size="large"
									rules={ChangePasswordRequest(t).password}
								/>

								<InputBox.Password
									name="password_confirmation"
									label={t("labels_confirm_password")}
									size="large"
									rules={ChangePasswordRequest(t).password_confirmation}
								/>
								<Button
									className="btn-primary"
									loading={saving}
									size="large"
									htmlType="submit"
								>
									Reset Password
								</Button>
								<div className="registerNowText">
									<p>
										<Link to="/login">Back to login</Link>
									</p>
								</div>
							</FormBox>
						</div>
					</div>
				</section>
			)}
		</>
	);
});

export default ResetPasswordViaLink;
