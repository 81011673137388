import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { observer } from "mobx-react";
import { Button, Form } from "antd";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import useStore from "../../../store";
import { ReactComponent as DeleteIcon } from "../../../assets/images/trashIcon.svg";
import { ReactComponent as SortIcon } from "../../../assets/images/sort.svg";
import { EditAllStackCategoryRequest } from "../../../requests/AddStackCategoryRequest";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

interface AddProps {
	closeEditStackCategory: () => void;
	visible: boolean;
	editCategoryList: any;
}

const EditAllStackCategoryModal: React.FC<AddProps> = observer(
	({ visible, closeEditStackCategory, editCategoryList }) => {
		const { AUTH, COMPANY_STACK_MODULE_CATEGORY, ROOT,SCORECARD_STACK } = useStore();
		const { fetchAuthUser,company, project, stackModule,stackModuleCategoryId } = AUTH;
		const { bulkUpdateStackCategory } = COMPANY_STACK_MODULE_CATEGORY;
		const { assignErrorToInput } = ROOT;
		const {
			scorecardStackDetails,
			tab,
			dateRange,
			showAllData
		} = SCORECARD_STACK;
		const { t } = useTranslation();
		const [form] = Form.useForm();
		const [categoryList, setCategoryList] = useState<any[]>(editCategoryList);

		useEffect(() => {
			form.setFieldsValue({ data: categoryList });
		}, [editCategoryList]);

		const handleSequenceChange = (result: any) => {
			if (result.source?.index>= 0 && result.destination?.index>= 0) {
				setCategoryList((prevCategoryList: any[]) => {
					const dragged = prevCategoryList.splice(result.source.index, 1)[0];
					prevCategoryList.splice(result.destination.index, 0, dragged);
					form.setFieldsValue({
						data: prevCategoryList.map((category: any, index: any) => {
							return {
								...category,
								sequence: index + 1,
							};
						}),
					});
					return prevCategoryList.map((stack: any, id: number) => ({
						...stack,
						sequence: id + 1,
					}));
				});
			}
		};

		return (
			<>
				<Modal
					forceRender
					title={"Edit Subcategories"}
					className="addEditModel"
					open={visible}
					footer={
						<Button
							htmlType="submit"
							className="btn-primary"
							style={{ width: "100%" }}
							onClick={form.submit}
						>
							Save
						</Button>
					}
					onCancel={closeEditStackCategory}
				>
					<FormBox
						form={form}
						onFinish={(formData: any) => {
							bulkUpdateStackCategory(formData)
								.then(() => {
									fetchAuthUser();
									closeEditStackCategory();
									setTimeout(()=>{
										tab=="03" && scorecardStackDetails({
											company_id: company.company_id,
											project_id: project.id,
											company_stack_modules_id: stackModule,
											company_stack_category_id: stackModuleCategoryId,
											from_date: dateRange.fromDate,
											to_date: dateRange.toDate,
											display_data: showAllData ? "all" : "selected",
											type:
												tab == "01"
													? "MapView"
													: tab == "02"
													? "TableView"
													: tab == "03"
													? "GraphView"
													: "",
										});
									},100)
								})
								.catch(({ data }: any) => {
									assignErrorToInput(form, data.errors);
								});
						}}
					>
						<div className="infoField editSubcategories">
							<DragDropContext onDragEnd={handleSequenceChange}>
								<Droppable droppableId="stackSequence">
									{(provided) => (
										<>
										<Form.List {...provided.droppableProps} name="data">
											{(fields) => (
												<div ref={provided.innerRef}>
													{fields.map((field, index) => (
														<Draggable
															key={index}
															draggableId={index.toString()}
															index={index}
														>
															{(innerProvided) => (
																<div
																	ref={innerProvided.innerRef}
																	{...innerProvided.draggableProps}
																	{...innerProvided.dragHandleProps}
																	key={`EditAllStackCategoryModal-${index}`}
																>
																	<InputBox.Text
																		name={[index, "category_id"]}
																		hidden
																	/>
																	<InputBox.Text
																		name={[index, "is_deleted"]}
																		hidden
																	/>
																	<InputBox.Text
																		required
																		hidden={form.getFieldValue([
																			"data",
																			index,
																			"is_deleted",
																		])}
																		name={[index, "name"]}
																		onChange={(e) => {
																			const { data } = form.getFieldsValue();
																			Object.assign(data[index], {
																				name: e.target.value,
																			});
																			setCategoryList(data);
																		}}
																		label={"Sub Category Name"}
																		addonBefore={<SortIcon />}
																		rules={EditAllStackCategoryRequest(t).name}
																		extra={
																			<DeleteIcon
																				onClick={() => {
																					const fields = form.getFieldsValue();
																					const { data } = fields;
																					Object.assign(data[index], {
																						is_deleted: 1,
																					});
																					setCategoryList(data);
																					form.setFieldsValue({ data });
																				}}
																			/>
																		}
																	/>
																</div>
															)}
														</Draggable>
													))}
												</div>
											)}
										</Form.List>
										{provided.placeholder}
										</>
									)}
								</Droppable>
							</DragDropContext>

							<Button htmlType="submit" className="btn-primary" hidden>
								Save
							</Button>
						</div>
					</FormBox>
				</Modal>
			</>
		);
	}
);

export default EditAllStackCategoryModal;
