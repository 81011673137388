import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export type CompanyProjectProps = {
	id?: number;
	company_id: number;
	name: string;
	stackModule: any[];
};

export type BulkUpdateProjectProps = {
	project_id: number,
	is_deleted: number,
	name: string,
	sequence: number
  }[]

export class CompanyProjectStore {
	companyProjectList?: CompanyProjectProps[];
	companyProject?: CompanyProjectProps;
	addEditProjectModal = false;
	editProjectDetail: any;

	constructor() {
		makeAutoObservable(this);
	}

	setCompanyProjectList = (list?: CompanyProjectProps[]) => {
		this.companyProjectList = list;
	};

	setAddEditProjectModal = (open: boolean) => {
		this.addEditProjectModal = open;
	};

	setEditProjectDetail = (detail: any) => {
		this.editProjectDetail = detail;
	};

	setCompanyProject = (value?: CompanyProjectProps) => {
		this.companyProject = value;
	};

	fetchCompanyProjectList = async (): Promise<any> => {
		return await axios.get(API_URL.COMPANY_PROJECT.INDEX).then(({ data }) => {
			this.setCompanyProjectList(data.data.rows);
		});
	};

	createCompanyProject = async (data: CompanyProjectProps): Promise<any> => {
		return await axios
			.post(API_URL.COMPANY_PROJECT.CREATE, data)
	};

	companyProjectDetails = async (id: number): Promise<any> => {
		return await axios
			.get(API_URL.COMPANY_PROJECT.DETAILS(id))
			.then(({ data }) => {
				this.setCompanyProject(data);
			});
	};

	updateCompanyProject = async (id: number, body: CompanyProjectProps) => {
		return await axios
			.post(API_URL.COMPANY_PROJECT.UPDATE(id), body)
			.then(this.fetchCompanyProjectList);
	};

	deleteCompanyProject = async (id: number) => {
		return await axios
			.delete(API_URL.COMPANY_PROJECT.DELETE(id))
			.then(this.fetchCompanyProjectList);
	};

	bulkUpdateCompanyProject = async (data:BulkUpdateProjectProps)=>{
		return await axios.post(API_URL.COMPANY_PROJECT.BULK_UPDATE,data).then((data)=>{
			return data
		})
	}
}
