import { GridReadyEvent } from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";

export type ReferClientProps = {
	company_id?: number;
	first_name?: string;
	last_name?: string;
	email?: string;
	referal_code?: string;
};

export class ReferClientStore {
	referClientList?: ReferClientProps[];
	referClientDetails?: ReferClientProps;
	grid?: any;
	rootStore?: RootStore;

	constructor() {
		makeAutoObservable(this);
		this.rootStore = new RootStore();
	}
	setReferClientList = (value?: ReferClientProps[]) => {
		this.referClientList = value;
	};

	setReferClientDetails = (value?: ReferClientProps) => {
		this.referClientDetails = value;
	};

	private setGrid = (value?: any) => {
		this.grid = value;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		// const datasource = "";
	};

	// private createDataSource = (gridOptions?: GridOptions) => {
	// 	return {
	// 		gridOptions,
	// 		getRows: (params:IServerSideGetRowsParams) => {
	// 			const payload = this.rootStore?.getServerListPayload(params)
	// 			this.fetchReferClientList(payload).then({data})
	// 		}
	// 	};
	// };

	fetchReferClientList = async (): Promise<any> => {
		return await axios.get(API_URL.REFER_CLIENT.INDEX).then(({ data }) => {
			this.setReferClientList(data.data.rows);
		});
	};

	createReferClient = async (data: ReferClientProps): Promise<any> => {
		return await axios
			.post(API_URL.REFER_CLIENT.CREATE, data)
			.then(this.fetchReferClientList);
	};

	fetchReferClientDetails = async (id: number): Promise<any> => {
		return await axios
			.post(API_URL.REFER_CLIENT.DETAILS(id))
			.then(({ data }) => {
				this.setReferClientDetails(data);
			});
	};

	updateReferClient = async (
		id: number,
		data: ReferClientProps
	): Promise<any> => {
		return await axios
			.post(API_URL.REFER_CLIENT.UPDATE(id), data)
			.then(this.fetchReferClientList);
	};

	deleteReferClient = async (id: number): Promise<any> => {
		return await axios
			.delete(API_URL.REFER_CLIENT.DELETE(id))
			.then(this.fetchReferClientList);
	};
}
