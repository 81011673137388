import React, { useEffect, useState } from "react";
import { Button, message, Tabs } from "antd";
import { ReactComponent as ViewIcon } from "../../../../assets/images/viewIcon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/images/editIcon.svg";
import { ReactComponent as AddSvg } from "../../../../assets/images/plusgrey.svg";
// import { ReactComponent as CalendarIcon } from "../../../../assets/images/calendarIcon.svg";
// import { ReactComponent as RightArrow } from "../../../../assets/images/rightArrow.svg";
import EditAllStackCategoryModal from "../../SharedComponent/EditAllStackCategoryModal";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import AddTemplate from "./components/AddTemplate";
import GoalView from "./components/GoalStackTabContents";
import { GoalStackDetailsProps } from "../../../../store/GoalStackStore/GoalStackStore";
import Message from "../../../../config/Message";
import { useTranslation } from "react-i18next";

const GoalStack = observer(({ stackCategories }: any) => {
	const { AUTH, GOAL_STACK } = useStore();
	const {
		company,
		stackModuleCategoryId,
		project,
		stackModule,
		setStackModuleCategoryId,
		user,
		stackType,
	} = AUTH;
	const { goalStackDetails, goalDataState } = GOAL_STACK;
	const { t } = useTranslation();
	const [mode, setMode] = useState("view");
	const [addTemplate, setAddTemplate] = useState<any>(false);
	const [categories, setCategories] = useState();
	const [editCategoryList, setEditCategoryList] = useState<any>([]);
	const [editStackCategory, setEditStackCategory] = useState<any>(false);

	useEffect(() => {
		if (stackType == "GOAL_STACK" && stackModuleCategoryId) {
			const payload: GoalStackDetailsProps = {
				company_id: company.company_id,
				company_stack_category_id: stackModuleCategoryId,
				company_stack_modules_id: stackModule,
				project_id: project.id,
			};
			goalStackDetails(payload);
		}
	}, [stackModuleCategoryId]);

	const openAddTemplate = () => {
		if (goalDataState != 0) {
			message.error(Message(t).notSaved.common);
		} else {
			setAddTemplate(true);
		}
	};
	const closeAddTemplate = () => {
		setAddTemplate(false);
	};

	const openEditStackCategory = () => {
		if (goalDataState != 0) {
			message.error(Message(t).notSaved.common);
		} else {
			const editCategoryListArray: any = [];
			stackCategories.map((stack: any) => {
				editCategoryListArray.push({
					category_id: stack.key,
					name: stack.label,
					is_deleted: 0,
				});
			});

			setEditCategoryList(editCategoryListArray);
			setEditStackCategory(true);
		}
	};
	const closeEditStackCategory = () => {
		setEditCategoryList([]);
		setEditStackCategory(false);
	};

	const operations = (
		<div className="rightFirstTabWrap">
			{company && (
				<>
					{(company.is_owner || company.is_admin) && (
						<div className="tabButton">
							<div
								className={`addIcon ${
									stackCategories && stackCategories.length <= 0
										? `animatedAddIcon`
										: ``
								}`}
								onClick={openAddTemplate}
							>
								<AddSvg />
							</div>

							<AddTemplate
								visible={addTemplate}
								closeAddTemplate={closeAddTemplate}
							/>
							{stackCategories && stackCategories.length > 0 && (
								<div className="editIcon" onClick={openEditStackCategory}>
									<EditIcon />
								</div>
							)}
							{editStackCategory && (
								<EditAllStackCategoryModal
									visible={editStackCategory}
									closeEditStackCategory={closeEditStackCategory}
									editCategoryList={editCategoryList}
								/>
							)}
						</div>
					)}
					{(company.is_owner || company.is_admin || company.is_contributor) && (
						<div className="modeWrap">
							<Button
								onClick={() => {
									if (goalDataState != 0) {
										message.error(Message(t).notSaved.view);
									} else {
										setMode("view");
									}
								}}
								className={`${mode === "view" ? "active" : ""}`}
							>
								<ViewIcon /> View Mode
							</Button>
							<Button
								className={`${mode === "edit" ? "active" : ""}`}
								onClick={() => {
									setMode("edit");
								}}
							>
								<EditIcon /> Edit Mode
							</Button>
						</div>
					)}
				</>
			)}

			{/* {stackModuleCategoryId && tab != "03" && (
				<div className="calendarWrap">
					{/* <div className="calendarWrap">
					<InputBox.DateRangePicker
						name="startdate"
						format={displayFormats.DATE_FORMAT}
						defaultValue={[moment(), moment()]}
						onCalendarChange={(values) => {
							if (values?.[0]?.date() && values?.[1]?.date()) {
								setDateRange({
									fromDate: values?.[0]?.format(
										displayFormats.POST_DATE_FORMAT
									),
									toDate: values?.[1]?.format(displayFormats.POST_DATE_FORMAT),
								});
							}
						}}
						separator={<RightArrow />}
						label={<CalendarIcon />}
						inputReadOnly={true}
						// renderExtraFooter={() => (
						// 	<div className="BtnWrap">
						// 		<Button>Cancel</Button>
						// 		<Button className="btn-primary">Set</Button>
						// 	</div>
						// )}
					/>
				</div>
			)} */}
		</div>
	);

	useEffect(() => {
		setCategories(
			stackCategories.map((stack: any) => {
				return {
					// ...stack,
					label: stack.label,
					key: stack.key + "-stackCategory-",
					children: mode && <GoalView mode={mode} category={stack} />,
				};
			})
		);
	}, [stackCategories, mode, user]);

	return (
		<>
			<Tabs
				className="linkTab"
				activeKey={stackModuleCategoryId + "-stackCategory-"}
				tabBarExtraContent={operations}
				items={categories}
				onChange={(key: any) => {
					if (goalDataState != 0) {
						message.error(Message(t).notSaved.stack);
					} else {
						const stackCategoryId = key.split("-stackCategory-");
						setMode("view");
						setStackModuleCategoryId(Number(stackCategoryId[0]));
					}
				}}
			/>
		</>
	);
});

export default GoalStack;
