/* eslint-disable no-mixed-spaces-and-tabs */
import { Card, Checkbox, Popover } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as SettingIcon } from "../../../../../../../../assets/images/settingIcon.svg";
import { ReactComponent as DownArrow } from "../../../../../../../../assets/images/downIcon.svg";
// import { ReactComponent as ResizeIcon } from "../../../../../../../../assets/images/resizeIcon.svg";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import DateRange from "./DateRange";
import { Bar } from "react-chartjs-2";
import useStore from "../../../../../../../../store";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import chartTrendLine from "chartjs-plugin-trendline";
import { Link } from "react-router-dom";
import { CONSTANT } from "../../../../../../../../config/Global";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	chartTrendLine
);

interface ButtonProps {
	open?: any;
	isDrag?: any;
	labels?: any;
	graphAxes?: any;
	chartId?: any;
	mode?: any;
}

const VerticalBar: React.FC<ButtonProps> = observer(
	({ open, graphAxes, chartId, mode }) => {
		const { SCORECARD_STACK } = useStore();
		const [showDatePicker, setShowDatePicker] = useState(false);
		const {
			setUniqueId,
			setEditGraphData,
			setDefaultDateChacked,
			defaultDateChecked,
			setGraphLabel,
		} = SCORECARD_STACK;
		
		const [graphData, setGraphData] = useState([]);
		const [label, setLabel] = useState([]);
		const [chartData, setChartData] = useState<any>({labels:[], datasets:[]});
		const chartRef = useRef<typeof Bar | any>(null);

		useEffect(() => {
			if (graphAxes?.bar_name === 1) {
				setGraphData(graphAxes?.datasets);
				setLabel(graphAxes?.label);
				setDefaultDateChacked(graphAxes?.dateChecked);
			}
		}, [graphAxes]);

		useEffect(() => {
			setChartData(
				{
					labels: label,
					datasets: graphData,
				}
			)
		}, [label, graphData]);


		const handleDatasetToggle = (evt: any, legendItem: any) => {
			if (chartRef.current) {
				const chart = chartRef.current;
				// Toggle the visibility of the dataset
				chart.data.datasets[legendItem.datasetIndex].hidden = !chart.data.datasets[legendItem.datasetIndex].hidden;

				// Update the chart to reflect the visibility change
				chart.update();
				drawImage(chart);
			}
		};

		const options = {
			responsive: true,
			maintainAspectRatio: false,
			cornerRadius: 20,
			plugins: {
				legend: {
					position: "bottom" as const,
					onClick: handleDatasetToggle,
					labels: {
						usePointStyle: true,
						pointStyle: "circle",
					},
				},
			},
			layout: {
				padding: {
					left: 20,
					right: 20,
					top: 0,
					bottom: 0,
				},
			},
			scales: {
				x: {
					display: true,
					grid: {
						display: false,
						drawOnChartArea: false,
					},
					title: {
						display: true,
						text: graphAxes?.x_axis_name,
					},
					beginAtZero: true,
				},
				y: {
					display: graphData?.length > 0 ? true : false,
					title: {
						display: true,
						text: graphAxes?.y_axis_name,
					},
					beginAtZero: true,
				},
			},
		};
		const customOptions = {
			responsive: true,
			maintainAspectRatio: false,
			cornerRadius: 20,
			plugins: {
				legend: {
					position: "top" as const,
					display: false,
					onClick: handleDatasetToggle,
						// onClick: (evt:any, legendItem:any, legend:any) => {
						// 	//console.log(legendItem);
						// 	const index = legendItem.datasetIndex;
						// 	const ci = legend.chart;

						// 	legend.chart.data.datasets.forEach((d:any, i:any) => {
						// 	  ci.hide(i);
						// 	  d.hidden = true;
						// 	})

						// 	ci.show(index);
						// 	legendItem.hidden = false;

						// 	ci.update();
						//   },
						
					labels: {
						usePointStyle: true,
						pointStyle: "circle",
						
						generateLabels: (chart?: any) => {
							const datasets = chart.data.datasets;
							//console.log("chart", chart?.data?.datasets);
							//const defaultLabels = chart?.legend?.legendItems;
							const customLabels: any = [];
							//console.log("default label",defaultLabels)

							if (graphAxes?.tab === "Custom") {
								datasets?.map((dataset: any, datasetIndex: any) => {
									//console.log("datasetsindex",dataset)
									dataset?.label?.map((label: any, labelIndex: any) => {
										customLabels.push({
											datasetIndex,
											label,
											text: label,
											fillStyle: dataset.backgroundColor[labelIndex],
											hidden: !chart.isDatasetVisible(labelIndex),
											strokeStyle: dataset.backgroundColor[labelIndex],
										});
									});
								});
								//console.log(customLabels)
								return customLabels;
							}
							// console.log("datasets",chart.data.datasets)
						},
					},
				},
			},
			layout: {
				padding: {
					left: 20,
					right: 20,
					top: 0,
					bottom: 0,
				},
			},
			scales: {
				x: {
					display: true,
					grid: {
						display: false,
						drawOnChartArea: false,
					},
					title: {
						display: true,
						text: graphAxes?.x_axis_name,
					},
					beginAtZero: true,
				},
				y: {
					display: true,
					title: {
						display: true,
						text: graphAxes?.y_axis_name,
					},
					beginAtZero: true,
				},
			},
		};
		const onChange = (e: CheckboxChangeEvent) => {
			setDefaultDateChacked(e.target.checked);
		};
		const title = (
			<div>
				<Checkbox onChange={onChange} checked={defaultDateChecked}>
					Set As Default
				</Checkbox>
			</div>
		);
		const content = (
			<div>
				<DateRange chartId={chartId} setShowDatePicker={setShowDatePicker} />
			</div>
		);
		const openDrawer = (id: any) => {
			open(id);
			setUniqueId(chartId);
			setEditGraphData(graphAxes?.datasets);
			setGraphLabel(graphAxes?.label);
		};

		const handleVisibleChange = (newVisible: any) => {
			setShowDatePicker(newVisible);
		};

		const drawImage = (chart: any) => {
			// console.log('drawImage chart', chart);
			const ctx = chart.ctx;
			const circleSize = 8.5;

			// Iterate through each dataset and draw an image at the bottom of each bar
			chart.data.datasets.forEach((dataset: any, ind: number) => {
				// console.log('dataset', dataset);
				if (!dataset.hidden) {
					dataset.data.forEach((value: any, index: number) => {
						if (dataset?.assigned_color) {
							const xPos = chart.getDatasetMeta(ind).data[index].x - circleSize / 2;
							const yTopPos = chart.getDatasetMeta(ind).data[index].y;
							const yPos = chart.scales.y.bottom;

							let assignColor = "";
							if (dataset?.assigned_color[index] === "colorOrange") {
								assignColor = CONSTANT.COLOR.YELLOW;
							}
							if (dataset?.assigned_color[index] === "colorPink") {
								assignColor = CONSTANT.COLOR.RED;
							}
							if (dataset?.assigned_color[index] === "colorGreen") {
								assignColor = CONSTANT.COLOR.GREEN;
							}

							if (assignColor !== "") {
								ctx.fillStyle = assignColor;
								ctx.beginPath();
								// ctx.arc(xPos, yPos, circleSize, 0, 2 * Math.PI);
								ctx.roundRect(xPos, yPos, circleSize, (yTopPos - yPos) / 2, 50);
								ctx.fill();
							}
						}
					});
				}
			});
		};

		const plugin: any = [
			{
				afterDatasetDraw: (chart: any) => {
					drawImage(chart);
				},
			},
		];

		return (
			<>
				<div className="horizontalContainer">
					<Card>
						<div className="cardHeader">
							<h4>{graphAxes?.graph_name}</h4>
							{mode === "edit" && (
								<div className="headerIcon" onDoubleClick={() => openDrawer(1)}>
									<Link to="/">
										{graphAxes?.datasets?.length == 0 || !graphAxes?.datasets
											? "Configure This Graph"
											: ""}
									</Link>
									<SettingIcon />
								</div>
							)}
						</div>
						<div className="cardContent">
							<Popover
								content={content}
								title={title}
								open={showDatePicker}
								trigger="click"
								placement="bottomLeft"
								onOpenChange={handleVisibleChange}
								overlayClassName={"graphPopover"}
							>
								<div
									onClick={() => {
										setShowDatePicker(true);
										setEditGraphData({
											date_label: graphAxes?.date_label,
											graph_from_date: graphAxes?.graph_from_date,
											graph_to_date: graphAxes?.graph_to_date,
										});
									}}
								>
									{mode === "view" ? (
										<div className="popoverLink">
											{graphAxes?.date_label}
											<DownArrow />
										</div>
									) : (
										""
									)}
								</div>
							</Popover>

							{/* {
								chartId===graphAxes?.graph_id && graphAxes?.bar_name===1?<Bar data={data} options={options} />:""
							} */}
							<div id="graphContainer">
								<Bar
								 	ref={chartRef}
									data={chartData}
									options={graphAxes?.tab == "Custom" ? customOptions : options}
									plugins={plugin}
								/>
							</div>
						</div>
						{/* <div className="cardFooter">
								<ResizeIcon />
							</div> */}
					</Card>
				</div>
			</>
		);
	}
);

export default VerticalBar;
