import React from "react";
import { makeAutoObservable } from "mobx";
import axios from "axios";
import {
	ChangePasswordProps,
	CheckMyEmailProps,
	doLoginAsCompanyType,
	doLoginType,
	doRegisterType,
	StackModuleCategoryProps,
	userType,
	VerifyUserChangePasswordProps,
} from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";
import StackView from "../../views/app_pages/StackView";

export default class AuthStore {
	// Variables
	public user?: userType;
	public token?: string;
	public app_loading: boolean;
	public email?: string;
	private rootStore: RootStore;
	public companyList?: any[];
	public activeCompanyList?: any[];
	public inactiveCompanyList?: any[];
	public company?: any;
	public project?: any;
	public stackModule?: any;
	public projectList?: any[];
	public stackModuleCategoryId?: number;
	public stackModuleList?: any[];
	public stackModuleCategoryList?: StackModuleCategoryProps[];
	public stackType?: string;

	constructor() {
		this.app_loading = true;
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Initiate Application Functions
	public InitializeApp = (): void => {
		this.rootStore.setAxiosBaseUrl();
		this.rootStore.setAxiosInterceptor();
		this.rootStore.setNotificationConfig();
		this.SetupHeaders(localStorage.getItem("token") ?? "");
	};

	public SetupHeaders = (access_token?: string): void => {
		this.setAppLoading(true);
		this.rootStore
			.setAxiosHeaders(access_token)
			.then((token) => {
				this.setToken(token);
				this.fetchAuthUser();
			})
			.catch(() => {
				this.rootStore.resetStore();
				this.setToken();
				this.setUser();
				this.setAppLoading(false);
			});
	};

	// Setter Functions
	public setUser = (value?: userType): void => {
		this.user = value;
	};

	public setToken = (value?: string): void => {
		this.token = value;
	};

	public setAppLoading = (value: boolean): void => {
		this.app_loading = value;
	};

	public setEmail = (value: string): void => {
		this.email = value;
	};

	public setCompanyList = (value?: any[]): void => {
		this.companyList = value;
	};

	public setStackModuleCategoryId = (value?: number): void => {
		this.stackModuleCategoryId = value;
	};

	public setActiveCompanyList = (value?: any[]): void => {
		this.activeCompanyList = value;
		const savedCompany = localStorage.getItem("company");
		if (savedCompany) {
			try {
				const findCompany = value?.find(
					(company: any) => company.company_id == JSON.parse(savedCompany).company_id
				);
				if (findCompany) {
					this.setCompany(findCompany);
				} else {
					value && value.length > 0 && this.setCompany(value[0]);
				}
			} catch {
				value && value.length > 0 && this.setCompany(value[0]);
			}
		} else {
			value && value.length > 0 && this.setCompany(value[0]);
		}
	};

	public setInactiveCompanyList = (value?: any[]): void => {
		this.inactiveCompanyList = value;
	};

	public setProjectList = (value?: any[]): void => {
		this.projectList = value;
		const savedProject = localStorage.getItem("project");
		if (savedProject) {
			try {
				const findProject = value?.find(
					(project: any) => project.id == JSON.parse(savedProject).id
				);
				if (findProject) {
					this.setProject(findProject);
				} else {
					value && value.length > 0
						? this.setProject(value[0])
						: this.setProject();
				}
			} catch {
				value && value.length > 0
					? this.setProject(value[0])
					: this.setProject();
			}
		} else {
			value && value.length > 0 ? this.setProject(value[0]) : this.setProject();
		}
	};

	public setProject = (value?: any): void => {
		this.project = value;
		value
			? localStorage.setItem("project", JSON.stringify(value))
			: localStorage.removeItem("project");
		const oldStackModule = localStorage.getItem("stackModule");
		if (oldStackModule) {
			try {
				const stack = value.stackModule.find(
					(sModule: any) => sModule.id == oldStackModule
				);
				this.setStackModule(stack?.id);
				this.setStackType(stack?.key);
				this.setStackModuleCategoryList(stack.stackCategory);
				this.setStackModuleCategoryId(stack.stackCategory[0]?.category_id);
			} catch {
				if (value?.stackModule && value?.stackModule.length > 0) {
					this.setStackModule(value?.stackModule[0]?.id);
					this.setStackType(value?.stackModule[0]?.key);
					this.setStackModuleCategoryList(value?.stackModule[0]?.stackCategory);
					this.setStackModuleCategoryId(value?.stackModule[0]?.stackCategory[0]?.category_id);
				} else {
					this.setStackModule();
					this.setStackType();
					this.setStackModuleCategoryList();
					this.setStackModuleCategoryId();
				}
			}
		} else {
			if (value?.stackModule && value?.stackModule.length > 0) {
				this.setStackModule(value?.stackModule[0]?.id);
				this.setStackType(value?.stackModule[0]?.key);
				this.setStackModuleCategoryList(value?.stackModule[0]?.stackCategory);
				this.setStackModuleCategoryId(value?.stackModule[0]?.stackCategory[0]?.category_id);
			} else {
				this.setStackModule();
				this.setStackType();
				this.setStackModuleCategoryId();
			}
		}

		if (value?.stackModule && value?.stackModule.length > 0) {
			this.setStackModuleList(
				value.stackModule.map((stack: any) => {
					return {
						children: <StackView stack={stack} />,
						key: stack.id,
						label: stack.name,
						stackType: stack.key,
						categoryList: stack.stackCategory,
					};
				})
			);
		} else {
			this.setStackModuleList();
		}
	};

	public setStackModule = (value?: any) => {
		this.stackModule = value;
		value
			? localStorage.setItem("stackModule", JSON.stringify(value))
			: localStorage.removeItem("stackModule");
	};

	public setStackModuleList = (value?: any[]): void => {
		this.stackModuleList = value;
	};

	public setCompany = (value?: any): void => {
		this.company = value;
		value
			? localStorage.setItem("company", JSON.stringify(value))
			: localStorage.removeItem("company");
		this.setProjectList(value?.project);
	};

	public setStackModuleCategoryList = (values?: StackModuleCategoryProps[]) => {
		this.stackModuleCategoryList = values;
	};

	public setStackType = (values?: string) => {
		this.stackType = values;
	};

	// API Functions
	public doLogin = async (payload: doLoginType): Promise<any> => {
		return await axios
			.post(API_URL.LOGIN_WITH_EMAIL, payload)
			.then(({ data }) => {
				this.SetupHeaders(data.data.token);
				localStorage.getItem("viewCompanyAsId") && localStorage.removeItem("viewCompanyAsId");
				return data;
			})
			.catch(({ data }) => {
				this.rootStore.resetStore();
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			});
	};
	public doLoginAsCompany = async (payload: doLoginAsCompanyType): Promise<any> => {
		return await axios
			.post(API_URL.LOGIN_AS_COMPANY, payload)
			.then(({ data }) => {
				this.SetupHeaders(data.data.token);
				localStorage.setItem("viewCompanyAsId", payload.company_id);
				return data;
			})
			.catch(({ data }) => {
				this.rootStore.resetStore();
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			});
	};

	public doRegister = async (payload: doRegisterType): Promise<any> => {
		return await axios.post(API_URL.REGISTER_WITH_EMAIL, payload).then(() => {
			this.setEmail(payload.email);
		});
	};

	public fetchAuthUser = async (): Promise<any> => {
		const viewCompanyAsId = localStorage.getItem("viewCompanyAsId");
		const params = viewCompanyAsId ? { company_id: viewCompanyAsId } : {};
		return await axios
			.get(API_URL.ME, { params })
			.then(({ data }) => {
				this.setUser(data.data);
				this.setCompanyList(data.data.company);
				this.setActiveCompanyList(data.data.activeCompany);
				this.setInactiveCompanyList(data.data.inactiveCompany);
				return data;
			})
			.catch((data) => {
				this.rootStore.resetStore();
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			})
			.finally(() => this.setAppLoading(false));
	};

	public logout = async (): Promise<any> => {
		return await axios
			.post(API_URL.LOGOUT)
			.then(({ data }) => {
				setTimeout(() => {
					this.rootStore.resetStore();
					this.setToken();
					this.setUser();
				}, 1000);
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public forgetPassword = async (data: any): Promise<any> => {
		return await axios
			.post(API_URL.FORGOT_PASSWORD, data)
			.then(({ data }) => {
				return data.data;
			})
			.catch((data: any) => {
				return Promise.reject(data);
			});
	};

	public updateProfile = async (id: number, data: any): Promise<any> => {
		return await axios
			.post(API_URL.UPDATE_PROFILE(id), data)
			.then(this.fetchAuthUser);
	};

	public updateProfileImage = async (
		id: number,
		profile_image: any
	): Promise<any> => {
		return await axios
			.post(API_URL.UPDATE_PROFILE_IMAGE(id), profile_image)
			.then(this.fetchAuthUser);
	};

	public leaveOrganization = async (id: number): Promise<any> => {
		return await axios
			.post(API_URL.LEAVE_ORGANIZATION(id))
			.then(this.fetchAuthUser);
	};

	public changePassword = async (data: ChangePasswordProps) => {
		return await axios.post(API_URL.CHANGE_PASSWORD, data).then((data) => {
			return data;
		});
	};

	public assignStack = async (data: {
		id: number[];
		company_id: number;
		project_id: number;
		company_stack_modules_id: number;
		company_stack_category_id: number;
	}): Promise<any> => {
		return await axios.post(API_URL.USER.ASSIGN_STACK, data);
	};

	public checkMyEmail = async (data: CheckMyEmailProps) => {
		return await axios.post(API_URL.CHECK_MY_EMAIL, data).then((data) => {
			return data;
		});
	};

	public verifyUserChangePassword = async (
		data: VerifyUserChangePasswordProps
	) => {
		return await axios
			.post(API_URL.VERIFY_USER_CHANGE_PASSWORD, data)
			.then((data) => {
				return data;
			});
	};

	public resetPasswordViaLink = async (
		otp: string,
		data: any
	): Promise<any> => {
		return await axios
			.post(API_URL.RESET_PASSWORD(otp), data)
			.then((data: any) => {
				return data.data;
			});
	};
}
