import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { DuplicateCategoryProps } from "./CompanyStackCategoryProps";

export class CompanyStackCategoryStore {
	public companyStackCategoryList?: any[];

	constructor() {
		makeAutoObservable(this);
	}

	setCompanyStackCategoryList = (value?: any[]) => {
		this.companyStackCategoryList = value;
	};

	fetchCompanyStackCategory = async (): Promise<any> => {
		return await axios
			.get(API_URL.COMPANY_STACK_CATEGORY.INDEX)
			.then(({ data }) => {
				this.setCompanyStackCategoryList(data.count);
			});
	};

	createCompanyStackCategory = async (data: any): Promise<any> => {
		return await axios
			.post(API_URL.COMPANY_STACK_CATEGORY.CREATE, data)
			.then(({ data }) => {
				data;
			});
	};

	companyStackCategoryDetails = async (id: number): Promise<any> => {
		return await axios
			.get(API_URL.COMPANY_STACK_CATEGORY.DETAILS(id))
			.then(({ data }) => {
				data;
			});
	};

	updateCompanyStackCategory = async (id: number, data: any): Promise<any> => {
		return await axios
			.post(API_URL.COMPANY_STACK_CATEGORY.UPDATE(id), data)
			.then(({ data }) => {
				data;
			});
	};

	deleteCompanyStackCategory = async (id: number): Promise<any> => {
		return await axios
			.delete(API_URL.COMPANY_STACK_CATEGORY.DELETE(id))
			.then(({ data }) => {
				data;
			});
	};

	updateSequenceCompanyStackCategory = async (): Promise<any> => {
		return await axios
			.post(API_URL.COMPANY_STACK_CATEGORY.UPDATE_SEQUENCE)
			.then(({ data }) => {
				data;
			});
	};

	bulkUpdateStackCategory = async (data: any): Promise<any> => {
		return await axios
			.post(API_URL.COMPANY_STACK_CATEGORY.BULK_UPDATE, data)
			.then(({ data }) => {
				data;
			});
	};

	duplicateCategory = async (data: DuplicateCategoryProps) => {
		return await axios
			.post(API_URL.COMPANY_STACK_CATEGORY.DUPLICATE_CATEGORY, data)
			.then(({ data }) => {
				return data;
			});
	};
}
