/* eslint-disable no-mixed-spaces-and-tabs */
import { Button, DatePicker, Space } from "antd";
import { DatePickerProps, RangePickerProps } from "antd/lib/date-picker";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useStore from "../../../../../../../../store";
import {
	displayFormats,
	getFromDateAndToDate,
} from "../../../../../../../../config/Global";

const { RangePicker } = DatePicker;

interface ButtonProps {
	chartId: any;
	setShowDatePicker?: any;
}

const DateRangePopover: React.FC<ButtonProps> = observer(
	({ chartId, setShowDatePicker }) => {
		const [placement] = useState<DatePickerProps["placement"]>("bottomLeft");
		const { SCORECARD_STACK, AUTH, COMMON } = useStore();
		const { scorecardTypeList } = COMMON;
		const {
			setGraphDate,
			fromGraphDate,
			toGraphDate,
			showAllData,
			scorecardStackDetails,
			tab,
			dateRange,
			setIsOnChange,
			setDefaultDateChacked,
			setDateLabel,
			dateLabel,
			defaultDateChecked,
			scorecardStackDetail,
			editGraphData,
		} = SCORECARD_STACK;
		const { company, project, stackModule, stackModuleCategoryId } = AUTH;

		const [dateRangeValue, setDateRangeValue] = useState<any>();

		useEffect(() => {
			if (editGraphData?.date_label == "Last 6 Months") {
				scorecardStackDetail &&
					setDateRangeValue(
						getFromDateAndToDate(
							scorecardStackDetail.scorecard_type,
							moment()
								.clone()
								.subtract(6, "months")
								.format(displayFormats.DATE_FORMAT),
							moment().format(displayFormats.DATE_FORMAT),
							scorecardStackDetail.scorecard_start_from
						)
					);
				setGraphDate(
					moment()
						.clone()
						.subtract(6, "months")
						.format(displayFormats.DATE_FORMAT),
					moment().format(displayFormats.DATE_FORMAT)
				);
			} else {
				setDateRangeValue([
					moment(editGraphData?.graph_from_date),
					moment(editGraphData?.graph_to_date),
				]);
			}
		}, [scorecardStackDetail, editGraphData]);
		useEffect(() => {
			//
		}, [dateRangeValue]);
		// Calculate the date 6 months ago

		const onChange: RangePickerProps["onChange"] = (dates, dateStrings) => {
			setIsOnChange(true);
			
			if (dates) {
				setGraphDate(dateStrings[0], dateStrings[1]);
				scorecardStackDetail &&
					setDateRangeValue(
						getFromDateAndToDate(
							scorecardStackDetail.scorecard_type,
							dateStrings[0],
							dateStrings[1],
							scorecardStackDetail?.scorecard_start_from
						)
					);
				if (
					moment()
						.clone()
						.subtract(1, "week")
						.isoWeekday(scorecardStackDetail?.scorecard_start_from ?? 1)
						.format(displayFormats.DATE_FORMAT) === dateStrings[0] &&
					moment()
						.add(-1, "week")
						.endOf("week")
						.format(displayFormats.DATE_FORMAT) === dateStrings[1]
				) {
					setDateLabel("Last Week");
					//setDefaultDateChacked(false);
				} else if (
					moment()
						.add(-1, "week")
						.startOf("week")
						.format(displayFormats.DATE_FORMAT) === dateStrings[0] &&
					moment()
						.add(-1, "week")
						.endOf("week")
						.format(displayFormats.DATE_FORMAT) === dateStrings[1]
				) {
					setDateLabel("Last Week");
					//setDefaultDateChacked(false);
				} else if (
					moment().startOf("month").format(displayFormats.DATE_FORMAT) ==
						dateStrings[0] &&
					moment().endOf("month").format(displayFormats.DATE_FORMAT) ==
						dateStrings[1]
				) {
					setDateLabel("This Month");
					//setDefaultDateChacked(false);
				} else if (
					moment()
						.add(-1, "months")
						.startOf("month")
						.format(displayFormats.DATE_FORMAT) === dateStrings[0] &&
					moment()
						.add(-1, "months")
						.endOf("month")
						.format(displayFormats.DATE_FORMAT) === dateStrings[1]
				) {
					setDateLabel("Last Month");
					//setDefaultDateChacked(false);
				} else if (
					moment()
						.add(-1, "years")
						.endOf("day")
						.format(displayFormats.DATE_FORMAT) === dateStrings[0] &&
					moment().startOf("day").format(displayFormats.DATE_FORMAT) ===
						dateStrings[1]
				) {
					setDateLabel("Last 12 Months");
					//setDefaultDateChacked(false);
				} else if (
					moment().format(displayFormats.DATE_FORMAT) === dateStrings[1] &&
					moment()
						.clone()
						.subtract(6, "months")
						.format(displayFormats.DATE_FORMAT) === dateStrings[0]
				) {
					setDateLabel("Last 6 Months");
					//setDefaultDateChacked(true);
				} else {
					setDateLabel("Custom");
					//setDefaultDateChacked(false);
				}
			} else {
				//console.log("Clear");
			}
		};

		const handleSave = () => {
			setIsOnChange(true);
			setShowDatePicker(false);
			scorecardStackDetails({
				company_id: company.company_id,
				project_id: project.id,
				company_stack_modules_id: stackModule,
				company_stack_category_id: stackModuleCategoryId,
				from_date: dateRange.fromDate,
				to_date: dateRange.toDate,
				display_data: showAllData ? "all" : "selected",
				graph_id: chartId,
				graph_from_date: getFromDateAndToDate(
					scorecardStackDetail.scorecard_type,
					fromGraphDate,
					toGraphDate,
					scorecardStackDetail.scorecard_start_from
				)[0].format("YYYY-MM-DD"),
				graph_to_date: getFromDateAndToDate(
					scorecardStackDetail.scorecard_type,
					fromGraphDate,
					toGraphDate,
					scorecardStackDetail.scorecard_start_from
				)[1].format("YYYY-MM-DD"),
				date_label: dateLabel,
				dateChecked: defaultDateChecked,
				graphSelectedDateRageData: true,
				type:
					tab == "01"
						? "MapView"
						: tab == "02"
						? "TableView"
						: tab == "03"
						? "GraphView"
						: "",
			});
		};

		const scorecard_type = scorecardTypeList?.find(
			(type: any) => type.id == scorecardStackDetail?.scorecard_type
		)?.name;

		const dateRanges: any =
			scorecard_type !== "Weekly"
				? {
						"This Month": [moment().startOf("month"), moment().endOf("month")],
						"Last Month": [
							moment().add(-1, "months").startOf("month"),
							moment().add(-1, "months").endOf("month"),
						],
						"Last 6 Months": [moment().clone().subtract(6, "months"), moment()],
						"Last 12 Months": [
							moment().add(-1, "years").endOf("day"),
							moment().startOf("day"),
						],
						Custom: [moment(), moment()],
				  }
				: {
						"Last Week":
							scorecardStackDetail.scorecard_start_from == 1
								? [
										moment()
											.clone()
											.subtract(1, "week")
											.isoWeekday(scorecardStackDetail.scorecard_start_from),
										moment().add(-1, "week").endOf("week"),
								  ]
								: [
										moment().add(-1, "week").startOf("week"),
										moment().add(-1, "week").endOf("week"),
								  ],
						"This Month": [moment().startOf("month"), moment().endOf("month")],
						"Last Month": [
							moment().add(-1, "months").startOf("month"),
							moment().add(-1, "months").endOf("month"),
						],
						"Last 6 Months": [moment().clone().subtract(6, "months"), moment()],
						"Last 12 Months": [
							moment().add(-1, "years").endOf("day"),
							moment().startOf("day"),
						],
						Custom: [moment(), moment()],
				  };

		return (
			<div>
				<Space direction="vertical" size={12} style={{ padding: "20px" }}>
					<RangePicker
						ranges={dateRanges}
						onChange={onChange}
						placement={placement}
						// popupClassName="graphDateRange"
						popupClassName="goalRangePopup"
						value={dateRangeValue}
						inputReadOnly={true}
						format={displayFormats.DATE_FORMAT}
					/>

					<div className="popoverFooter">
						<Button
							className="cancelBtn"
							onClick={() => {
								setShowDatePicker(false);
								setDefaultDateChacked(false);
							}}
						>
							Cancel
						</Button>
						<Button className="blueSave" onClick={handleSave}>
							Save
						</Button>
					</div>
				</Space>
			</div>
		);
	}
);

export default DateRangePopover;
