import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import { maxProjectName, minProjectName } from "../config/Validators";

export const createProject = (t: any): RequestProps => ({
	name: [
		{
			required: true,
			whitespace: true,
			message: Message(t).required.text(t("labels_project_name")),
		},
		{
			min: minProjectName,
			message: Message(t).min.string(minProjectName, t("labels_project_name")),
		},
		{
			max: maxProjectName,
			message: Message(t).max.string(maxProjectName, t("labels_project_name")),
		},
	],
});
