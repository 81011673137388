import React, { useEffect } from "react";
import { Button, Form } from "antd";
import Modal from "antd/lib/modal/Modal";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { createProject } from "../../../../../requests/ProjectRequest";
import { useTranslation } from "react-i18next";

const AddEditCompanyProject = observer(() => {
	const { AUTH, COMPANY_PROJECT, ROOT, COMMON,SCORECARD_STACK} = useStore();
	const { setDeleteModal, setDeleteModalDetail } = COMMON;
	const { assignErrorToInput } = ROOT;
	const { company, fetchAuthUser, project, stackModule,stackModuleCategoryId } = AUTH;
	const {
		createCompanyProject,
		updateCompanyProject,
		setAddEditProjectModal,
		addEditProjectModal,
		setEditProjectDetail,
		editProjectDetail,
		deleteCompanyProject,
	} = COMPANY_PROJECT;
	const {
		scorecardStackDetails,
		tab,
		dateRange,
		showAllData
	} = SCORECARD_STACK;
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const closeCompanyProjectModel = () => {
		setEditProjectDetail(null);
		form.resetFields();
		setAddEditProjectModal(false);
	};

	const handleFinish = (data: any) => {

		data.company_id = company.company_id;
		
		if (!editProjectDetail) {
			createCompanyProject(data)
				.then(() => {
					fetchAuthUser();
					closeCompanyProjectModel();
				})
				.catch((e: any) => {
					assignErrorToInput(form, e.data.errors);
				});
		} else {
			updateCompanyProject(editProjectDetail.id, data)
				.then(() => {
					fetchAuthUser();
					closeCompanyProjectModel();
					setTimeout(()=>{
						tab=="03" &&scorecardStackDetails({
							company_id: company.company_id,
							project_id: project.id,
							company_stack_modules_id: stackModule,
							company_stack_category_id: stackModuleCategoryId,
							from_date: dateRange.fromDate,
							to_date: dateRange.toDate,
							display_data: showAllData ? "all" : "selected",
							type:
								tab == "01"
									? "MapView"
									: tab == "02"
									? "TableView"
									: tab == "03"
									? "GraphView"
									: "",
						});
					},100)
				})
				.catch((e: any) => {
					assignErrorToInput(form, e.data.errors);
				});
		}
	};

	useEffect(() => {
		editProjectDetail &&
			form.setFieldsValue({
				name: editProjectDetail.name,
			});
	}, [addEditProjectModal, editProjectDetail]);

	return (
		<Modal
			open={addEditProjectModal}
			onCancel={closeCompanyProjectModel}
			title={!editProjectDetail ? "Create New Project" : "Edit Project"}
			footer={false}
			className="editProjectModal"
		>
			<FormBox form={form} onFinish={handleFinish}>
				<InputBox.Text
					name="name"
					rules={createProject(t).name}
					// addonAfter="Project Name"
					label="Project Name"
					required
					placeholder="project Name"
				/>
				{!editProjectDetail && (
					<Button htmlType="submit">Create New Project</Button>
				)}

				{editProjectDetail && (
					<>
						<Button htmlType="submit" className="upadteButton">
							Update Project
						</Button>
						<Button
							className="deleteButton"
							onClick={() => {
								closeCompanyProjectModel();
								setDeleteModal(true);
								setDeleteModalDetail(
									"Project",
									deleteCompanyProject,
									editProjectDetail.id
								);
							}}
						>
							Delete Project
						</Button>
					</>
				)}
			</FormBox>
		</Modal>
	);
});

export default AddEditCompanyProject;
