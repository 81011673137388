/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Avatar,
	Button,
	Checkbox,
	Form,
	Popover,
	Select,
	Switch,
	Table,
	Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { NodeProps } from "reactflow";
import { ColumnsType } from "antd/lib/table";
// import { ReactComponent as SettingsIcon } from "../../../../../../assets/images/settingIcon.svg";
import { ReactComponent as DragIcon } from "../../../../../../assets/images/dragIcon.svg";
import { ReactComponent as ViewIcon } from "../../../../../../assets/images/viewIcon.svg";
import { ReactComponent as HideIcon } from "../../../../../../assets/images/hideicon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/images/redDelete.svg";
import { ReactComponent as HelpIcon } from "../../../../../../assets/images/helpIcon.svg";
import { ReactComponent as RecurringIcon } from "../../../../../../assets/images/recurringIcon.svg";
import { ReactComponent as NoneIcon } from "../../../../../../assets/images/none.svg";
// import { ScorecardStackProps } from "../../../../../../store/ScoreCardStack/ScorecardStackStore";
// import { ReactComponent as Notification } from "../../../../../../assets/images/notificationGreyIcon.svg";
// import { ReactComponent as NoneIcon } from "../../../../../../assets/images/none.svg";
// import DefaultUserLogo from "../../../../../../assets/images/userLogo.svg";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import useStore from "../../../../../../store";
import {
	ChangeValueProps,
	NodeEntryProps,
} from "../../../../../../store/ScoreCardStack/ScorecardStackStore";
import moment from "moment";
import { displayFormats, displayNumberFormat, validateFields } from "../../../../../../config/Global";
// import { ReactComponent as DropdownIcon } from "../../../../../../assets/images/downIcon.svg";
import ReactDragListView from "react-drag-listview";
import { createStackRequest } from "../../../../../../requests/StackRequest";
import { useTranslation } from "react-i18next";

interface DataType {
	override_start_goal: any;
	goal_duration_target: any;
	node_created_date: any;
	goal_target_date: any;
	goal_duration_start: any;
	goal_start_date: any;
	goal_range_type: any;
	is_goal: any;
	goal_repeat_type: any;
	node_id: string;
	to_date: string;
	from_date: string;
	isHidden: boolean;
	key: string;
	nodeId: string;
	name: string;
	avatar: string;
	type: string;
	user?: any;
	assigned_color: string;
	value?: string;
	dates: any;
	reminder: 0 | 1;
	result: number;
	goal: number;
	diff: string;
	per: string;
	goaldate: string;
	edit_id?: number;
	note?: string;
	mode?: string;
	assigned_to?: string;
	comment?: string;
	edit_value: string;
	age: string;
	address: string;
	format_of_metric?: 1 | 2 | 3;
}
interface ColsType {
	actual: boolean;
	goal: boolean;
	diff: boolean;
	percentage: boolean;
	goal_date: boolean;
}
const TableView = observer(
	({
		mode,
		setNodes,
		nodes,
		//startDay,
		//edges,
		//scorecardType,
		category,
	}: //open,
		//setOpen,
		//saveStackData,
		{
			edges: any;
			nodes: any;
			mode: string;
			setNodes?: any;
			startDay?: number;
			scorecardType?: any;
			category?: any;
			open?: any;
			setOpen?: any;
			saveStackData?: any;
		}) => {
		const { SCORECARD_STACK, AUTH, COMMON } = useStore();
		const { company, project, stackModule } = AUTH;
		const { metricFormatList } = COMMON;
		const {
			//saveScorecardStack,
			scorecardStackDetails,
			tab,
			nodeEntry,
			stackTableId,
			setScoreDataState,
			showAllData,
			dateRange,
			scorecardStackDetail,

		}: //changeValue,
			{
				saveScorecardStack: any;
				scorecardStackDetails: any;
				tab: "01" | "02" | "03";
				nodeEntry: (data: NodeEntryProps) => any;
				stackTableId: any;
				setScoreDataState: any;
				showAllData: any;
				dateRange: any;
				scorecardStackDetail?: any
				changeValue: (data: ChangeValueProps) => any;
			} = SCORECARD_STACK;

		const [tables, setTables] = useState<any[]>([]);
		const [cols, setCols] = useState<ColsType>({
			actual: true,
			goal: true,
			diff: true,
			percentage: true,
			goal_date: true,
		});
		const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
		const [showHiddenData, setShowHiddenData] = useState<boolean>(false);
		const [childrenData, setChildrenData] = useState<any>([]);

		const getFormatValue = (record: any, value: any, valueWithComma = false) => {
			const newValue = valueWithComma ? displayNumberFormat(value) : value;
			if (value) {
				const metricFormat = record.format_of_metric && metricFormatList ? metricFormatList.find((format: any) => format.id == record.format_of_metric) : { name: "", id: "" };
				return metricFormat?.name == "$" ? `${metricFormat?.name} ${newValue ?? ""}` : `${newValue ?? ""} ${metricFormat?.name}`
			} else {
				return "";
			}
		};

		const getRowClassName = (record: any) => {
			if (record?.type === "header") {
				return ''//'rowHeaderBg';
			}

			return '';
		};

		const { Option } = Select;
		const { t } = useTranslation();

		const columns: ColumnsType<DataType> = [
			...(mode == "edit"
				? [
					{
						title: "",
						width: "50px",
						dataIndex: "drag",
						className: "dragCol",
						fixed: true,
						key: "drag",

						render: (_: any, record: any) => {
							return (
								record.type != "listData" && (
									<div className="dragIcon">
										<DragIcon />
									</div>
								)
							);
						},
					},
				]
				: []),

			{
				...(mode == "edit" && { width: 320 }),
				title: (
					<div className="checkbox">
						<span>
							<Checkbox
								onChange={(e) => {
									setCols({ ...cols, actual: e.target.checked });
								}}
								checked={cols.actual}
								indeterminate={false}
							/>
							<span className="checkTitle">Actual</span>
						</span>
						<span>
							<Checkbox
								indeterminate={false}
								onChange={(e) => {
									setCols({ ...cols, goal: e.target.checked });
								}}
								checked={cols.goal}
							/>
							<span className="checkTitle">Goal</span>{" "}
						</span>
						<span>
							<Checkbox
								indeterminate={false}
								onChange={(e) => {
									setCols({ ...cols, diff: e.target.checked });
								}}
								checked={cols.diff}
							/>
							<span className="checkTitle">Diff.</span>
						</span>
						<span>
							<Checkbox
								indeterminate={false}
								onChange={(e) => {
									setCols({
										...cols,
										percentage: e.target.checked,
									});
								}}
								checked={cols.percentage}
							/>
							<span className="checkTitle">%</span>
						</span>
						<span>
							<Checkbox
								indeterminate={false}
								onChange={(e) => {
									setCols({
										...cols,
										goal_date: e.target.checked,
									});
								}}
								checked={cols.goal_date}
							/>
							<span className="checkTitle">Goal Date</span>
						</span>
					</div>
				),
				dataIndex: "name",
				className: mode == "edit" ? "FirstCol" : "viewCol",
				width: "450px",
				fixed: "left",
				key: "name",
				onCell: () => {
					return {
						style: {
							textAlign: "center",
						},
					};
				},
				render: (name, record, id) => {
					let recordName: any;
					let recordInitial: any;

					if (record.user) {
						if (record.user.name && record.user?.last_name) {
							recordName = record.user.name + " " + record.user?.last_name;
							recordInitial =
								record.user.name.charAt(0) + record.user?.last_name.charAt(0);
						} else if (record.user.name?.trim()) {
							recordName = record.user.name;
							recordInitial =
								record.user.name.split(" ")[0]?.charAt(0) +
								record.user.name.split(" ")[1]?.charAt(0);
						} else {
							recordName = record.user.email;
							recordInitial =
								record.user.email.charAt(0) + record.user.email.charAt(1);
						}
					}

					return (
						<>
							<div className={record.type === "header" ? "userHeader" : "userMetricWrap"}>
								<div className="thumb">
									<Tooltip
										style={record.assigned_to ? {} : { visibility: "hidden" }}
										title={recordName}
										placement="top"
									>
										{
											record.type === "header" ?
												"" : <Avatar

													size={30}
													src={
														record?.user &&
															(record.user.profile_image ||
																record.user.profile_image != "")
															? record.user.profile_image
															: record.avatar ?? null
													}
													style={{
														marginRight: "10px",
														...(record.assigned_to ? {} : { visibility: "hidden" }),
													}}
												>
													{recordInitial ?? ""}
												</Avatar>
										}

									</Tooltip>
									{record?.type === "header" ? (
										<div className="tableHeader">
											<span className="leftLine"></span>
											<b>{record?.name}</b>
											<span className="rightLine"></span>
										</div>
									) : (
										<span>{name}</span>
									)}
								</div>

								<div className="icon">
									{record.note && (
										<Tooltip title={record.note ?? ""}>
											<HelpIcon style={{ marginRight: "5px" }} />
										</Tooltip>
									)}
									{record.type !== "dynamicGroup" &&
										record.type !== "listData" &&
										record.type !== "metricBoxGroup" &&
										record.type != "metricBoxCalculation" &&
										record?.type != "spacer" &&
										record?.type != "header" &&
										(
											<svg
												width="16"
												height="18"
												viewBox="0 0 16 18"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M14.4747 8.70453C13.8659 7.99366 13.5893 7.37763 13.5893 6.33104V5.97519C13.5893 4.61136 13.2754 3.73264 12.593 2.85391C11.5411 1.48924 9.7704 0.666748 8.03694 0.666748H7.96323C6.26621 0.666748 4.55097 1.45147 3.48091 2.76075C2.76119 3.6571 2.41085 4.57359 2.41085 5.97519V6.33104C2.41085 7.37763 2.15245 7.99366 1.52549 8.70453C1.06417 9.22824 0.916748 9.90134 0.916748 10.6298C0.916748 11.3592 1.1561 12.0499 1.63647 12.6114C2.26343 13.2845 3.1488 13.7142 4.05321 13.7889C5.36262 13.9383 6.67203 13.9945 8.0005 13.9945C9.32813 13.9945 10.6375 13.9005 11.9478 13.7889C12.8514 13.7142 13.7367 13.2845 14.3637 12.6114C14.8432 12.0499 15.0834 11.3592 15.0834 10.6298C15.0834 9.90134 14.936 9.22824 14.4747 8.70453"
													fill={record.reminder ? "blue" : "#BABECB"}
												/>
												<path
													opacity="0.4"
													d="M9.67396 15.0238C9.25737 14.9348 6.71888 14.9348 6.30229 15.0238C5.94616 15.106 5.56104 15.2974 5.56104 15.717C5.58174 16.1173 5.81613 16.4707 6.14079 16.6948L6.13996 16.6956C6.55987 17.0229 7.05266 17.2311 7.56864 17.3058C7.8436 17.3435 8.12354 17.3418 8.40845 17.3058C8.9236 17.2311 9.41639 17.0229 9.8363 16.6956L9.83547 16.6948C10.1601 16.4707 10.3945 16.1173 10.4152 15.717C10.4152 15.2974 10.0301 15.106 9.67396 15.0238"
													fill={record.reminder ? "blue" : "#BABECB"}
												/>
											</svg>
										)}

									{mode === "edit" && record?.type == "spacer" && (
										<div className="deleteIcon dragIcon">
											<Button
												onClick={() => {
													setTables((prevTables) =>
														prevTables.filter(
															(table, currentId) => currentId != id
														)
													);
													setScoreDataState(1);
													setNodes((prevNodes: any[]) => {
														const deletedId = prevNodes.findIndex(
															(node: any) => node.type == "spacer"
														);
														return prevNodes.filter(
															(_, id) => id !== deletedId
														);
													});
												}}
												style={{ cursor: "pointer" }}
											>
												<DeleteIcon />
											</Button>
										</div>
									)}

									{mode == "edit" && record?.type != "spacer" && record?.type != "header" && (
										<div
											style={{
												cursor: "pointer",
												marginLeft: "10px",
											}}
											className="userBtnWrap"
										>
											{(company.is_admin || company.is_owner) &&
												mode == "edit" && (
													<>
														{record.type != "listData" && (
															<Button
																className="addBtn"
																onClick={() => {
																	if (mode == "edit") {
																		record.isHidden &&
																			updateNode(false, "isHidden", record.key);
																		!record.isHidden &&
																			updateNode(true, "isHidden", record.key);
																	}
																}}
															>
																{record.isHidden ? <HideIcon /> : <ViewIcon />}
															</Button>
														)}
													</>
												)}
										</div>
									)}
								</div>
							</div>
						</>
					);
				},
			},
		];

		const getColumnKey = (record: any) =>
			`${moment(record?.from_date).format("DDMMYYYY")}${moment(
				record?.to_date
			).format("DDMMYYYY")}`;

		const getColumnData = (item: any) => {
			const data: any = {};
			item.data.data_list.map((record: any) => {
				data[getColumnKey(record)] = {
					key: record.node_id + record?.from_date + record?.to_date,
					nodeId: item.id,
					value: record.value,
					edit_value: record.edit_value,
					diff: record.goal_difference,
					edit_id: record?.node_edit_id,
					comment: record?.comment,
					per: record.goal_percentage + " %",
					goal: record.goal,
					assigned_color: record?.assigned_color,
					goal_setting: record?.goal_setting,
					goal_repeat_type: record?.goal_repeat_type,
					goal_target: record.goal_target
						? moment(record.goal_target_date).format(displayFormats.DATE_FORMAT)
						: "",
					dates:
						record.from_date && record.to_date
							? {
								fromDate: moment(record.from_date).format(
									displayFormats.POST_DATE_FORMAT
								),
								toDate: moment(record.to_date).format(
									displayFormats.POST_DATE_FORMAT
								),
							}
							: "N/A",
				};
			});

			return data;
		};

		const getItem = (item: any) => {
			let data;
			setSelectedRowKeys((rowKey: any) => [...rowKey, item?.id?.toString()]);
			if (item?.data?.type == "metricBox") {
				data = {
					assigned_color: item.data.assigned_color,
					auto_assign_color: item.data.auto_assign_color,
					avatar: item.data.avatar,
					format_of_metric: item.data.format_of_metric,
					reminder: item.data.reminder,
					note: item.data.note,
					assigned_to: item.data.assigned_to,
					user: item?.data?.user,
					node_id: item?.data?.node_id,
					name: item?.data?.name,
					value: item?.data?.value,
					goal: item?.data?.goal,
					goal_repeat_type: item?.data?.goal_repeat_type,
					goal_type: item?.data?.goal_type,
					is_goal: item?.data?.is_goal,
					goal_range_type: item?.data?.goal_range_type,
					goal_start_date: item?.data?.goal_start_date,
					goal_target_date: item?.data?.goal_target_date,
					node_created_date: item?.data?.node_created_date,
					goal_duration_start: item?.data?.goal_duration_start,
					goal_duration_target: item?.data?.goal_duration_target,
					override_start_goal: item?.data?.override_start_goal,
					mode: mode,
					...(item.data?.data_list?.length && {
						...getColumnData(item),
					}),
				};
			} else if (item?.data?.type == "metricBoxCalculation") {
				data = {
					assigned_color: item.data.assigned_color,
					auto_assign_color: item.data.auto_assign_color,
					format_of_metric: item.data.format_of_metric,
					...(item.data?.data_list?.length && {
						...getColumnData(item),
					}),
				};
			} else if (item?.data?.type == "metricBoxGroup") {
				data = {
					assigned_color: item.data.assigned_color,
					auto_assign_color: item.data.auto_assign_color,
					avatar: item.data.avatar,
					reminder: item.data.reminder,
					mode: mode,
					...(item.data?.data_list?.length && {
						...getColumnData(item),
					}),
				};
			} else if (item?.data?.type == "metricCategory") {
				data = {
					goal: item.data.category,
				};
			} else if (
				item?.data?.type == "textBox" ||
				item?.data?.type == "header" ||
				item?.data?.type == "button"
			) {
				data = {
					name: item.data.title,
				};
			}
			return {
				key: item?.id?.toString(),
				nodeId: item?.id,
				type: item?.data?.type,
				sequence: item?.sequence ?? Number.MAX_SAFE_INTEGER,
				value: item?.data?.value,
				edit_value: item?.data?.edit_value,
				name: item?.data?.name,
				isHidden: item?.isHidden ?? false,
				...data,
			};
		};

		useEffect(() => {
			//
		}, [showAllData, tables]);

		useEffect(() => {
			nodes?.map((d: any) => {
				if (d?.data?.type === "metricBox") {
					setChildrenData(d?.data?.data_list);
				}
			});
		}, [nodes]);

		useEffect(() => {
			setTables([]);
			const newTable: any[] = [];
			if (nodes && nodes.length) {
				nodes
					.filter((node: any) => !node?.hidden)
					.map((node: NodeProps) => {
						if (node?.data?.type != "button") {
							if (node?.type == "spacer") {
								newTable.push({ ...node, key: node.id });
							} else {
								newTable.push(getItem(node));
							}
						}
					});
			}
			setTables(newTable.sort((a: any, b: any) => a.sequence - b.sequence));
		}, [nodes, mode]);

		const updateNode = (
			newVal: any,
			field: string,
			id: string,
			nodeId?: string,
			fromDate?: string
		) => {
			let newObj: any;
			setNodes((nodes: any) => {
				newObj = {};
				return nodes.map((node: any) => {
					if (node.id == id) {
						newObj[field] = newVal;
						if (field == "isHidden") {
							return { ...node, ...newObj };
						} else {
							return {
								...node,
								data: { ...node?.data, ...newObj },
							};
						}
					} else if (node.id == nodeId) {
						newObj[field] = newVal;
						if (field == "isHidden") {
							return { ...node, ...newObj };
						} else {
							return {
								...node,
								data: {
									...node.data,
									data_list: node.data.data_list.map((entry: any) => {
										return entry.from_date == fromDate
											? {
												...entry,
												assigned_color: newVal,
											}
											: entry;
									}),
								},
							};
						}
					} else {
						return node;
					}
				});
			});
			setScoreDataState(1);
		};

		const addSpacer = () => {
			setNodes((state: any) => [
				{
					type: "spacer",
					sequence: 0,
					id: Date.now().toString(),
				},
				...state,
			]);
			setScoreDataState(1);
		};

		const dragProps = {
			onDragEnd: (fromIndex: number, toIndex: number) => {
				setNodes((nodes: any) => {
					nodes.map((node: any) => {
						if (node.hidden || node.isHidden) {
							node.sequence = Number.POSITIVE_INFINITY;
						}
						return node;
					});
					const newNodes = nodes.sort(
						(a: any, b: any) => a?.sequence - b?.sequence
					);
					const data = [...newNodes];
					const item = data.splice(fromIndex - 1, 1)[0];
					data.splice(toIndex - 1, 0, item);
					return data
						.filter((node: any) => node)
						.map((node: any, id: any) => {
							if (node) {
								return {
									...node,
									sequence: id,
								};
							}
						});
				});
				setScoreDataState(1);
			},
			nodeSelector: "tr",
			handleSelector: ".dragIcon",
		};

		const handleHide = (checked: boolean) => {
			if (checked) {
				setShowHiddenData(true);
			} else {
				setShowHiddenData(false);
			}
		};

		const EditData = ({
			columnValue,
			record,
		}: {
			columnValue?: any;
			record?: any;
		}) => {
			const [isVisible, setIsVisible] = useState(false);
			const [disabled, setDisabled] = useState(true);
			const goals =
				columnValue?.goal_setting && JSON.parse(columnValue?.goal_setting);
			const handleVisibleChange = (newVisible: any) => {
				setIsVisible(newVisible);
			};
			const changeData = () => {
				const [form] = Form.useForm();
				const handleSubmit = (data?: any) => {
					const overwriteAssignedColor = (record?.auto_assign_color && columnValue?.assigned_color && record?.auto_assign_color == 1 && columnValue?.assigned_color != null && columnValue?.assigned_color != data?.assigned_color) ? 1 : 0;
					const dataValue = data?.value?.trim() !== "" ? data?.value : null;
					const payload = {
						scorecard_stack_id: stackTableId,
						value: dataValue,
						assigned_color: data?.assigned_color,
						overwrite_assigned_color: overwriteAssignedColor,
						node_id: columnValue?.nodeId,
						from_date: columnValue?.dates.fromDate,
						to_date: columnValue?.dates.toDate,
						comment: data?.comment,
						goal: data?.goal,
						goal_setting: dataValue === null ? null : JSON.stringify({
							node_id: columnValue.nodeId,
							name: columnValue?.name ?? record?.name,
							value: data?.value,
							goal: data.goal,
							//format_of_metric: record.format_of_metric,
							goal_repeat_type:
								goals?.goal_repeat_type ?? columnValue?.goal_repeat_type,
							goal_type: goals?.goal_type ?? columnValue?.goal_type,
							is_goal: goals?.is_goal ?? record?.is_goal,
							goal_range_type:
								goals?.goal_range_type ?? columnValue?.goal_range_type,
							goal_start_date:
								goals?.goal_start_date ?? columnValue?.goal_start_date,
							goal_target_date:
								goals?.goal_target_date ?? columnValue?.goal_target_date,
							goal_target:
								goals?.goal_target ?? columnValue?.goal_target,
							//node_created_date: record.node_created_date,
						}),
					};
					nodeEntry(payload).then(() => {
						setScoreDataState(0);
						scorecardStackDetails({
							company_id: company.company_id,
							project_id: project.id,
							company_stack_modules_id: stackModule,
							company_stack_category_id: category.key,
							from_date: dateRange.fromDate,
							to_date: dateRange.toDate,
							display_data: showAllData ? "all" : "selected",
							type:
								tab == "01"
									? "MapView"
									: tab == "02"
										? "TableView"
										: tab == "03"
											? "GraphView"
											: "",
						});
					});
					setIsVisible(false);
				};

				useEffect(() => {
					!isVisible && form.resetFields();
					if (isVisible) {
						form.setFieldValue("value", columnValue?.edit_value);
						form.setFieldValue("goal", columnValue?.goal);
						form.setFieldValue("assigned_color", columnValue?.assigned_color ?? "noColor");
						form.setFieldValue("comment", columnValue?.comment);
					}
				}, [isVisible]);

				const formChange = () => {
					validateFields(form, setDisabled)
				}

				return (
					<div>
						<FormBox onFinish={handleSubmit} form={form} onChange={formChange}>
							<InputBox.Text
								label="Actual Value"
								name={"value"}
								rules={createStackRequest(t).value}
							/>
							<InputBox.Text
								label="Goal"
								name={"goal"}
								rules={createStackRequest(t).goal}
								disabled
							/>
							<div className="assignItem">
								<div className=" dropdownWrap">
									{/* <FormItem label={"Assign Color:"}></FormItem> */}
									<span>Assign Color:</span>
									<Form.Item name={"assigned_color"}>
										<Select
											onChange={(val) => {
												form.setFieldValue("assigned_color", val);
												formChange();
												//updateNode(val, "assigned_color",columnValue?.key);
											}}
											showArrow={false}
											style={{ width: "31px" }}
										>
											<Option value="colorPink">
												<div className="colorPink"></div>
											</Option>
											<Option value="colorOrange">
												<div className="colorOrange"></div>
											</Option>
											<Option value="colorGreen">
												<div className="colorGreen"></div>
											</Option>
											<Option value="noColor">
												<div className="noColor">
													<NoneIcon />
												</div>
											</Option>
										</Select>
									</Form.Item>
								</div>
							</div>

							<div className="editMetricText ">
								<InputBox.TextArea
									name={"comment"}
									label="Comments"
									rows={2}
									//defaultValue={nodeData.data.note}
									// onChange={(e) => {
									// 	updateNode(nodeData.data.type, e.target.value, "note");
									// }}
									style={{ marginTop: "20px" }}
								/>
							</div>
							<div>
								<Button htmlType="submit" disabled={disabled}>Save</Button>
							</div>
						</FormBox>
					</div>
				);
			};

			const displayValue = getFormatValue(record, columnValue?.value);

			return (
				<Popover
					content={() => changeData()}
					trigger="click"
					overlayClassName="commentPopover"
					open={mode == "edit" ? isVisible : false}
					onOpenChange={handleVisibleChange}
					placement="top"
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							minHeight: "18px",
							cursor: "pointer"
						}}
						onClick={() => setIsVisible(true)}
					>
						{displayValue}
						{columnValue?.comment && (
							// <div className="messageInfo">
							<Tooltip title={columnValue?.comment ?? ""}>
								<HelpIcon className="helpIcon" />
							</Tooltip>
							// </div>
						)}
					</div>
				</Popover>
			);
		};

		const repeatChildColumn = (columns?: any) => {
			let newChildColumn = {};

			childrenData?.map((d: any) => {
				newChildColumn = {
					title: `${moment(d?.from_date).format("MM/DD/YY")} - ${moment(
						d?.to_date
					).format("MM/DD/YY")}`,
					className: "paddingRow",
					children: [
						...(cols?.actual == true
							? [
								{
									title: "Actual",
									width: 120,
									key: `${getColumnKey(d)}.value`,
									dataIndex: getColumnKey(d),
									render: (value?: any, record?: any) => {

										const displayValue = getFormatValue(record, value?.value);

										return record.type == "metricBox" ||
											record.type == "listData" ? (
											//<EditValue columnValue={value} record={record} />
											<EditData columnValue={value} record={record} />
										) : record.type == "metricBoxCalculation" ? (
											<span className="boldResult">{displayValue}</span>
										) : (
											""
										);
									},
									onCell: (record?: any) => {
										return {
											className: record[getColumnKey(d)]?.assigned_color,
										};
									},
								},
							]
							: []),

						...(cols?.goal == true
							? [
								{
									title: "Goal",
									width: 120,
									key: `${getColumnKey(d)}.goal`,
									dataIndex: [`${getColumnKey(d)}`, "goal"],
									render: (value: any, record?: any) => {

										const displayValue = getFormatValue(record, value, true);

										if (record[getColumnKey(d)]?.goal_repeat_type == 1) {
											return (
												<>
													<div
														className="alignLine"
													>
														{displayValue}
														<RecurringIcon className="" />
													</div>
												</>
											);
										} else {
											return (
												<div
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
												>
													{displayValue}
												</div>
											);
										}
									},
									onCell: (record?: any) => {
										return {
											className: record[getColumnKey(d)]?.assigned_color,
											style:
												record.type == "metricCategory"
													? { fontWeight: "bolder" }
													: {},
										};
									},
								},
							]
							: []),

						...(cols?.diff == true
							? [
								{
									title: "Diff.",
									width: 120,
									key: `${getColumnKey(d)}.diff`,
									dataIndex: [`${getColumnKey(d)}`, "diff"],
									render: (value: any, record?: any) => {
										return getFormatValue(record, value, true);
									},
									onCell: (record?: any) => {
										return {
											className: record[getColumnKey(d)]?.assigned_color,
										};
									},
								},
							]
							: []),

						...(cols?.percentage == true
							? [
								{
									title: "% to Goal",
									width: 80,
									key: `${getColumnKey(d)}.per`,
									dataIndex: [`${getColumnKey(d)}`, "per"],
									render: (value?: any) => {
										return value?.includes("undefined") ? "" : value;
									},
									onCell: (record?: any) => {
										return {
											className: record[getColumnKey(d)]?.assigned_color,
										};
									},
								},
							]
							: []),

						...(cols?.goal_date == true
							? [
								{
									title: "Goal Date",
									width: 100,
									className: "paddingRow",
									key: `${getColumnKey(d)}.goal_target`,
									dataIndex: [`${getColumnKey(d)}`, "goal_target"],
									render: (value?: any) => {
										if (value != "Invalid date") {
											return value
										} else {
											if (scorecardStackDetail?.scorecard_type == 1) {
												return "Weekly"
											} else if (scorecardStackDetail?.scorecard_type == 2) {
												return "Bi-weekly"
											} else if (scorecardStackDetail?.scorecard_type == 4) {
												return "Monthly"
											} else if (scorecardStackDetail?.scorecard_type == 5) {
												return "Quarterly"
											} else if (scorecardStackDetail?.scorecard_type == 6) {
												return "Annually"
											}
										}
									},
									onCell: (record?: any) => {
										return {
											className: record[getColumnKey(d)]?.assigned_color,
										};
									},
								},
							]
							: []),
					],
				};

				columns.push(newChildColumn);
			});

			return {
				...columns,
			};
		};

		repeatChildColumn(columns);

		return (
			<div className="containerWrap">
				{
					<div className="tableView" key={"table-view"}>
						{/* <div>
							{open && (
								<DrawerView
									setNodeData={setNodeData}
									mode={mode}
									nodes={nodes}
									setNodes={setNodes}
									nodeData={nodeData}
									open={open}
									onClose={() => setOpen(false)}
									saveStackData={saveStackData}
								/>
							)}
						</div> */}
						<div className="featureHeading">
							<div className="featureHeadingWrap">
								{mode == "edit" && (
									<>
										<Button className="btn-primary" onClick={addSpacer}>
											Add Spacer
										</Button>
										<div className="featureCheck">
											<span>Show Hidden Metrics</span>
											<Switch checked={showHiddenData} onChange={handleHide} />
										</div>
									</>
								)}
							</div>
						</div>
						{mode == "edit" ? (
							<ReactDragListView {...dragProps}>
								<Table
									columns={columns}
									className="editTable"
									rowClassName={getRowClassName}
									dataSource={
										(showHiddenData
											? tables
											: tables.filter((table) => !table.isHidden)) ?? []
									}
									scroll={{ x: 500, y: 500 }}
									// onRow={(record) => {
									// 	return {
									// 		className: record.assigned_color,
									// 		// onDragStart: () => {
									// 		// 	setPrevShowHiddenData(showHiddenData);
									// 		// 	setShowHiddenData(true);
									// 		// },
									// 		// onDragEnd: () => {
									// 		// 	setShowHiddenData(prevShowHiddenData);
									// 		// },
									// 	};
									// }}
									// defaultExpandAllRows={showAllData}
									expandable={{
										expandedRowKeys: selectedRowKeys ?? [],
									}}
									pagination={false}
								/>
							</ReactDragListView>
						) : (
							<Table
								columns={columns}
								rowClassName={getRowClassName}
								dataSource={
									//childrenData
									(showHiddenData
										? tables
										: tables.filter((table) => !table.isHidden)) ?? []
								}
								// onRow={(record) => {
								// 	return { className: record.assigned_color };
								// }}
								defaultExpandAllRows={showAllData}
								scroll={{ x: 500, y: 500 }}
								expandable={{
									expandedRowKeys: selectedRowKeys ?? [],
								}}
								pagination={false}
							/>
						)}
					</div>
					// );
					// })
				}
			</div>
		);
	}
);

export default TableView;
