import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../../../store";
import MacImg from "../../../assets/images/macDashboardImg.png";
import LogoComponent from "../../layouts/Components/LogoComponent";

const LoginAsCompany: React.FC = () => {
	const navigate = useNavigate();
	const { AUTH } = useStore();
	const { doLoginAsCompany } = AUTH;
	const { search } = useLocation();

	useEffect(() => {
		const email = new URLSearchParams(search).get("email");
		const company = new URLSearchParams(search).get("company");
		if (email && company) {
			doLoginAsCompany({email: email, company_id: company})
				.then(() => {
					navigate("/dashboard", { replace: true });
				})
				.catch(() => {
					//
				});
		}
	}, []);

	return (
		<section className="authPagesSection">
			<div className="authLeft">
				<div className="thumb">
					<img src={MacImg} alt="" />
				</div>
				<div className="contentWrap">
					<h1>The Entrepreneur&apos;s Stack</h1>
					<p>Take Control of Your Business and Create Radical Results.</p>
				</div>
			</div>
			<div className="authRight">
				<LogoComponent />
			</div>
		</section>
	);
};

export default LoginAsCompany;
