import React, { useEffect, useState } from "react";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/plusIcon.svg";
// import {

// 	useEdgesState,
// 	useNodesState,
// } from "reactflow";
import Modal from "antd/lib/modal/Modal";
import { Avatar, Button, Switch } from "antd";
import { Link } from "react-router-dom";

type AddProps = {
	mode: string;
	category: any;
};

const AccessSetting: React.FC<AddProps> = observer(({ category }) => {
	const { TEAM_MEMBER, AUTH, GOAL_STACK } = useStore();
	const { company, assignStack, project, stackModule } = AUTH;
	const { goalStackDetails, userAccessList, stackTableId } = GOAL_STACK;

	const { teamMemberList } = TEAM_MEMBER;
	const [userAccess, setUserAccess] = useState<any[]>([]);

	const [addAccess, setAddAccess] = useState<any>(false);

	useEffect(() => {
		//
	}, [userAccess, teamMemberList]);

	useEffect(() => {
		userAccessList &&
			setUserAccess(
				userAccessList.map((user: any) => {
					return user.id;
				})
			);
	}, [userAccessList]);

	const openAddAccess = () => {
		setAddAccess(true);
	};

	const closeAddAccess = () => {
		setAddAccess(false);
	};

	return (
		<>
			<Button
				//  onClick={openPopUp}

				className="btn-primary"
				onClick={openAddAccess}
			>
				<PlusIcon />
			</Button>

			{addAccess && (
				<Modal
					title="Access Settings"
					className="accessModal"
					open={addAccess}
					footer={
						<div className="btncenter">
							<Button
								// className="primaryBtn"
								onClick={() => {
									assignStack({
										user_id: userAccess,
										company_id: company.company_id,
										project_id: project.id,
										company_stack_modules_id: stackModule,
										company_stack_category_id: category.key,
										stack_table_id: stackTableId,
									}).then(() => {
										goalStackDetails({
											company_id: company.company_id,
											project_id: project.id,
											company_stack_modules_id: stackModule,
											company_stack_category_id: category.key,
										});
										closeAddAccess();
									});
								}}
							>
								Save
							</Button>
							<Link className="linkbtn" to={"/edit-team"}>
								Team Settings
							</Link>
						</div>
					}
					onCancel={() => {
						closeAddAccess();
						userAccessList &&
							setUserAccess(
								userAccessList.map((user: any) => {
									return user.id;
								})
							);
					}}
				>
					<div className="viewersListing">
						{teamMemberList?.length &&
							teamMemberList.map((member: any) => {
								const name = member.name?.split(" ");
								return (
									<div className="itemList" key={member.id}>
										<div
											className={
												member.is_owner ||
												userAccess.find((userId: any) => userId == member.id)
													? "thumb"
													: "thumb active"
											}
										>
											<Avatar
												style={{ backgroundColor: "#4863f6" }}
												src={
													member?.profile_image || member?.profile_image != ""
														? member?.profile_image
														: null
												}
											>
												<span style={{ color: "white" }}>
													{name
														? name[0].charAt(0) + name[1].charAt(0)
														: member.email.charAt(0) + member.email.charAt(1)}
												</span>
											</Avatar>
										</div>
										<div
											className={
												member.is_owner ||
												userAccess.find((userId: any) => userId == member.id)
													? "content"
													: "content active"
											}
										>
											<h6>{member.name ?? member.email}</h6>
											<span>{member.display_role}</span>
										</div>
										<div className="info">
											{
												// !member.is_owner &&
												<Switch
													disabled={member.is_owner}
													checked={
														userAccess.find(
															(userId: any) => userId == member.id
														) || member.is_owner
													}
													onChange={(checked) => {
														if (checked) {
															setUserAccess([...userAccess, ...[member.id]]);
														} else {
															setUserAccess(
																userAccess.filter((id) => id != member.id)
															);
														}
													}}
												/>
											}
										</div>
									</div>
								);
							})}
					</div>
				</Modal>
			)}
		</>
	);
});

export default AccessSetting;
