import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class OrganizationStore {
	constructor() {
		makeAutoObservable(this);
	}

	updateOrganization = async (id: number, data: any): Promise<any> => {
		return await axios
			.post(API_URL.ORGANIZATION.UPDATE(id), data)
			.then(({ data }) => {
				return data;
			});
	};

	changeLogo = async (id: number, logo: any) => {
		return await axios
			.post(API_URL.ORGANIZATION.CHANGE_LOGO(id), logo)
			.then(({ data }) => {
				return data;
			});
	};
}
