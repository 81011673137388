import React, { useEffect, useState } from "react";
import { Avatar, Button, Drawer, Image, Layout, Menu, Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import LogoComponent from "../../Components/LogoComponent";
import Config from "../../../../config/Config";
import defaultLogo from "../../../../assets/images/trello.svg";
import { PlusOutlined } from "@ant-design/icons";
// import { ReactComponent as TrashIcon } from "../../../../assets/images/trashIcon.svg";
// import { ReactComponent as HelpIcon } from "../../../../assets/images/helpIcon.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/images/settingIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../../assets/images/logoutIcon.svg";
// import { ReactComponent as NotificationIcon } from "../../../../assets/images/notificationIcon.svg";
// import { ReactComponent as EditIcon } from "../../../../assets/images/editIcon.svg";
import { ReactComponent as Archive } from "../../../../assets/images/archive.svg";
import { Scrollbars } from "react-custom-scrollbars-2";
import useStore from "../../../../store";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { observer } from "mobx-react";
import { CompanyProjectProps } from "../../../../store/CompanyProjectStore/CompanyProjectStore";
import EditAllCompanyProjectModal from "../../../app_pages/SharedComponent/EditAllCompanyProjectModal";

// interface AppSidebarViewProps {
// 	collapsed?: boolean;
// }

const SidebarView: React.FC<any> = observer(() => {
	const [open, setOpen] = useState<any>(false);
	const { AUTH, COMPANY_PROJECT, COMMON } = useStore();
	const { setAddEditProjectModal, setEditProjectDetail } = COMPANY_PROJECT;
	const { collapsed } = COMMON;
	const {
		logout,
		projectList,
		setProject,
		project,
		activeCompanyList,
		setCompany,
		user,
		setProjectList,
		company,
	} = AUTH;
	const location = useLocation();
	const [allCompanyProjectModal, setAllCompanyProjectModal] = useState(false);
	const closeAllCompanyProjectModal = () => {
		setAllCompanyProjectModal(false);
	};

	const items: ItemType[] = [
		// {
		// 	key: "1",
		// 	title: "Trash",
		// 	icon: <TrashIcon />,
		// 	label: <Link to="">Trash</Link>,
		// },
		// {
		// 	key: "2",
		// 	title: "Help",
		// 	icon: <HelpIcon />,
		// 	label: <Link to="">Help</Link>,
		// },
		{
			key: "/archive",
			title: "Archive",
			icon: <Archive />,
			label: <Link to="/archive">Archive</Link>,
		},

		{
			key: "3",
			title: "Company Settings",
			icon: <SettingsIcon />,
			label: <Link to="/setting">Settings</Link>,
		},
		{
			key: "5",
			title: "Logout",
			icon: <LogoutIcon />,
			label: (
				<Link to={"/"} onClick={logout}>
					Logout
				</Link>
			),
		},
	];

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		//
	}, [user, activeCompanyList, collapsed]);

	return (
		<Layout.Sider
			collapsed={collapsed}
			width={Config.sidebar_width}
			className="dashboardSidebar"
		>
			{user && (
				<Scrollbars autoHeight autoHeightMin={"100vh"} autoHeightMax={"100vh"}>
					<LogoComponent />
					<div className="userLogo">
						{company && (
							<Tooltip title={company.company_name} placement="top">
								<img
									src={
										company.company_logo ? company.company_logo : defaultLogo
									}
									alt="Logo"
								/>
							</Tooltip>
						)}
						{activeCompanyList && activeCompanyList?.length > 1 && (
							<div className="link">
								<Link to={"#"} onClick={showDrawer}>
									Switch
								</Link>
							</div>
						)}
						{activeCompanyList && activeCompanyList?.length > 1 && (
							<Drawer
								width={"auto"}
								placement="left"
								closable={false}
								onClose={onClose}
								open={open}
							>
								<div className="logoListing">
									<ul>
										{activeCompanyList &&
											activeCompanyList.map((company: any) => {
												return (
													<li
														key={company.company_id + "active-company-drawer"}
														onClick={() => {
															setCompany(company);
															if (company.project.length > 0) {
																setProjectList(company.project);
															} else {
																setProjectList([]);
															}
															setOpen(false);
														}}
													>
														<Tooltip
															title={company.company_name}
															placement="top"
														>
															<Image
																preview={false}
																src={
																	company && company.company_logo
																		? company.company_logo
																		: defaultLogo
																}
															/>
														</Tooltip>
													</li>
												);
											})}
									</ul>
								</div>
							</Drawer>
						)}
					</div>
					<div className="userInfoDetailsWrap">
						<div className="userInfoDetails">
							<div className="userPic">
								<figure>
									<Tooltip
										title={(user.first_name && user.last_name) ?? user.email}
										placement="top"
									>
										<Avatar
											size={60}
											style={{ backgroundColor: "#4863f6" }}
											src={user.profile_image}
										>
											{user.first_name && user.last_name
												? user.first_name.charAt(0) + user.last_name.charAt(0)
												: user.email &&
												user.email.charAt(0) + user.email.charAt(1)}
										</Avatar>
									</Tooltip>

									{/* <div className="userNotification">
									<Popover
										content={notificationContent}
										title="Notifications"
										trigger="click"
										placement="rightTop"
									>
										<NotificationIcon />
									</Popover>
								</div> */}
								</figure>
								<h2>{user && `${user.first_name} ${user.last_name}`}</h2>
							</div>
							{/* <Collapse
							className="menuAccordion"
							accordion
							expandIconPosition={"end"}
							defaultActiveKey={openKeys}
							onChange={onChange}
						>
							<Panel header="My Points" key="1">
								<ul className="submenuList">
									<li>
										Earned <span>31</span>
									</li>
									<li>
										Redeemed <span>64</span>
									</li>
									<li>
										Available <span>14</span>
									</li>
								</ul>
							</Panel>
							<Panel header="Awarded Points" key="2">
								<ul className="submenuList">
									<li>
										Earned <span>86</span>
									</li>
									<li>
										Awarded <span>32</span>
									</li>
									<li>
										Available <span>14</span>
									</li>
								</ul>
							</Panel>
						</Collapse> */}
							<div className="btnWrap">
								{/* <Button>Redeem</Button>
							<Button>Award</Button> */}
							</div>
						</div>
						<div className="refferedBlock">
							{/* <h3>Organization Reffered</h3>
						<ul>
							<li>
								<span>{user && user.totalClientReferrals}</span>
								Invited
							</li>
							<li>
								<span>{user && user.pendingClientReferrals}</span>
								Pending
							</li>
							<li>
								<span>{user && user.acceptedClientReferrals}</span>
								Referred
							</li>
						</ul> */}
							<Button className="commanButton">
								<Link to="/refer-client">
									Refer
									{/* <PlusOutlined/> */}
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										style={{ marginLeft: "5px" }}
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 5V19V5Z"
											fill="#4A5CFB"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M19 12H5H19Z"
											fill="#4A5CFB"
										/>
										<path
											d="M12 5V19M19 12H5"
											stroke="#4A5CFB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</Link>
							</Button>
						</div>
					</div>
					<div className="projectsListing">
						<div className="projectTitle">
							<span>Projects:</span>{" "}
							{company && company.is_owner && (
								<>
									{allCompanyProjectModal && (
										<EditAllCompanyProjectModal
											visible={allCompanyProjectModal}
											editProjectList={projectList?.map((project: any) => {
												return {
													project_id: project.id,
													is_deleted: project.is_deleted ?? 0,
													name: project.name,
													sequence: project.sequence,
												};
											})}
											closeEditProject={closeAllCompanyProjectModal}
										/>
									)}
									<div style={{ display: "flex", alignItems: "center" }}>
										<PlusOutlined
											style={{ cursor: "pointer", paddingRight: "12px" }}
											onClick={() => {
												setAddEditProjectModal(true);
											}}
										/>
										{/* <EditIcon
									onClick={openAllCompanyProjectModal}
									style={{ cursor: "pointer" }}
								/> */}
									</div>
								</>
							)}
						</div>
						<Scrollbars
							autoHeight
							autoHeightMin={"60px"}
							autoHeightMax={"120px"}
							className="sideBarScroll"
						>
							<ul className="listing">
								{projectList &&
									projectList.map((item: CompanyProjectProps) => {
										return (
											<li
												className={`${project &&
														project.id === item.id &&
														(location.pathname == "/" ||
															location.pathname == "/dashboard")
														? "active"
														: ""
													}`}
												key={item.id}
											>
												<Link
													to="/dashboard"
													onClick={() => {
														setProject(item);
														localStorage.setItem(
															"project",
															JSON.stringify(item)
														);
													}}
													title={item.name}
												>
													{item.name}
												</Link>
												{(company.is_owner || company.is_admin) && (
													<Button
														onClick={() => {
															setAddEditProjectModal(true);
															setEditProjectDetail(item);
														}}
														className="projectButton"
													>
														<svg
															width="21"
															height="20"
															viewBox="0 0 21 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<rect width="21" height="20" fill="#F5F5F5" />
															<path
																opacity="0.4"
																d="M20.2159 12.37C20.0115 12.07 19.7211 11.77 19.3446 11.58C19.0434 11.44 18.8497 11.21 18.6776 10.94C18.129 10.08 18.4517 8.95 19.3661 8.44C20.4418 7.87 20.7861 6.6 20.1621 5.61L19.4414 4.43C18.8282 3.44 17.4835 3.09 16.4185 3.67C15.4719 4.15 14.2563 3.83 13.7077 2.98C13.5356 2.7 13.4387 2.4 13.4603 2.1C13.4925 1.71 13.3634 1.34 13.1698 1.04C12.7718 0.42 12.051 0 11.255 0H9.73818C8.95288 0.02 8.23213 0.42 7.83411 1.04C7.62972 1.34 7.51138 1.71 7.5329 2.1C7.55441 2.4 7.4576 2.7 7.28548 2.98C6.73685 3.83 5.52126 4.15 4.58536 3.67C3.50961 3.09 2.17569 3.44 1.55176 4.43L0.831009 5.61C0.217835 6.6 0.562073 7.87 1.62706 8.44C2.54144 8.95 2.86417 10.08 2.32629 10.94C2.14342 11.21 1.94978 11.44 1.64858 11.58C1.28282 11.77 0.960099 12.07 0.78798 12.37C0.389954 12.99 0.411469 13.77 0.809495 14.42L1.55176 15.62C1.94978 16.26 2.69205 16.66 3.46658 16.66C3.83234 16.66 4.26263 16.56 4.60687 16.36C4.87581 16.19 5.19853 16.13 5.55353 16.13C6.61852 16.13 7.51138 16.96 7.5329 17.95C7.5329 19.1 8.52258 20 9.77045 20H11.2335C12.4706 20 13.4603 19.1 13.4603 17.95C13.4925 16.96 14.3854 16.13 15.4504 16.13C15.7946 16.13 16.1173 16.19 16.397 16.36C16.7413 16.56 17.1608 16.66 17.5373 16.66C18.3011 16.66 19.0434 16.26 19.4414 15.62L20.1944 14.42C20.5817 13.75 20.614 12.99 20.2159 12.37"
																fill="#676A78"
															/>
															<path
																d="M10.5124 12.8302C8.82343 12.8302 7.45724 11.5802 7.45724 10.0102C7.45724 8.44018 8.82343 7.18018 10.5124 7.18018C12.2013 7.18018 13.5352 8.44018 13.5352 10.0102C13.5352 11.5802 12.2013 12.8302 10.5124 12.8302"
																fill="#676A78"
															/>
														</svg>
													</Button>
												)}
											</li>
										);
									})}
							</ul>
						</Scrollbars>
					</div>
					<div className="sidebarNavigation">
						<Menu
							mode="inline"
							items={items}
							selectedKeys={[
								location.pathname.includes("setting") ? "3" : location.pathname,
							]}
						/>
					</div>
				</Scrollbars>
			)}
		</Layout.Sider>
	);
});

export default SidebarView;
