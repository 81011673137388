import { Badge, Card, Checkbox, Col, Popover, Row } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ReactComponent as SettingIcon } from "../../../../../../../../assets/images/settingIcon.svg";
import { ReactComponent as DownArrow } from "../../../../../../../../assets/images/downIcon.svg";
// import { ReactComponent as ResizeIcon } from "../../../../../../../../assets/images/resizeIcon.svg";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import DateRange from "./DateRange";
//import type { ColumnsType } from "antd/es/table";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import useStore from "../../../../../../../../store";
import { Link } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);
// interface ButtonProps {
//   editOpen: () => void;
// }
interface ButtonProps {
	open?: any;
	graphAxes?: any;
	chartId?: any;
	mode?: any;
}


const PieChart: React.FC<ButtonProps> = observer(
	({ open, graphAxes, chartId, mode }) => {
		const [graphData, setGraphData] = useState();
		const [goalData, setGoalData] = useState<any>();
		const [showDatePicker, setShowDatePicker] = useState(false);
		const [isGoal, setIsGoal] = useState();
		const { SCORECARD_STACK } = useStore();
		const { setUniqueId,setEditGraphData,setDefaultDateChacked,defaultDateChecked,setGraphLabel } = SCORECARD_STACK;
		//	console.log(graphAxes)
		useEffect(() => {
			// graphAxes?.map((d: any) => {
			// 	if (chartId === d?.graph_id && d?.bar_name === 7) {
			// 		setGraphData(d?.datasets);
			// 		setGoalData(d?.goal_difference);
			// 	}
			// });
			if (graphAxes?.bar_name === 7) {
				setGraphData(graphAxes?.datasets);
				setIsGoal(graphAxes?.compare_goal);
				setGoalData(graphAxes?.goal_difference || graphAxes?.datasets);
				setDefaultDateChacked(graphAxes?.dateChecked)
			}
		}, [graphAxes]);
		const onChange = (e: CheckboxChangeEvent) => {
			setDefaultDateChacked(e.target.checked)
		};
		const title = (
			<div>
				<Checkbox onChange={onChange} checked={defaultDateChecked}>Set As Default</Checkbox>
			</div>
		);
		const content = (
			<div>
				<DateRange chartId={chartId} setShowDatePicker={setShowDatePicker}/>
			</div>
		);

		const Chartdata = {
			// labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
			datasets: graphData ? graphData : [],
		};
		const openDrawer = (id: any) => {
			open(id);
			setUniqueId(chartId);
			setEditGraphData(graphAxes?.datasets)
			setGraphLabel(graphAxes?.label)
		};
		const handleVisibleChange = (newVisible:any) => {
			setShowDatePicker(newVisible);
		};

		return (
			<>
				<div
					className="horizontalContainer"
					
				>
					<Card>
						<div className="cardHeader">
							<h4>{graphAxes?.graph_name}</h4>
							{mode === "edit" && (
								<div className="headerIcon" onDoubleClick={() => openDrawer(7)}>
									<Link to="/">{graphAxes?.matricData?.length==0 || !graphAxes?.datasets?"Configure This Graph":""}</Link>
									<SettingIcon />
								</div>
							)}
						</div>
						<div className="cardContent">
							<Popover
								content={content}
								title={title}
								trigger="click"
								open={showDatePicker}
								placement="bottomLeft"
								overlayClassName={"graphPopover"}
								onOpenChange={handleVisibleChange}
							>
								<div onClick={()=>{setShowDatePicker(true);setEditGraphData({
											date_label: graphAxes?.date_label,
											graph_from_date: graphAxes?.graph_from_date,
											graph_to_date: graphAxes?.graph_to_date,
										});}}>
								{
									mode==="view"?<div className="popoverLink">
									{
										graphAxes?.date_label
									}
									<DownArrow />
								</div>:""
								}
								</div>
								
							</Popover>
							<Row>
								<Col span={8}>
									<Pie data={Chartdata} />
								</Col>
								<Col span={16}>
									<Row className="headingRow">
										<Col span={10}>Metric</Col>
										<Col span={10}>Current</Col>
										<Col span={4}>{isGoal === true?"Goal":""}</Col>
									</Row>
									{goalData?.map((d: any) => {
										let displayValue = "";
										if (d?.format_of_metric && d?.format_of_metric === "$") {
											displayValue = d?.format_of_metric + d?.display_value;
										} else if (d?.format_of_metric && d?.format_of_metric?.toString().toLowerCase() === "qty") {
											displayValue = d?.display_value;
										} else if(d?.format_of_metric) {
											displayValue = d?.display_value + d?.format_of_metric;
										} else {
											displayValue = d?.display_value;
										}
										return (
											<Row key={d?.name} className="dataRow">
												<Col span={10}>
													<Badge color={d?.color} />
													<span style={{ margin: "0px 8px" }}>
														{d?.name || d?.label}
													</span>
												</Col>
												<Col span={10}>
													<Badge className="statusCheck" color={d?.color} />
													<span className="currentData">{displayValue}</span>
													<Badge className="ratioRate" color={"#C9CCDA"} />
													<span className="currentData">
														{d?.gole_complition_percent}%
													</span>
												</Col>
												<Col span={4}>{isGoal === true ? d?.goal : ""}</Col>
											</Row>
										);
									})}
								</Col>
							</Row>
						</div>
						{/* <div className="cardFooter">
								<ResizeIcon />
							</div> */}
					</Card>
				</div>
			</>
		);
	}
);

export default PieChart;
