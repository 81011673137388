import React from "react";
import ListComponent from "./Component/ListComponent";

const Archive = () => {
	return (
		<div className="archiveSection modContainerDashboard">
			<h1 className="archiveHeading">Archive</h1>
			<div className="archiveLayout">
				<ListComponent />
			</div>
		</div>
	);
};

export default Archive;
