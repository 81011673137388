import React, { useState } from "react";
import { Divider, Drawer, Form, Segmented, Select, Switch } from "antd";
// import TextArea from "antd/lib/input/TextArea";
import { ReactComponent as TrashIcon } from "../../../../../../../assets/images/trashIcon.svg";
import { ReactComponent as LeftAlign } from "../../../../../../../assets/images/leftalign.svg";
import { ReactComponent as RightAlign } from "../../../../../../../assets/images/rightalign.svg";
import { ReactComponent as CenterAlign } from "../../../../../../../assets/images/centeralign.svg";
import { ReactComponent as NoneIcon } from "../../../../../../../assets/images/none.svg";

import moment from "moment";

/* Don't Remove it will be used in next phase */
// import { ReactComponent as FavoritesIcon } from "../../../../../assets/images/FavoritesIcon.svg";
// import { InputWrapper } from "../../../../../components/AntdAddons/functions";
import useStore from "../../../../../../../store";
import { observer } from "mobx-react";
import FormItem from "antd/lib/form/FormItem";
import { ReactComponent as DropdownIcon } from "../../../../../../../assets/images/downIcon.svg";
import { displayFormats } from "../../../../../../../config/Global";
import { FormBox, InputBox } from "../../../../../../../components/AntdAddons";
const DrawerView = observer(
	({
		open,
		onClose,
		nodeData,
		setNodes,
		setNodeData,
		mode,
		nodes,
		reactFlowInstance,
		updateNodeSize,
	}: any) => {
		const { TEAM_MEMBER, GOAL_STACK } = useStore();
		const { teamMemberList } = TEAM_MEMBER;
		const { Option } = Select;
		const { setGoalDataState } = GOAL_STACK;

		const [isGoal, setIsGoal] = useState(nodeData.data.is_due_date ?? 0);

		const [form] = Form.useForm();

		/* Don't Remove it will be used in next phase */
		// const [isFavorite, setIsFavorite] = useState(
		// 	nodeData.data.is_favorite ?? 0
		// );

		const [isAssigned, setIsAssigned] = useState(
			nodeData.data.is_assigned ?? 0
		);

		const title: () => string = () => {
			if (nodeData.data.type == "header") {
				return "Edit Header";
			} else if (nodeData.data.type == "textBox") {
				return "Edit Text Box";
			} else if (nodeData.data.type == "goal") {
				return "Edit Goal";
			}
			return "Edit";
		};

		const updateNode = async (type: string, newVal: any, field: string) => {
			const newObj: any = {};
			type;
			await setNodes((nodes: any) => {
				if (nodeData.parentNode) {
					updateNodeSize(nodeData.parentNode);
				}
				return nodes.map((node: any) => {
					if (node.id == nodeData.id) {
						if (field == "goal" || field == "goal_date") {
							if (newVal && Number(newVal) >= 0) {
								newObj[field] = newVal;
							} else {
								newObj[field] = 0;
							}
						} else {
							newObj[field] = newVal;
						}
						setGoalDataState(1);

						const newData = {
							...node,
							data: { ...node.data, ...newObj },
						};

						setNodeData(newData);
						return newData;
					} else {
						return node;
					}
				});
			});
		};

		const deleteNode = async () => {
			if (mode == "edit") {
				setGoalDataState(1);
			}

			const newNodes = await nodes.filter((node: any) => {
				if (node.id == nodeData.id) {
					return true;
				}
			});
			reactFlowInstance.deleteElements({ nodes: newNodes });

			setTimeout(() => {
				setNodeData();
				onClose();
			}, 10);
		};

		return (
			<Drawer
				destroyOnClose
				className="metricDrawer goalDrawer"
				title={title()}
				placement="right"
				closable={true}
				onClose={onClose}
				open={open}
				getContainer={false}
				footer={
					<>
						<div onClick={deleteNode} className="deleteFooter">
							<span>
								<TrashIcon /> Delete
							</span>
						</div>
					</>
				}
			>
				<FormBox form={form}>
					{nodeData.data.type == "header" && (
						<div>
							<InputBox.Text
								label="Header Name"
								defaultValue={nodeData.data.title ?? ""}
								onChange={(e) => {
									updateNode(nodeData.data.type, e.target.value, "title");
								}}
							/>
						</div>
					)}
					{nodeData.data.type == "goal" && (
						<div className="tabFormWrap">
							<Segmented
								value={nodeData.data.goal_type ?? 1}
								options={[
									{ value: 1, label: "Goal" },
									{ value: 2, label: "Milestone" },
									{ value: 3, label: "Custom" },
								]}
								onChange={(t) => {
									updateNode(nodeData.data.type, t, "goal_type");
								}}
							/>
							<InputBox.TextArea
								name="name"
								label="Name"
								defaultValue={nodeData.data.name ?? ""}
								onChange={(e) => {
									updateNode(nodeData.data.type, e.target.value, "name");
								}}
								size="large"
							/>
							<InputBox.TextArea
								required
								name="category"
								label={"Description"}
								onChange={(e) => {
									updateNode(nodeData.data.type, e.target.value, "description");
								}}
								defaultValue={nodeData.data.description}
								placeholder="N/A"
							/>
							<InputBox.Select
								name="font"
								onChange={(val) => {
									updateNode(nodeData.data.type, val, "font");
								}}
								defaultValue={nodeData.data.font ?? ""}
								label={"Font"}
								placeholder={"Select Font"}
								options={{
									list: [
										{ id: 1, name: "Roboto" },
										{ id: 2, name: "Inter" },
										{ id: 3, name: "Poppins" },
									],
									valueKey: "id",
									textKey: "name",
								}}
								showArrow={false}
								extra={<DropdownIcon />}
							/>
							<InputBox.Select
								name="size"
								label={"Size"}
								onChange={(val) => {
									updateNode(nodeData.data.type, val, "font_size");
								}}
								defaultValue={nodeData.data.font_size ?? 1}
								placeholder={"24pt"}
								options={{
									list: [
										{ id: 1, name: "14pt" },
										{ id: 2, name: "16pt" },
										{ id: 3, name: "18pt" },
										{ id: 4, name: "20pt" },
										{ id: 5, name: "22pt" },
										{ id: 6, name: "24pt" },
									],
									valueKey: "id",
									textKey: "name",
								}}
								showArrow={false}
								extra={<DropdownIcon />}
							/>
							<div className="nodeInfoDropdown">
								<div className="nodeDropdownList">
									<div className="item">
										<div className="dropdownWrap">
											<FormItem label={"Alignment:"}></FormItem>
											<Select
												onChange={(val) => {
													updateNode(nodeData.data.type, val, "alignment");
												}}
												defaultValue={nodeData.data.alignment ?? "alignCenter"}
												// showArrow={false}s
												//  extra=  {<DropdownIcon />}
											>
												<Option value="alignLeft">
													<LeftAlign />
												</Option>
												<Option value="alignRight">
													<RightAlign />
												</Option>
												<Option value="alignCenter">
													<CenterAlign />
												</Option>
											</Select>
										</div>
									</div>
									<div className="item">
										<div className="dropdownWrap">
											<FormItem label={"Assign Color:"}></FormItem>
											<Select
												onChange={(val) => {
													updateNode(nodeData.data.type, val, "assigned_color");
												}}
												defaultValue={nodeData.data.assigned_color ?? "noColor"}
												showArrow={false}
											>
												<Option value="colorPink">
													<div className="colorPink"></div>
												</Option>
												<Option value="colorOrange">
													<div className="colorOrange"></div>
												</Option>
												<Option value="colorGreen">
													<div className="colorGreen"></div>
												</Option>
												<Option value="noColor">
													<div className="noColor">
														<NoneIcon />
													</div>
												</Option>
											</Select>
										</div>
									</div>
									<div className="item">
										<div className="dropdownWrap">
											<FormItem label={"Text Color:"}></FormItem>
											<Select
												onChange={(val) => {
													updateNode(nodeData.data.type, val, "text_color");
												}}
												defaultValue={
													nodeData.data.text_color &&
													nodeData.data.text_color != ""
														? nodeData.data.text_color
														: "colorBlack"
												}
												showArrow={false}
											>
												<Option value="colorPink">
													<div className="colorPink"></div>
												</Option>
												<Option value="colorOrange">
													<div className="colorOrange"></div>
												</Option>
												<Option value="colorGreen">
													<div className="colorGreen"></div>
												</Option>
												<Option value="colorBlack">
													<div className="colorBlack"></div>
												</Option>
											</Select>
										</div>
									</div>
									<div className="textDecoration">
										<div className="goalText">
											<span>Bold</span>
											<Switch
												defaultChecked={nodeData.data.isBold}
												onChange={(checked) =>
													updateNode(nodeData.data.type, checked, "isBold")
												}
											/>
										</div>
										<Divider />
										<div className="goalText">
											<span>Italic</span>
											<Switch
												defaultChecked={nodeData.data.isItalic}
												onChange={(checked) =>
													updateNode(nodeData.data.type, checked, "isItalic")
												}
											/>
										</div>
										<Divider />
										<div className="goalText">
											<span>Underlined</span>
											<Switch
												defaultChecked={nodeData.data.isUnderlined}
												onChange={(checked) =>
													updateNode(
														nodeData.data.type,
														checked,
														"isUnderlined"
													)
												}
											/>
										</div>
										<Divider />
										<div className="goalText">
											<span>Capitalize</span>
											<Switch
												defaultChecked={nodeData.data.isCapitalize}
												onChange={(checked) =>
													updateNode(
														nodeData.data.type,
														checked,
														"isCapitalize"
													)
												}
											/>
										</div>
									</div>
								</div>
								{/* <div style={{ textAlign: "center" }}>
									<div style={{ width: "200px" }}>
										<span>Bold</span>
										<Switch
											defaultChecked={nodeData.data.isBold}
											onChange={(checked) =>
												updateNode(nodeData.data.type, checked, "isBold")
											}
										/>
									</div>
									<div style={{ width: "200px" }}>
										<span>Italic</span>
										<Switch
											defaultChecked={nodeData.data.isItalic}
											onChange={(checked) =>
												updateNode(nodeData.data.type, checked, "isItalic")
											}
										/>
									</div>
									<div style={{ width: "200px" }}>
										<span>Underlined</span>
										<Switch
											defaultChecked={nodeData.data.isUnderlined}
											onChange={(checked) =>
												updateNode(nodeData.data.type, checked, "isUnderlined")
											}
										/>
									</div>
									<div style={{ width: "200px" }}>
										<span>Capitalize</span>
										<Switch
											defaultChecked={nodeData.data.isCapitalize}
											onChange={(checked) =>
												updateNode(nodeData.data.type, checked, "isCapitalize")
											}
										/>
									</div>
								</div> */}
							</div>
							<div className="switchListing">
								<div className="switchItem">
									<div className={`switchTitle ${!isGoal ? "goalLine" : ""}`}>
										<span>Due Date</span>
										<Switch
											onChange={(checked) => {
												setIsGoal(checked);
												updateNode(
													nodeData.data.type,
													checked ? 1 : 0,
													"is_due_date"
												);
												!checked &&
													updateNode(
														nodeData.data.type,
														"",
														"achievement_date"
													);
											}}
											defaultChecked={nodeData.data.is_due_date ?? 0}
										/>
									</div>
								</div>
								{isGoal ? (
									<InputBox.DatePicker
										name="achievement_date"
										defaultValue={
											nodeData.data.achievement_date
												? moment(nodeData.data.achievement_date)
												: undefined
										}
										inputReadOnly={true}
										// rules={CreateGoalRequest().goal}
										format={displayFormats.DATE_FORMAT}
										// disabledDate={(current) => {
										// 	const customDate = moment().format(
										// 		displayFormats.DATE_FORMAT
										// 	);
										// 	return (
										// 		current &&
										// 		current < moment(customDate, displayFormats.DATE_FORMAT)
										// 	);
										// }}
										onChange={(value) => {
											// console.log("value", value);

											updateNode(
												nodeData.data.type,
												value
													? moment(value).format(displayFormats.DATE_FORMAT)
													: null,
												"achievement_date"
											);
										}}
										label="Achievement Date"
									/>
								) : (
									""
								)}
								<div className="switchItem">
									<div className="switchTitle">
										{/* <span>Reminders</span> */}
										<span>Assign</span>
										<Switch
											onChange={(checked) => {
												setIsAssigned(checked);
												updateNode(
													nodeData.data.type,
													checked ? 1 : 0,
													"is_assigned"
												);
												if (!checked) {
													updateNode(nodeData.data.type, null, "assigned_to");
													updateNode(nodeData.data.type, null, "avatar");
													form.setFieldValue("assignedTo", undefined);
													updateNode(nodeData.data.type, undefined, "user");
												}
											}}
											defaultChecked={nodeData.data.is_assigned ?? isAssigned}
										/>
									</div>
									{isAssigned ? (
										<div className="switchItemWrap">
											<InputBox.Select
												required
												name="assignedTo"
												showArrow={false}
												label={"Assign To"}
												defaultValue={nodeData.data.assigned_to}
												onChange={(user) => {
													const userObj = teamMemberList.find(
														(member: any) => member.id == user
													);
													updateNode(nodeData.data.type, user, "assigned_to");
													updateNode(
														nodeData.data.type,
														userObj.profile_image,
														"avatar"
													);
													updateNode(nodeData.data.type, userObj, "user");
												}}
												maxLength={100}
												options={{
													list: teamMemberList,
													valueKey: "id",
													textKey: "name",
													alternativeTextKey: "email",
												}}
												extra={<DropdownIcon />}
											/>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						</div>
					)}
					{nodeData.data.type == "dynamicGroup" && (
						<InputBox.Text
							name={"groupName"}
							label="Name"
							defaultValue={nodeData.data.name ?? ""}
							onChange={(e) => {
								updateNode(nodeData.data.type, e.target.value, "name");
							}}
						/>
					)}
				</FormBox>
			</Drawer>
		);
	}
);

export default DrawerView;
