import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";
import { deleteFileProps, uploadFileProps } from "./CommonProps";

export type MetricCategory = {
	id: number;
	name: string;
};

export default class CommonStore {
	private rootStore: RootStore;
	public translationData?: any;
	public selectedLanguage: any;
	public rolesList?: any[];
	public rolesTypeList?: any[];
	public metricCategoryList?: any;
	public metricFormatList?: any;
	public metricList?: any[];
	public privilegesList?: any[];
	public companyList?: any[];
	public statusFilter?: string[];
	public stackModuleList?: any[];
	public scorecardTypeList?: any[];
	public deleteModal = false;
	public deleteModalDetail: any;
	public EditModal = false;
	public EditModalDetail: any;
	public metricMetricGroup?: any[];
	public collapsed = false;
	public scorecardDays?: any[];

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	public setDeleteModal = (open: boolean): void => {
		this.deleteModal = open;
	};

	private setScorecardDays = (days?: any[]): void => {
		this.scorecardDays = days;
	};

	public setDeleteModalDetail = (
		moduleName?: string,
		functionName?: void,
		id?: number
	): void => {
		if (moduleName && functionName && id) {
			this.deleteModalDetail = {
				deleteModuleName: moduleName,
				deleteFunction: functionName,
				deleteId: id,
			};
		} else {
			this.deleteModalDetail = null;
		}
	};
	// Edit function
	public setEditModal = (open: boolean): void => {
		this.EditModal = open;
	};

	public setEditModalDetail = (
		moduleName?: string,
		functionName?: void,
		id?: number
	): void => {
		if (moduleName && functionName && id) {
			this.EditModalDetail = {
				EditModuleName: moduleName,
				EditFunction: functionName,
				EditId: id,
			};
		} else {
			this.EditModalDetail = null;
		}
	};
	public setTranslationData = (value?: any): void => {
		this.translationData = value;
	};

	public setSelectedLanguage = (selectedLanguage: any) => {
		this.selectedLanguage = selectedLanguage;
	};

	public setRolesList = (roles?: any) => {
		this.rolesList = roles;
	};

	public setRoleTypeList = (roles: any) => {
		this.rolesTypeList = roles;
	};

	public setMetricCategoryList = (categoryList: MetricCategory | []) => {
		this.metricCategoryList = categoryList;
	};

	public setMetricFormatList = (metricFormats: any) => {
		this.metricFormatList = metricFormats;
	};

	public setMetricList = (metrics: any[]) => {
		this.metricList = metrics;
	};

	public setPrivilegesList = (privileges: any[]) => {
		this.privilegesList = privileges;
	};

	public setCompanyList = (companies: any) => {
		this.companyList = companies;
	};

	private setStatusFilter = (value?: any) => {
		this.statusFilter = value;
	};

	private setStackModuleList = (value?: any) => {
		this.stackModuleList = value;
	};

	public setMetricMetricGroup = (value?: any) => {
		this.metricMetricGroup = value;
	};

	private setScorecardTypeList = (value?: any) => {
		this.scorecardTypeList = value;
	};

	public setCollapsed = (value: boolean) => {
		this.collapsed = value;
	};

	// API Functions
	public loadTranslationData = async (locale: string) => {
		return await axios.get(API_URL.LANGUAGE_DATA(locale)).then(({ data }) => {
			this.setTranslationData(data);
			return data;
		});
	};

	public fetchMetricCategoryList = async (
		search?: string,
		access_from?: 1 | 0
	) => {
		const params = { search, access_from };
		this.setMetricCategoryList([]);
		return await axios
			.get(API_URL.COMMON.METRIC_CATEGORY_LIST, { params })
			.then(({ data }) => {
				this.setMetricCategoryList(data.data);
				return data.data;
			});
	};

	public fetchSync = async () => {
		return await axios.post(API_URL.COMMON.SYNC).then(({ data }) => {
			this.setMetricFormatList(data.data.formatOfMatrix);
			this.setStatusFilter(data.data.status);
			this.setRoleTypeList(data.data.roleType);
			this.setStackModuleList(data.data.stackModuleList);
			this.setScorecardTypeList(data.data.scorecardType);
			this.setScorecardDays(data.data.days);
			return data.data;
		});
	};

	public fetchMetricList = async (payload: any) => {
		return await axios
			.get(API_URL.COMMON.METRIC_LIST, { params: payload })
			.then(({ data }) => {
				this.setMetricList(data.data.rows);
				return data.data;
			});
	};

	public fetchPrivilegesList = async (menu_type?: 1 | 2) => {
		return await axios
			.get(API_URL.COMMON.PRIVILEGES(menu_type))
			.then(({ data }) => {
				this.setPrivilegesList(data.data);
			});
	};

	public fetchRolesList = async (
		search?: string,
		role_type?: 1 | 2
	): Promise<any> => {
		return await axios
			.get(API_URL.COMMON.ROLES(search, role_type))
			.then(({ data }) => {
				this.setRolesList(data.data);
			});
	};

	public fetchCompanyList = async (search?: string) => {
		return await axios
			.get(API_URL.COMMON.COMPANY_LIST(search))
			.then(({ data }) => {
				this.setCompanyList(data.data);
				return data.data;
			});
	};

	public fetchMetricMetricGroup = async (payload: any) => {
		return await axios
			.get(API_URL.COMMON.WEB_METRIC_METRIC_GROUP, {params: payload})
			.then(({ data }: any) => {
				this.setMetricMetricGroup(data.data);
				return data.data;
			});
	};

	public uploadFile = async (payload: uploadFileProps) => {
		return await axios
			.post(API_URL.COMMON.UPLOAD_FILE, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public deleteFile = async (payload: deleteFileProps) => {
		return await axios
			.post(API_URL.COMMON.DELETE_FILE, payload)
			.then(({ data }) => {
				return data;
			});
	};
}
