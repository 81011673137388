import { Card, Col, Divider, Row } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ReactComponent as SettingIcon } from "../../../../../../../../assets/images/settingIcon.svg";
import { ReactComponent as DownArrow } from '../../../../../../../../assets/images/downFill.svg';
import { ReactComponent as UpGreenIcon } from "../../../../../../../../assets/images/upfillIcon.svg";
import useStore from "../../../../../../../../store";
import { Link } from "react-router-dom";

interface ButtonProps {
	open?: any;
	graphAxes?: any;
	chartId?: any;
	mode?: any;
}
const DifferenceGraph: React.FC<ButtonProps> = observer(
	({ open, graphAxes, chartId, mode }) => {
		const [graphData, setGraphData] = useState<any>();
		const [isGoal, setisGoal] = useState();
		const { SCORECARD_STACK } = useStore();
		const { setUniqueId,setEditGraphData } = SCORECARD_STACK;
		useEffect(() => {
			if (graphAxes?.bar_name === 4) {
				setGraphData(graphAxes?.datasets);
				setisGoal(graphAxes?.compare_goal);
			}
		}, [graphAxes]);
		const openDrawer = (id: any) => {
			open(id);
			setUniqueId(chartId);
			setEditGraphData(graphAxes)
		};
		return (
			<>
				<div
					className="horizontalContainer"
				>
					<Card>
						<div className="cardHeader">
							<h4 style={{ width: "90%", textAlign: "center" }}>
								{graphAxes?.graph_name}
							</h4>
							{mode === "edit" && (
								<div className="headerIcon" onDoubleClick={() => openDrawer(4)}>
									<Link to="/">{graphAxes?.datasets?.length==0 || !graphAxes?.datasets?"Configure This Graph":""}</Link>
									<SettingIcon />
								</div>
							)}
						</div>
						<div className="cardContent">
							<Row className="headingRow">
								<Col span={9}>Metric</Col>
								<Col span={5}>Current</Col>
								{isGoal === true && <Col span={5}>Goal</Col>}
								<Col span={5}>Difference</Col>
							</Row>
							<Divider />
							{graphData?.map((d: any) => {
								let displayValue = "";
								if (d?.format_of_metric && d?.format_of_metric === "$") {
									displayValue = d?.format_of_metric + d?.display_value;
								} else if (d?.format_of_metric && d?.format_of_metric?.toString().toLowerCase() === "qty") {
									displayValue = d?.display_value;
								} else if(d?.format_of_metric) {
									displayValue = d?.display_value + d?.format_of_metric;
								} else {
									displayValue = d?.display_value;
								}
								
								return (
									<Row key={d?.matrix_name} className="dataRow">
										<Col span={9}>{d?.name || d?.label}</Col>
										<Col span={5}> {displayValue} </Col>
										{isGoal === true && <Col span={5}>{d?.goal_type == 1 ? "> " : "< "}{d?.display_goal}</Col>}
										<Col span={5}>
											{d?.difference && parseFloat(d?.difference?.replaceAll(",")) < 0 ? <DownArrow/> : <UpGreenIcon/> }  {d?.difference}
										</Col>
									</Row>
								);
							})}
						</div>
					</Card>
				</div>
			</>
		);
	}
);

export default DifferenceGraph;
