import React, { useState } from "react";
import { Button, Form, Modal } from "antd";

import { observer } from "mobx-react";
import FormComponent from "./FormComponent";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import useStore from "../../../../../store";
import { DuplicateCategoryProps } from "../../../../../store/CompanyStackCategoryStore/CompanyStackCategoryProps";
import { AddStackCategoryRequest } from "../../../../../requests/AddStackCategoryRequest";
import { useTranslation } from "react-i18next";
import { ReactComponent as DropdownIcon } from "../../../../../assets/images/downIcon.svg";
interface AddProps {
	closeAddTemplate: () => void;
	visible?: boolean;
	type?: string;
	// setType: any;
}

const AddStackModule: React.FC<AddProps> = observer(
	({ visible, closeAddTemplate }) => {
		const { AUTH, COMPANY_STACK_MODULE_CATEGORY } = useStore();
		const { duplicateCategory } = COMPANY_STACK_MODULE_CATEGORY;
		const {
			stackModuleCategoryList,
			fetchAuthUser,
			stackModule,
			company,
			project,
		} = AUTH;

		const { t } = useTranslation();

		const [duplicateForm] = Form.useForm();
		const [form] = Form.useForm();

		const [duplicate, setDuplicate] = useState(false);
		const [module, setModule] = useState<any>();

		const openDuplicateModal = () => {
			setDuplicate(true);
		};

		const closeMainModal = () => {
			form.resetFields();
			duplicateForm.resetFields();
			closeAddTemplate();
		};

		const closeDuplicateModal = () => {
			form.resetFields();
			duplicateForm.resetFields();
			setDuplicate(false);
		};

		return (
			<>
				<Modal
					title={"Create New Subcategory"}
					className="addTemplateModel"
					open={visible}
					footer={null}
					onCancel={closeMainModal}
				>
					<FormComponent closeAddTemplate={closeMainModal} form={form} />
					<a
						className="existingLink"
						onClick={(e) => {
							e.preventDefault();
							closeMainModal();
							openDuplicateModal();
						}}
					>
						Duplicate an existing
					</a>
				</Modal>
				<Modal
					title="Duplicate an existing"
					onCancel={closeDuplicateModal}
					footer={null}
					open={duplicate}
					className="existingModal"
				>
					<FormBox
						form={duplicateForm}
						onFinish={() => {
							const data: DuplicateCategoryProps = {
								company_id: company.company_id,
								project_id: project.id,
								name: module.name,
								company_stack_category_id: module.id,
								company_stack_modules_id: stackModule,
							};
							duplicateCategory(data).then(() => {
								fetchAuthUser();
								closeDuplicateModal();
							});
						}}
					>
						<InputBox.Select
							label="Subcategory Name"
							name={"subcategory"}
							showArrow={false}
							extra={<DropdownIcon />}
							options={{
								list: stackModuleCategoryList ?? [],
								textKey: "name",
								valueKey: "category_id",
							}}
							rules={AddStackCategoryRequest(t).duplicate}
							onChange={(value, option: any) => {
								setModule({
									id: value,
									name: option.children,
								});
							}}
						/>
						<Button htmlType="submit">Create Subcategory</Button>
					</FormBox>
				</Modal>
			</>
		);
	}
);

export default AddStackModule;
