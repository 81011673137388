import { createContext, useContext } from "react";
import { Context } from "vm";
import AllTransactionStore from "./AllTransactionStore/AllTransactionStore";
import { ArchiveStore } from "./ArchiveStore/ArchiveStore";
import AuthStore from "./AuthStore/AuthStore";
import CommonStore from "./CommonStore/CommonStore";
import { CompanyProjectStore } from "./CompanyProjectStore/CompanyProjectStore";
import { CompanyStackCategoryStore } from "./CompanyStackCategoryStore/CompanyStackCategoryStore";
import { CompanyStackModuleStore } from "./CompanyStackModuleStore/CompanyStackModuleStore";
import GoalStackStore from "./GoalStackStore/GoalStackStore";
import MetricStore from "./MetricsStore/MetricsStore";
import OrganizationStore from "./OrganizationStore";
import { ReferClientStore } from "./ReferClientStore/ReferClientStore";
import RootStore from "./RootStore/RootStore";
import ScorecardStackStore from "./ScoreCardStack/ScorecardStackStore";
import { TeamMemberStore } from "./TeamMemberStore/TeamMemberStore";
import TeamStackStore from "./TeamStackStore/TeamStackStore";
import UserStore from "./UserStore/UserStore";

const commonStore = new CommonStore();
const AppContext = createContext({
	ROOT: new RootStore(),
	COMMON: commonStore,
	AUTH: new AuthStore(),
	USER: new UserStore(),
	ALL_TRANSACTIONS: new AllTransactionStore(),
	COMPANY_SETTING: new OrganizationStore(),
	COMPANY_PROJECT: new CompanyProjectStore(),
	TEAM_MEMBER: new TeamMemberStore(),
	REFER_CLIENT: new ReferClientStore(),
	COMPANY_STACK_MODULE: new CompanyStackModuleStore(),
	COMPANY_STACK_MODULE_CATEGORY: new CompanyStackCategoryStore(),
	TEAM_STACK: new TeamStackStore(),
	SCORECARD_STACK: new ScorecardStackStore(),
	METRIC: new MetricStore(commonStore),
	GOAL_STACK: new GoalStackStore(),
	ARCHIVE: new ArchiveStore(commonStore)
});

const useStore = (): Context => useContext(AppContext);

export default useStore;
