import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";

export default class TeamStackStore {
	teamStackList?: any[];
	teamStack?: any;
	userAccessList?: any[];
	stackTableId?: any;
	teamDataState?: 0 | 1 | 2 = 0;

	constructor() {
		makeAutoObservable(this);
	}

	setTeamDataState = (state: 0 | 1 | 2) => {
		this.teamDataState = state;
	};

	setTeamStackList = (values?: any) => {
		this.teamStackList = values;
	};

	setUserAccessList = (value?: any) => {
		this.userAccessList = value;
	};

	setTeamStack = (values?: any) => {
		this.teamStack = values;
	};

	setStackTableId = (value?: any) => {
		this.stackTableId = value;
	};

	fetchTeamStack = async (): Promise<any> => {
		return await axios.get(API_URL.TEAM_STACK.INDEX).then(({ data }) => {
			this.setTeamStackList(data.rows);
		});
	};

	createTeamStack = async (data: any): Promise<any> => {
		return await axios
			.post(API_URL.TEAM_STACK.CREATE, data)
			.then(this.fetchTeamStack);
	};

	teamStackSave = async (
		company_id: number,
		project_id: number,
		company_stack_modules_id: number,
		company_stack_category_id: number,
		team_stack_data: any[]
	): Promise<any> => {
		return await axios.post(API_URL.TEAM_STACK.SAVE, {
			company_id,
			project_id,
			company_stack_modules_id,
			company_stack_category_id,
			team_stack_data,
		});
	};

	teamStackDetails = async (
		company_id: number,
		project_id: number,
		company_stack_modules_id: number,
		company_stack_category_id: number,
		team_stack_data?: any[]
	): Promise<any> => {
		this.setTeamStack();
		this.setUserAccessList();
		this.setStackTableId();
		return await axios
			.post(API_URL.TEAM_STACK.DETAILS, {
				company_id,
				project_id,
				company_stack_modules_id,
				company_stack_category_id,
				team_stack_data,
			})
			.then(({ data }) => {
				this.setTeamStack(data.data.team_stack_data);
				this.setUserAccessList(data.data.userAccess);
				this.setStackTableId(data.data.id);
			});
	};

	updateTeamStack = async (id: number, data: any): Promise<any> => {
		return await axios
			.post(API_URL.TEAM_STACK.UPDATE(id), data)
			.then(this.fetchTeamStack);
	};

	deleteTeamStack = async (id: number): Promise<any> => {
		return await axios
			.delete(API_URL.TEAM_STACK.DELETE(id))
			.then(this.fetchTeamStack);
	};

	updateRoleTeamStack = async (id: number, role_id: number): Promise<any> => {
		return await axios
			.post(API_URL.TEAM_STACK.UPDATE_ROLE(id), { role_id })
			.then(this.fetchTeamStack);
	};
}
