export const Regex = {
	password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=.*[0-9]).*$/,
	mobile: /^[0-9]+$/,
	twoDecimal: /^-?\d*(\.\d{0,2})?$/,
	onlyNumeric: /^\d+$/,
	//postiveornegative:/^-?\d{1,2}(\.\d{0,2})?$/
};

// /^(?=.*[0-9])/

export const minName = 3;
export const maxName = 50;
export const minProjectName = 3;
export const maxProjectName = 100;
export const maxCompanyName = 100;
export const minMobile = 4;
export const maxMobile = 20;
export const minEmail = 3;
export const maxEmail = 70;
export const minPassword = 8;
export const maxPassword = 20;
export const minDesc = 3;
export const maxDesc = 200;
export const minAmount = 1;
export const minRoleName = 2;
export const maxTableValue = 11;
