import React, { useEffect } from "react";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import useStore from "../../../store";
import { observer } from "mobx-react";
import DeleteSvg from "../../../assets/images/deleteIcon.svg";

const DeleteModalComponent = observer(() => {
	const { COMMON, AUTH } = useStore();
	const { fetchAuthUser } = AUTH;
	const {
		deleteModal,
		setDeleteModal,
		setDeleteModalDetail,
		deleteModalDetail,
	} = COMMON;

	const closeDeleteModel = () => {
		setDeleteModal(false);
		setDeleteModalDetail();
	};

	useEffect(() => {
		//
	}, [deleteModal, deleteModalDetail])

	return (
		<>
			{deleteModalDetail && <Modal
				open={deleteModal}
				onCancel={closeDeleteModel}
				width={422}
				className="deleteModal"
				footer={[
					<Button
						key="delete"
						className="deleteBtn"
						onClick={() => {
							deleteModalDetail.deleteFunction(deleteModalDetail.deleteId)
								.then(() => {
									fetchAuthUser();
									closeDeleteModel();
								});
						}}
						
					>
						Yes, Delete {deleteModalDetail.deleteModuleName}
					</Button>,
					<Button onClick={closeDeleteModel} key="cancel" className="cancelBtn">
						Cancel
					</Button>,
				]}
			>
				<div className="deleteContent">
					<div className="deleteSign">
						<img src={DeleteSvg} alt="delete sign" />
					</div>
					<span className="deleteTitle">
						Delete {deleteModalDetail.deleteModuleName}?
					</span>

					<span className="deleteMtBoxContent">
					Are you sure you want to delete this {deleteModalDetail.deleteModuleName}?
						{/* All content will be deleted */}
					</span>
				</div>
			</Modal>}
		</>
	);
});
  
export default DeleteModalComponent;
