import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { TeamMemberProps, UpdateRoleProps } from "./TeamMemberInterface";

export class TeamMemberStore {
	teamMemberList?: TeamMemberProps[];
	teamMember?: TeamMemberProps;

	constructor() {
		makeAutoObservable(this);
	}

	setTeamMemberList = (value?: TeamMemberProps[]) => {
		this.teamMemberList = value;
	};

	setTeamMember = (value?: TeamMemberProps) => {
		this.teamMember = value;
	};

	fetchTeamMembers = async (id: number): Promise<any> => {
		this.setTeamMemberList();
		return await axios.get(API_URL.TEAM_MEMBER.INDEX(id)).then(({ data }) => {
			this.setTeamMemberList(data.data.rows);
		});
	};

	createTeamMember = async (data: any): Promise<any> => {
		return await axios.post(API_URL.TEAM_MEMBER.CREATE, data);
	};

	teamMemberDetails = async (id: number): Promise<any> => {
		return await axios.get(API_URL.TEAM_MEMBER.DETAILS(id)).then(({ data }) => {
			this.setTeamMember(data);
			return data.data;
		});
	};

	updateTeamMember = async (
		id: number,
		data: TeamMemberProps
	): Promise<any> => {
		return await axios
			.post(API_URL.TEAM_MEMBER.UPDATE(id), data)
			.then(({ data }) => {
				data;
			});
	};

	updateTeamMemberRole = async (
		id: number,
		data: UpdateRoleProps
	): Promise<any> => {
		return await axios
			.post(API_URL.TEAM_MEMBER.UPDATE_ROLE(id), data)
			.then(({ data }) => {
				return data;
			});
	};

	acceptTeamMemberInvitation = async (
		id: number,
		is_accepted: 1 | 0
	): Promise<any> => {
		return await axios
			.post(API_URL.TEAM_MEMBER.ACCEPT_INVITATION(id), { is_accepted })
			.then(({ data }) => {
				data;
			});
	};
}
