const API_URL = {
	LOGIN_WITH_EMAIL: "/user/login",
	LOGIN_AS_COMPANY: "/user/login-as-company",
	REGISTER_WITH_EMAIL: "register",
	FORGOT_PASSWORD: "/user/forgot-password",
	ME: "/user/me",
	LOGOUT: "/user/logout",
	CHANGE_PASSWORD: "/user/change-password",
	CHECK_MY_EMAIL: "/user/check-my-email",
	VERIFY_USER_CHANGE_PASSWORD: "/user/verify-user-change-password",
	UPDATE_PROFILE: (id: number): string => "/user/" + id + "/update-profile",
	RESET_PASSWORD: (otp: string): string => "/user/" + otp + "/reset-password",
	UPDATE_PROFILE_IMAGE: (id: number): string =>
		"/user/" + id + "/profile-image",
	LEAVE_ORGANIZATION: (id: number): string =>
		"/user/leave-organization/?id=" + id,

	USER: {
		LIST: "user/list",
		ASSIGN_STACK: `/user/assign-stack`,
	},
	All_TRANSACTION: {
		LIST: "trade/transactions/list",
	},
	ORGANIZATION: {
		UPDATE: (id: number): string => "/organization/" + id + "/update",
		CHANGE_LOGO: (id: number): string => "/organization/" + id + "/change-logo",
	},
	LANGUAGE_DATA: (localKey: string): string => "language/" + localKey,
	COMMON: {
		METRIC_CATEGORY_LIST: "/metric-category-list",
		SYNC: "/sync",
		METRIC_LIST: `/metric-list`,
		PRIVILEGES: (menu_type?: 1 | 2): string =>
			`/privileges-list${menu_type ? `?menu_type=${menu_type}` : ""}`,
		COMPANY_LIST: (search?: string): string => {
			if (search) {
				return "/company-list/?search=" + search;
			} else {
				return "/company-list/";
			}
		},
		ROLES: (search?: string, role_type?: 1 | 2): string =>
			`/roles-list${search ? `?search=${search}` : ""}${
				search && role_type ? `&&` : ""
			}${role_type ? `?role_type=${role_type}` : ""}`,
		STACK_MODULE_LIST: "/stackmodule-list",
		WEB_METRIC_METRIC_GROUP: `/web-metric-and-metric-group-list`,
		UPLOAD_FILE: `/upload-file`,
		DELETE_FILE: `/delete-file`,
	},
	COMPANY_PROJECT: {
		INDEX: "/company-project",
		CREATE: "/company-project/create",
		DETAILS: (id: number): string => `/company-project/${id}/details`,
		UPDATE: (id: number): string => `/company-project/${id}/update`,
		DELETE: (id: number): string => `/company-project/${id}/delete`,
		BULK_UPDATE: `/company-project/bulk-update`,
	},
	PROJECT_CATEGORY: {
		INDEX: "/project-category",
		CREATE: "/project-category/create",
		DETAILS: (id: number): string => `/project-category/${id}/details`,
		UPDATE: (id: number): string => `/project-category/${id}/update`,
		DELETE: (id: number): string => `/project-category/${id}/delete`,
		UPDATE_SEQUENCE: "/project-category/update-sequence",
	},
	REFER_CLIENT: {
		INDEX: "/refer-client",
		CREATE: "/refer-client/create",
		DETAILS: (id: number): string => `/refer-client/${id}/details`,
		UPDATE: (id: number): string => `/refer-client/${id}/update`,
		DELETE: (id: number): string => `/refer-client/${id}/delete`,
	},
	TEAM_STACK: {
		INDEX: "/team-stack",
		CREATE: "/team-stack/create",
		DETAILS: `/team-stack/details`,
		UPDATE: (id: number): string => `/team-stack/${id}/update`,
		DELETE: (id: number): string => `/team-stack/${id}/delete`,
		UPDATE_ROLE: (id: number): string => `/team-stack/${id}/update-role`,
		SAVE: "/team-stack/save",
	},
	TEAM_MEMBER: {
		INDEX: (company_id: number): string =>
			`/team-member?company_id=${company_id}`,
		CREATE: "/team-member/create",
		DETAILS: (id: number): string => `/team-member/${id}/details`,
		UPDATE: (id: number): string => `/team-member/${id}/update`,
		UPDATE_ROLE: (id: number): string => `/team-member/${id}/update-role`,
		ACCEPT_INVITATION: (id: number): string =>
			`/team-member/${id}/accept-invitation`,
	},
	COMPANY_STACK_MODULE: {
		CREATE: "/company-stack-module/create",
		DETAILS: (id: number): string => `/company-stack-module/${id}/details`,
		UPDATE: (id: number): string => `/company-stack-module/${id}/update`,
		DELETE: (id: number): string => `/company-stack-module/${id}/delete`,
		CHANGE_STATUS: (id: number, is_active: 1 | 0): string =>
			`/company-stack-module/${id}/details?is_active=${is_active}`,
		BULK_UPDATE: `/company-stack-module/bulk-update`,
		DUPLICATE_STACK: `/company-stack-module/duplicate-stack`,
	},
	COMPANY_STACK_CATEGORY: {
		INDEX: "/companystack-category",
		CREATE: "/companystack-category/create",
		DETAILS: (id: number): string => `/companystack-category/${id}/details`,
		UPDATE: (id: number): string => `/companystack-category/${id}/update`,
		DELETE: (id: number): string => `/companystack-category/${id}/delete`,
		UPDATE_SEQUENCE: "/companystack-category/update-sequence",
		BULK_UPDATE: "/companystack-category/bulk-update",
		DUPLICATE_CATEGORY: "/companystack-category/duplicate-category",
	},
	SCORECARD_STACK: {
		INDEX: "/scorecard-stack",
		CREATE: "/scorecard-stack/create",
		DETAILS: `/scorecard-stack/details`,
		UPDATE: (id: number): string => `/scorecard-stack/${id}/update`,
		DELETE: (id: number): string => `/scorecard-stack/${id}/delete`,
		SAVE: `/scorecard-stack/save`,
		NODE_ENTRY: "/scorecard-stack/node-entry",
		CHANGE_VALUE: "/scorecard-stack/change-value",
	},
	METRICS: {
		LIST: "/metrics",
		CREATE: "/metrics/create",
		UPDATE: (id: number): string => "/metrics/" + id + "/update",
		DELETE: (id: number): string => "/metrics/" + id + "/delete",
		DETAILS: (id: number): string => "/metrics/" + id + "/details",
		CHANGE_STATUS: (id: number): string => "/metrics/" + id + "/change-status",
	},
	GOAL_STACK: {
		LIST: "/goal-stack",
		DETAILS: `/goal-stack/details`,
		DELETE: (id: number): string => `/goal-stack/${id}/delete`,
		SAVE: `/goal-stack/save`,
	},
	ARCHIVE:{
		LIST:"/archive",
		SAVE:"/archive/save",
		DETAILS:(id:number)=>`/archive/${id}/details`,
		RESTORE:(id:number)=>`/archive/${id}/restore`,
	}
};

export default API_URL;
