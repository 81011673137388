/* eslint-disable no-mixed-spaces-and-tabs */
import { Card, Checkbox, Popover } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ReactComponent as SettingIcon } from "../../../../../../../../assets/images/settingIcon.svg";
import { ReactComponent as DownArrow } from "../../../../../../../../assets/images/downIcon.svg";
// import { ReactComponent as ResizeIcon } from "../../../../../../../../assets/images/resizeIcon.svg";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import DateRange from "./DateRange";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useStore from "../../../../../../../../store";
import { Link } from "react-router-dom";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	PointElement
);


//const labels = ["January", "February", "March", "April", "May", "June", "July"];

interface ButtonProps {
	open?: any;
	graphAxes?: any;
	chartId?: any;
	mode?: any;
}

const LineGraph: React.FC<ButtonProps> = observer(
	({ open, graphAxes, chartId, mode }) => {
		const [graphData, setGraphData] = useState();
		const [showDatePicker, setShowDatePicker] = useState(false);
		const [label, setLabel] = useState();
		const { SCORECARD_STACK } = useStore();
		const { setUniqueId,setEditGraphData,setDefaultDateChacked,defaultDateChecked,setGraphLabel} = SCORECARD_STACK;

		const options = {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				trendline: {
					enable: true,
				},
				legend: {
					position: "top" as const,
					labels: {
						usePointStyle: true,
						pointStyle: "circle",
					},
				},
			},
			scales: {
				x: {
					display: true,
					beginAtZero: true,
					offset: graphAxes?.label?.length == 1 ? true : false,
					grid: {
						display: false,
					},
				},
				y: {
					beginAtZero: true,
				},
			}
		};
		
		useEffect(() => {
			if (graphAxes?.bar_name === 6) {
				setGraphData(graphAxes?.datasets);
				setLabel(graphAxes?.label);
				setDefaultDateChacked(graphAxes?.dateChecked)
			}
		}, [graphAxes]);
		const onChange = (e: CheckboxChangeEvent) => {
			setDefaultDateChacked(e.target.checked)
		};
		const title = (
			<div>
				<Checkbox onChange={onChange} checked={defaultDateChecked}>Set As Default</Checkbox>
			</div>
		);
		const content = (
			<div>
				<DateRange chartId={chartId} setShowDatePicker={setShowDatePicker}/>
			</div>
		);
		const data = {
			labels: label,
			datasets: graphData ? graphData : [],
		};
		const openDrawer = (id: any) => {
			open(id);
			setUniqueId(chartId);
			setEditGraphData(graphAxes?.datasets)
			setGraphLabel(graphAxes?.label)
		};
		const handleVisibleChange = (newVisible:any) => {
			setShowDatePicker(newVisible);
		};

		return (
			<>
				<div
					className="horizontalContainer"
					
				>
					<Card>
						<div className="cardHeader">
							<h4>{graphAxes?.graph_name}</h4>
							{mode === "edit" && (
								<div className="headerIcon" onDoubleClick={() => openDrawer(6)}>
									<Link to="/">{graphAxes?.datasets?.length==0 || !graphAxes?.datasets?"Configure This Graph":""}</Link>
									<SettingIcon />
								</div>
							)}
						</div>
						<div className="cardContent">
							<Popover
								content={content}
								title={title}
								trigger="click"
								open={showDatePicker}
								placement="bottomLeft"
								overlayClassName={"graphPopover"}
								onOpenChange={handleVisibleChange}
							>
								<div onClick={()=>{setShowDatePicker(true);setEditGraphData({
											date_label: graphAxes?.date_label,
											graph_from_date: graphAxes?.graph_from_date,
											graph_to_date: graphAxes?.graph_to_date,
										});}}>
								{
									mode==="view"?<div className="popoverLink">
									{
										graphAxes?.date_label
									}
									<DownArrow />
								</div>:""
								}
								</div>
							</Popover>

							<div id="graphContainer">
								<Line data={data} options={options} />
							</div>
						</div>
						{/* <div className="cardFooter">
								<ResizeIcon />
							</div> */}
					</Card>
				</div>
			</>
		);
	}
);

export default LineGraph;
