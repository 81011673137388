import { Collapse, Select } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import ReactFlow, {
	addEdge,
	ConnectionLineType,
	Controls,
	MarkerType,
	ReactFlowProvider,
} from "reactflow";
import { Link } from "react-router-dom";
import { ReactComponent as HeaderIcon } from "../../../../../../assets/images/headerIcon.svg";
import { ReactComponent as TrashIcon } from "../../../../../../assets/images/trashIcon.svg";
// import { ReactComponent as TextFieldIcon } from "../../../../../assets/images/textFieldIcon.svg";
// import { ReactComponent as CalculationIcon } from "../../../../../../assets/images/calculationIcon.svg";
// import { ReactComponent as ButtonIcon } from "../../../../../../assets/images/buttonIcon.svg";
// import { ReactComponent as TextBoxIcon } from "../../../../../../assets/images/textBoxIcon.svg";

import { ReactComponent as LeftAlign } from "../../../../../../assets/images/leftalign.svg";
import { ReactComponent as RightAlign } from "../../../../../../assets/images/rightalign.svg";
import { ReactComponent as CenterAlign } from "../../../../../../assets/images/centeralign.svg";
import { ReactComponent as GoalIcon } from "../../../../../../assets/images/Goal.svg";
// import { ReactComponent as MetricBoxIcon } from "../../../../../../assets/images/metricBoxIcon.svg";
import FormItem from "antd/es/form/FormItem";

import DrawerView from "./MapViewNodes/DrawerView";
// import AddNewMetric from "../AddNewMetric";
import TradeNode from "./MapViewNodes/CustomNode";
import moment from "moment";
import { observer } from "mobx-react";
import { InputBox } from "../../../../../../components/AntdAddons";
import { displayFormats } from "../../../../../../config/Global";
import useStore from "../../../../../../store";
import MultiSelectionToolbar from "./MapViewNodes/MultiSelectionToolbar";
import { GoalStackProps } from "../../../../../../store/GoalStackStore/GoalStackProps";

const MapView = observer(
	({
		mode,
		nodes,
		setNodes,
		edges,
		setEdges,
		open,
		setOpen,
		onNodesChange,
		onEdgesChange,
		setReactFlowInstance,
		reactFlowInstance,
		updateNodeSize,
	}: GoalStackProps) => {
		const { COMMON, GOAL_STACK, AUTH } = useStore();
		const { fetchMetricCategoryList } = COMMON;
		const { setGoalDataState } = GOAL_STACK;
		const { company } = AUTH;

		const { Option } = Select;

		const reactFlowWrapper: any = useRef(null);
		const [nodeData, setNodeData] = useState<any>();

		// const [modalMetric, setModalMetric] = useState(false);

		// const openModalMetric = () => {
		// 	setModalMetric(true);
		// };

		// const closeModalMetric = () => {
		// 	setModalMetric(false);
		// };

		const nodeTypes = useMemo(
			() => ({
				custom: (props: any) => (
					<TradeNode
						setNodes={setNodes}
						mode={mode}
						reactFlowInstance={reactFlowInstance}
						{...props}
					/>
				),
			}),
			[mode, reactFlowInstance, setNodes]
		);

		const onConnect = useCallback((params: any, currentMode: string) => {
			setEdges((eds: any) => addEdge(params, eds));
			if (currentMode == "edit") {
				setGoalDataState(1);
			}
		}, []);

		const flowNodesDrawer = () => {
			TrashIcon;
			setOpen(true);
		};
		const onClose = () => {
			setOpen(false);
		};

		const { Panel } = Collapse;
		const [openKeys, setOpenKeys] = useState<any>(["1"]);

		const onChange = (key: string | string[]) => {
			setOpenKeys(key);
			openKeys;
		};

		let newNodeData: any;
		const handleDrop = useCallback(
			(event: any) => {
				event.preventDefault();
				const reactFlowBounds =
					reactFlowWrapper.current.getBoundingClientRect();
				const type = event.dataTransfer.getData("type");

				// check if the dropped element is valid
				if (typeof type === "undefined" || !type) {
					return;
				}

				const position = reactFlowInstance.project({
					x: event.clientX - reactFlowBounds.left,
					y: event.clientY - reactFlowBounds.top,
				});

				const node_type = event.dataTransfer.getData("node_type");

				if (node_type == "header") {
					newNodeData = { title: "header title" };
				} else if (node_type == "goal") {
					newNodeData = {
						name: "Goal Name",
						description: "Goal description",
						avatar: "",
						assigned_color: "noColor",
						assigned_to: "",
						text_color: "colorBlack",
						is_due_date: false,
						achievement_date: "",
						size: 1,
						font_size: 4,
						alignment: "alignCenter",
						goal_type: 1,
						isBold: false,
						isItalic: false,
						isUnderlined: false,
						isCapitalize: false,
					};
				}
				if (node_type == "metricBox") {
					// openModalMetric();
					fetchMetricCategoryList();
				} else {
					const newNode: any = [
						{
							id: `${moment()
								.utc()
								.format(displayFormats.NODE_DATE_TIME_FORMAT)}-${node_type}`,
							type,
							position,
							data: {
								node_created_date: moment(new Date()).format(
									displayFormats.POST_DATE_FORMAT
								),
								type: node_type,
								node_id: `${moment()
									.utc()
									.format(displayFormats.NODE_DATE_TIME_FORMAT)}-${node_type}`,
								...newNodeData,
							},
						},
					];
					setNodes((nds: any) => nds.concat(newNode));
					setGoalDataState(1);
				}
			},
			[reactFlowInstance]
		);

		const handleDragStart = (e: any, type: string, node_type: string) => {
			e.dataTransfer.effectAllowed = "move";
			e.dataTransfer.setData("type", type);
			e.dataTransfer.setData("node_type", node_type);
		};

		const handleDragOver = useCallback((e: any) => {
			e.preventDefault();
			e.dataTransfer.dropEffect = "move";
		}, []);
		const onFitView = () => {
			return true;
		};

		const afterNodeDeleted = async (deletedNodes: any) => {
			onClose();
			try {
				await deletedNodes.map(async (deletedNode: any) => {
					if (deletedNode?.parentNode) {
						const defaultValue = 25;
						let groupPositionY = defaultValue;
						let totalNodeHeight = defaultValue;

						let totalParent = 0;
						let parentData: any;
						await setNodes((nodes: any) => {
							nodes.map((node: any) => {
								if (node.id != deletedNode.id) {
									if (
										node.parentNode == deletedNode.parentNode &&
										reactFlowInstance
									) {
										totalNodeHeight +=
											defaultValue + reactFlowInstance.getNode(node.id).height;
									}
								}
								if (node.parentNode == deletedNode.parentNode) {
									totalParent++;
								}
								if (node.id == deletedNode.parentNode) {
									parentData = node;
								}
							});

							if (totalParent == 1 && parentData) {
								return nodes.filter((node: any) => node.id !== parentData.id);
							} else {
								return nodes.map((node: any) => {
									if (node.id != deletedNode.id) {
										if (node.id == deletedNode.parentNode) {
											node.style.height = totalNodeHeight;
											node.height = totalNodeHeight;
										}
										if (
											node.parentNode == deletedNode.parentNode &&
											reactFlowInstance
										) {
											node.position.x = defaultValue;
											node.position.y = groupPositionY;

											groupPositionY =
												groupPositionY +
												defaultValue +
												reactFlowInstance.getNode(node.id).height;
										}
									}
									return node;
								});
							}
						});
					}
				});
			} catch (e: any) {
				//
			}
		};
		return (
			<>
				{open && false && nodeData && (
					<div className="nodeInfoDropdown">
						<div className="nodeDropdownList">
							<div className="item">
								<div className="selectWrap">
									<InputBox.Select
										name="font"
										label={"Font:"}
										placeholder={"Roboto"}
										options={{
											list: [
												{ id: 1, name: "Roboto" },
												{ id: 2, name: "Inter" },
												{ id: 3, name: "Poppins" },
											],
											valueKey: "id",
											textKey: "name",
										}}
									/>
								</div>
							</div>
							<div className="item">
								<div className="selectWrap">
									<InputBox.Select
										name="size"
										label={"Size:"}
										placeholder={"24pt"}
										options={{
											list: [
												{ id: 1, name: "14pt" },
												{ id: 2, name: "16pt" },
												{ id: 3, name: "18pt" },
												{ id: 4, name: "20pt" },
												{ id: 5, name: "22pt" },
												{ id: 6, name: "24pt" },
											],
											valueKey: "id",
											textKey: "name",
										}}
									/>
								</div>
							</div>
							<div className="item">
								<div className="dropdownWrap">
									<FormItem></FormItem>
									<Select defaultValue="leftalign">
										<Option value="leftalign">
											<LeftAlign />
										</Option>
										<Option value="rightalign">
											<RightAlign />
										</Option>
										<Option value="centeralign">
											<CenterAlign />
										</Option>
									</Select>
								</div>
							</div>
							<div className="item">
								<div className="dropdownWrap">
									<FormItem label={"Assign Color:"}></FormItem>
									<Select defaultValue="pinkColor" showArrow={false}>
										<Option value="pinkColor">
											<div className="pinkColor"></div>
										</Option>
										<Option value="orangeColor">
											<div className="orangeColor"></div>
										</Option>
										<Option value="greenColor">
											<div className="greenColor"></div>
										</Option>
									</Select>
								</div>
							</div>
						</div>
					</div>
				)}

				{open && nodeData && reactFlowInstance && (
					<DrawerView
						setNodeData={setNodeData}
						mode={mode}
						nodes={nodes}
						setNodes={setNodes}
						nodeData={nodeData}
						open={open}
						onClose={onClose}
						reactFlowInstance={reactFlowInstance}
						updateNodeSize={updateNodeSize}
					/>
				)}
				<div className="childRight"></div>
				<ReactFlowProvider>
					<div
						className="horizontalFlow reactflow-wrapper"
						ref={reactFlowWrapper}
					>
						<ReactFlow
							// nodes={nodes}
							nodes={[
								...nodes.filter(
									(node: any) =>
										node?.data?.type == "metricBoxGroup" ||
										node?.data?.type == "dynamicGroup"
								),
								...nodes
									.filter(
										(node: any) =>
											node?.data?.type != "metricBoxGroup" &&
											node?.data?.type != "dynamicGroup"
									)
									.sort((a: any, b: any) => a?.position?.y - b?.position?.y),
							]}
							edges={edges}
							onConnect={(changes: any) => onConnect(changes, mode)}
							fitView
							nodeTypes={nodeTypes}
							panOnScroll={true}
							onDrop={handleDrop}
							onDragOver={handleDragOver}
							onNodesChange={(changes: any) => onNodesChange(changes, mode)}
							onEdgesChange={(changes: any) => onEdgesChange(changes, mode)}
							onNodesDelete={(nodes: any) => afterNodeDeleted(nodes)}
							// elementsSelectable={mode == "edit"}
							nodesConnectable={mode == "edit"}
							nodesDraggable={mode == "edit"}
							defaultEdgeOptions={{
								type: ConnectionLineType.SimpleBezier,
								markerEnd: { type: MarkerType.Arrow },
							}}
							onInit={setReactFlowInstance}
							{...(mode == "edit" && {
								onNodeClick: (event: any, node) => {
									// console.log('event', event);
									// console.log('event.target?.nodeName', event.target?.nodeName);
									// console.log('event.target?.nodeName', event.target?.parentNode.nodeName);
									// console.log('find', event.target?.classList.contains('toolBarBtn'));
									// console.log('length', event.target?.classList.length);

									event;
									setNodeData(node);
									if (
										event.target?.nodeName !== "BUTTON" &&
										node.data.type != "dynamicGroup" &&
										!event.target?.classList.contains("toolBarBtn")
									) {
										flowNodesDrawer();
									}
								},
							})}
							{...(mode == "edit"
								? {
									deleteKeyCode: ["Delete", "Backspace"],
								}
								: {
									deleteKeyCode: null,
								})}
						>
							<Controls showInteractive={false} onFitView={onFitView} />
							{mode == "edit" && (
								<MultiSelectionToolbar
									nodes={nodes}
									setNodes={setNodes}
									reactFlowInstance={reactFlowInstance}
								/>
							)}
							{!company.is_viewer && mode == "edit" && (
								<div className="EditDragDrop">
									<Collapse
										className="menuAccordion"
										accordion
										expandIconPosition={"end"}
										onChange={onChange}
									>
										<Panel header="Drag and Drop Elements" key="1">
											<ul className="submenuList">
												<li
													draggable={true}
													onDragStart={(e) =>
														handleDragStart(e, "custom", "header")
													}
												>
													<Link to={""}>
														<HeaderIcon /> Header
													</Link>
												</li>
												<li
													draggable={true}
													onDragStart={(e) =>
														handleDragStart(e, "custom", "goal")
													}
												>
													<Link to={""}>
														<GoalIcon /> Goal
													</Link>
												</li>
											</ul>
										</Panel>
									</Collapse>
								</div>
							)}
						</ReactFlow>
					</div>
				</ReactFlowProvider>
			</>
		);
	}
);

export default MapView;
