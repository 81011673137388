import React, { memo, useEffect, useState } from "react";
import { Avatar, Button, Progress, Tooltip } from "antd";
import { ReactComponent as HelpIcon } from "../../../../../../../assets/images/helpIcon.svg";
import { Handle, NodeToolbar, Position } from "reactflow";
import moment from "moment";
import { displayFormats, displayNumberFormat } from "../../../../../../../config/Global";
import { observer } from "mobx-react";
import useStore from "../../../../../../../store";
// import {
// 	NodeResizer,
// 	// NodeResizeControl
// } from "@reactflow/node-resizer";
import "@reactflow/node-resizer/dist/style.css";

const TradeNode = observer(
	({
		data,
		mode,
		edges,
		updateUndoRedoState,
		reactFlowInstance,
		setNodes,
		id: nodeId,
	}: any) => {
		const fullNodeObject = reactFlowInstance?.getNode(nodeId);
		const [nameInitial, setNameInitial] = useState<any>({
			name: "",
			initial: "",
		});
		const { COMMON } = useStore();
		const { metricFormatList } = COMMON;

		const [metricFormat, setMetricFormat] = useState(
			metricFormatList &&
				metricFormatList.find(
					(format: any) => format.id == data.format_of_metric
				)
		);

		useEffect(() => {
			if (data.user) {
				if (data.user.name && data.user.name !== " " && data.user?.last_name) {
					setNameInitial({
						name: data.user.name + " " + data.user?.last_name,
						initial: data.user.name.charAt(0) + data.user?.last_name.charAt(0),
					});
				} else if (data.user.name && data.user.name !== " ") {
					const name = data.user.name.split(" ");
					setNameInitial({
						name: data.user.name,
						initial: name[0].charAt(0) + name[1]?.charAt(0),
					});
				} else {
					setNameInitial({
						name: data.user.email,
						initial: data.user.email.charAt(0) + data.user.email.charAt(1),
					});
				}
			}
		}, [data.user]);

		const updateGroupSize = async (parentNodeId: any) => {
			try {
				const defaultValue = 25;
				let groupPositionY = defaultValue;
				let totalNodeHeight = defaultValue;
				await setNodes((nodes: any) => {
					let totalParent = 0;
					let parentData: any;
					nodes.map((node: any) => {
						if (reactFlowInstance && node.parentNode == parentNodeId) {
							totalNodeHeight +=
								defaultValue + reactFlowInstance.getNode(node.id).height;
						}
						if (node.parentNode == parentNodeId) {
							totalParent++;
						}
						if (node.id == parentNodeId) {
							parentData = node;
						}
					});

					if (totalParent == 0 && parentData) {
						const newNodes = nodes.filter(
							(node: any) => node.id !== parentData.id
						);
						updateUndoRedoState(newNodes);
						return newNodes;
					} else {
						const newNodes = nodes.map((node: any) => {
							if (node.id == parentNodeId) {
								node.style.height = totalNodeHeight;
								node.style.width = 250;
								node.height = totalNodeHeight;
								node.width = 250;
							}
							if (reactFlowInstance && node.parentNode == parentNodeId) {
								node.position.x = defaultValue;
								node.position.y = groupPositionY;

								groupPositionY =
									groupPositionY +
									defaultValue +
									reactFlowInstance.getNode(node.id).height;
							}

							return node;
						});
						return newNodes;
					}
				});
			} catch (e: any) {
				//
			}
		};

		const handleUngroup = async () => {
			let parentNodeId: any = null;
			const totalNodeIdInParent: any[] = [];
			await setNodes((nodes: any) => {
				const currentNode: any = JSON.parse(
					JSON.stringify(nodes.find((node: any) => node.id == data.node_id))
				);
				const currentParentNode: any = currentNode.parentNode
					? nodes.find((node: any) => node.id == currentNode.parentNode)
					: undefined;

				parentNodeId = currentParentNode?.id ?? null;

				nodes.map((node: any) => {
					if (node.parentNode == parentNodeId) {
						totalNodeIdInParent.push(node.id);
					}
				});

				return nodes.map((node: any) => {
					if (node.id == currentNode.id) {
						delete node.expandParent;
						const nodeIndex = totalNodeIdInParent.indexOf(node.id);
						return {
							...node,
							parentNode: undefined,
							extent: undefined,
							data: { ...node.data, isGrouped: false },
							position: {
								x: currentParentNode.position.x + currentParentNode.width + 20,
								y:
									currentParentNode.position.y +
									85 * (nodeIndex > 0 ? nodeIndex + 1 : 0),
							},
						};
					}
					return node;
				});
			});
			(await parentNodeId) && updateGroupSize(parentNodeId);
		};

		const handleCopy = async () => {
			const currentId = moment()
				.utc()
				.format(displayFormats.NODE_DATE_TIME_FORMAT);
			let parentNodeId = null;
			await setNodes((nodes: any) => {
				const newNode: any = nodes.find((node: any) => node.id == data.node_id);
				if (newNode) {
					const tempNew = JSON.parse(JSON.stringify(newNode));
					parentNodeId = tempNew?.parentNode ?? null;
					switch (tempNew.data.type) {
						case "metricBox":
							tempNew.id = `${currentId}-metricBox-${tempNew.data.id}`;
							tempNew.sequence = Number.MAX_SAFE_INTEGER;
							tempNew.data.node_id = `${currentId}-${tempNew.data.type}-${tempNew.data.id}`;
							tempNew.data.node_created_date = moment(new Date()).format(
								displayFormats.POST_DATE_FORMAT
							);
							tempNew.position.x = tempNew.position.x + 70;
							tempNew.position.y = tempNew.position.y + 70;
							//tempNew.selected = false;
							tempNew.data.value = 0;
							tempNew.data.name = tempNew.data.name + " (Copy)";
							updateUndoRedoState([...nodes, tempNew]);

							return [...nodes, tempNew];
						case "metricBoxCalculation":
							tempNew.id = `${currentId}-metricBoxCalculation-${tempNew.data.id}`;
							tempNew.data.node_id = `${currentId}-${tempNew.data.type}-${tempNew.data.id}`;
							tempNew.data.node_created_date = moment(new Date()).format(
								displayFormats.POST_DATE_FORMAT
							);
							tempNew.position.x = tempNew.position.x + 70;
							tempNew.position.y = tempNew.position.y + 70;
							//tempNew.selected = false;
							tempNew.data.value,
							tempNew.data.name = tempNew.data.name + " (Copy)";
							updateUndoRedoState([...nodes, tempNew], edges);
							return [...nodes, tempNew];
						case "button":
						case "header":
						case "textBox":
							tempNew.id = `${currentId}-${tempNew.data.type}`;
							tempNew.data.node_id = `${currentId}-${tempNew.data.type}`;
							tempNew.data.node_created_date = moment(new Date()).format(
								displayFormats.POST_DATE_FORMAT
							);
							tempNew.position.x = tempNew.position.x + 70;
							tempNew.position.y = tempNew.position.y + 70;
							//tempNew.selected = false;
							updateUndoRedoState([...nodes, tempNew]);
							return [...nodes, tempNew];
						default:
							break;
					}
				}
				return nodes;
			});

			(await parentNodeId) && updateGroupSize(parentNodeId);
		};

		useEffect(() => {
			setMetricFormat(
				metricFormatList &&
					metricFormatList.find(
						(format: any) => format.id == data.format_of_metric
					)
			);
		}, [data]);

		return (
			<div className={`react-flow__node-default`}>
					{/* <NodeResizer minWidth={200} minHeight={100} /> */}
					<div
				>
					{data.type != "button" && (
						<>
							<Handle
								style={{ backgroundColor: "#555", border: "1px solid white" }}
								type="target"
								id="left"
								position={Position.Left}
							/>
							<Handle
								style={{ backgroundColor: "#555", border: "1px solid white" }}
								type="target"
								id="top"
								position={Position.Top}
							/>
							<Handle
								style={{ backgroundColor: "#555", border: "1px solid white" }}
								type="source"
								id="right"
								position={Position.Right}
							/>
							<Handle
								style={{ backgroundColor: "#555", border: "1px solid white" }}
								type="source"
								id="bottom"
								position={Position.Bottom}
							/>
						</>
					)}
					{mode == "edit" && (
							<NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition}>
							{fullNodeObject?.parentNode && (
								<button onClick={handleUngroup}>ungroup</button>
							)}
							<button onClick={handleCopy}>Copy</button>
						</NodeToolbar>
					)}
					</div>
					{/* header */}{" "}
					{data.type == "header" && (
						<div className="header">
							<h2 style={{ margin: "auto", textAlign: "center" }}>
								{data.title}
							</h2>
						</div>
					)}
					{/* button */}
					{data.type == "button" && (
						<div className="button">
							<Button
								onClick={() => {
									mode !== "edit" && data.url && window.open(data.url);
								}}
								className="btn-primary"
							>
								{data.title}
							</Button>
						</div>
					)}
					{/* textBox */}
					{data.type == "textBox" && (
						<div className="textBox">
							<h5 className="mapHeading">
								{data.title.split(";").length > 1 && (
									<ul>
										{data.title.split(";").map((text: string, id: number) => {
											return <li key={id}>{text}</li>;
										})}
									</ul>
								)}
								{data.title.split(";").length == 1 && <span>{data.title}</span>}
							</h5>
							{/* <Handle
							style={{ backgroundColor: "#555", border: "1px solid white" }}
							type="target"
							position={Position.Left}
						/>
						<Handle
							style={{ backgroundColor: "#555", border: "1px solid white" }}
							type="source"
							position={Position.Right}
						/> */}
						</div>
					)}
					{/* metricCategory */}
					{data.type == "metricCategory" && (
						<div className="metricCategory">
							{/* <Handle
							style={{ backgroundColor: "#555", border: "1px solid white" }}
							type="target"
							position={Position.Left}
						/>
						<Handle
							style={{ backgroundColor: "#555", border: "1px solid white" }}
							type="source"
							position={Position.Right}
						/> */}
							<div className="wrap">
								<h5>{data.category_name ?? "N/A"}</h5>
							</div>
						</div>
					)}
					{/* metricBox */}
					{data.type == "metricBox" && (
						<div className="metricBox">
							{/* <Handle
							style={{ backgroundColor: "#555", border: "1px solid white" }}
							type="target"
							position={Position.Left}
						/> */}
							<div className="wrap">
								<div className="Title">
									<div className={`status ${data.assigned_color}`}></div>
									<h5
										className={`${
											data.font == 1
												? "fontRoboto"
												: data.font == 2
												? "fontInter"
												: data.font == 3
												? "fontPopins"
												: ""
										} ${
											data.font_size == 1
												? "size14"
												: data.font_size == 2
												? "size16"
												: data.font_size == 3
												? "size18"
												: data.font_size == 4
												? "size20"
												: data.font_size == 5
												? "size22"
												: data.font_size == 6
												? "size24"
												: ""
										} ${data.alignment}`}
										style={{ wordBreak: "break-word" }}
									>
										{data.name}
									</h5>
								</div>
								<div className="profilePic">
									{data.avatar ? (
										<Avatar size={24} src={data.avatar} />
									) : data.assigned_to ? (
										<Tooltip title={nameInitial.name} placement="top">
											<Avatar
												size={24}
												style={{ backgroundColor: "#4863f6" }}
												src={
													data.user?.profile_image ||
													data.user?.profile_image != ""
														? data.user?.profile_image
														: null
												}
											>
												{nameInitial.initial}
											</Avatar>
										</Tooltip>
									) : (
										""
									)}
								</div>
							</div>
							<div className="goal">
								<div className="icon">
									{/* NotificationGreyIcon */}
									<svg
										width="16"
										height="18"
										viewBox="0 0 16 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M14.4747 8.70453C13.8659 7.99366 13.5893 7.37763 13.5893 6.33104V5.97519C13.5893 4.61136 13.2754 3.73264 12.593 2.85391C11.5411 1.48924 9.7704 0.666748 8.03694 0.666748H7.96323C6.26621 0.666748 4.55097 1.45147 3.48091 2.76075C2.76119 3.6571 2.41085 4.57359 2.41085 5.97519V6.33104C2.41085 7.37763 2.15245 7.99366 1.52549 8.70453C1.06417 9.22824 0.916748 9.90134 0.916748 10.6298C0.916748 11.3592 1.1561 12.0499 1.63647 12.6114C2.26343 13.2845 3.1488 13.7142 4.05321 13.7889C5.36262 13.9383 6.67203 13.9945 8.0005 13.9945C9.32813 13.9945 10.6375 13.9005 11.9478 13.7889C12.8514 13.7142 13.7367 13.2845 14.3637 12.6114C14.8432 12.0499 15.0834 11.3592 15.0834 10.6298C15.0834 9.90134 14.936 9.22824 14.4747 8.70453"
											fill={data.reminder ? "blue" : "#BABECB"}
										/>
										<path
											opacity="0.4"
											d="M9.67396 15.0238C9.25737 14.9348 6.71888 14.9348 6.30229 15.0238C5.94616 15.106 5.56104 15.2974 5.56104 15.717C5.58174 16.1173 5.81613 16.4707 6.14079 16.6948L6.13996 16.6956C6.55987 17.0229 7.05266 17.2311 7.56864 17.3058C7.8436 17.3435 8.12354 17.3418 8.40845 17.3058C8.9236 17.2311 9.41639 17.0229 9.8363 16.6956L9.83547 16.6948C10.1601 16.4707 10.3945 16.1173 10.4152 15.717C10.4152 15.2974 10.0301 15.106 9.67396 15.0238"
											fill={data.reminder ? "blue" : "#BABECB"}
										/>
									</svg>
								</div>
								{metricFormat?.name == "$" ? (
									<span>
										{metricFormat?.name}
										{data.value}
									</span>
								) : (
									<span>
										{data.value}
										{metricFormat
											? metricFormat?.name != "Qty"
												? metricFormat?.name
												: ""
											: ""}
									</span>
								)}
								{data.note && (
									<div className="messageInfo">
										<Tooltip title={data.note ?? ""}>
											<HelpIcon />
										</Tooltip>
									</div>
								)}
							</div>
							{data.track_goal ? (
								<div className="progressBar">
									<Progress
										strokeColor={
											data.assigned_color && data.assigned_color != "noColor" && data.assigned_color != ""
											? (data.assigned_color.replace("color", "") == "Pink" ? "red" : data.assigned_color.replace("color", "")) : ""
										}
										percent={data.goal_percentage ?? 0}
										size="small"
									/>
								</div>
							) : (
								""
							)}
							<div className="goalWrap">
								<div className="goalAmount">
									{data.goal > 0 && (
										<>
											Goal:{data?.goal_type == 1 ? ">" : "<"}
											<strong>
												{metricFormat?.name == "$"
													? `${metricFormat?.name}${displayNumberFormat(data.goal)}`
													: metricFormat
													? `${displayNumberFormat(data.goal)}${metricFormat?.name}`
													: `${displayNumberFormat(data.goal)}`}
											</strong>
										</>
									)}
								</div>
								<div className="date">
									{data.goal_target_date
										? moment(data.goal_target_date).format(
												displayFormats.DATE_FORMAT
										)
										: ""}
								</div>
							</div>
						</div>
					)}
					{/* metricBoxCalculation */}
					{data.type == "metricBoxCalculation" && (
						<div className="metricBoxCalculation metricBox">
							{/* <Handle
							style={{ backgroundColor: "#555", border: "1px solid white" }}
							type="target"
							position={Position.Left}
						/> */}
							<div className="wrap">
								<div className="Title">
									<div className={`status ${data.assigned_color}`}></div>
								</div>
								<h5>{data.name ?? "Calculation Name"}</h5>
							</div>

							<div className="goal">
								{metricFormat?.name == "$" ? (
									<span>
										{metricFormat?.name}
										{data.value}
									</span>
								) : (
									<span>
										{data.value}
										{metricFormat
											? metricFormat?.name != "Qty"
												? metricFormat?.name
												: ""
											: ""}
									</span>
								)}
							</div>

							<div className="goalWrap">
								<div className="goalAmount">
									<i>
										Expression: <span>{data.expression_readable ?? ""}</span>
									</i>
								</div>
							</div>
						</div>
					)}
			</div>
		);
	}
);
export default memo(TradeNode);

// setNodes((nodes: any) => {
// 	const parentNode: any = JSON.parse(
// 		JSON.stringify(nodes.find((node: any) => node.id == currentNode.id))
// 	);
// 	let newNodes: any = nodes.map((node: any) => {
// 		if (node.id === currentNode?.parentNode) {
// 			let xMin = Number.MAX_SAFE_INTEGER;
// 			let yMin = Number.MAX_SAFE_INTEGER;
// 			let xMax = Number.MIN_SAFE_INTEGER;
// 			let yMax = Number.MIN_SAFE_INTEGER;
// 			let width = 0;
// 			let height = 0;
// 			const subNodes = nodes.filter((subNode: any) => {
// 				return subNode.parentNode == currentNode.parentNode;
// 			});
// 			if (subNodes?.length) {
// 				subNodes.map((subNode: any) => {
// 					if (subNode.position) {
// 						if (subNode.position.x > xMax) {
// 							xMax = subNode.position.x;
// 							width = subNode.width;
// 						}
// 						if (subNode.position.y > yMax) {
// 							height = subNode.height;
// 							yMax = subNode.position.y;
// 						}
// 						if (subNode.position.x < xMin) {
// 							xMin = subNode.position.x;
// 						}
// 						if (subNode.position.y < yMin) {
// 							yMin = subNode.position.y;
// 						}
// 					}
// 					return subNode;
// 				});
// 			}
// 			return {
// 				...node,
// 				style: {
// 					height: yMax + height - yMin,
// 					width: xMax + width - xMin,
// 				},
// 			};
// 		}
// 		if (node.id === data.node_id) {
// 			node.parentNode = undefined;
// 			node.extent = undefined;
// 			node.data.isGrouped = false;
// 			node.position = {
// 				x: parentNode.position.x + node.position.x,
// 				y: parentNode.position.y + node.position.y,
// 			};
// 		}
// 		return node;
// 	});
// 	newNodes = JSON.parse(JSON.stringify(newNodes)).map((node: any) => {
// 		let y = 5;
// 		if(node?.parentNode==currentNode.id){

// 		}
// 		return node;
// 	});
// 	return newNodes;
// });
