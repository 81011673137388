import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import MacImg from "../../../assets/images/macDashboardImg.png";
import LogoComponent from "../../layouts/Components/LogoComponent";
import { LoginRequest } from "../../../requests/AuthRequest";
import { useTranslation } from "react-i18next";
import { stringDecryption, stringEncryption } from "../../../config/Global";

const Login: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const navigate = useNavigate();
	const { AUTH, ROOT } = useStore();
	const { doLogin, token } = AUTH;
	const { assignErrorToInput } = ROOT;
	const { t } = useTranslation();

	useEffect(() => {
		if (token) {
			navigate("/");
		}
		const rembrInfo: any = localStorage.getItem("rembrInfo");
		if (rembrInfo) {
			const rememberInfo: string = stringDecryption(rembrInfo);
			if (JSON.parse(rememberInfo)) {
				const credentials = JSON.parse(rememberInfo);
				if (credentials.endUnme && credentials.endPswd) {
					form.setFieldsValue({
						username: credentials.endUnme,
						password: credentials.endPswd,
						remember: true,
					});
				}
			}
		}
	}, []);

	const handleSubmit = (formData: any) => {
		setSaving(true);
		doLogin(formData)
			.then((data: any) => {
				if (formData?.remember) {
					const rememberInfo = {
						endUnme: formData.username,
						endPswd: formData.password,
					};
					const rembrInfo = stringEncryption(JSON.stringify(rememberInfo));
					localStorage.setItem("rembrInfo", rembrInfo);
				} else {
					localStorage.removeItem("rembrInfo");
				}
				if (!data.data?.user?.first_name) {
					navigate("/setting/profile", { replace: true });
				} else {
					navigate("/dashboard", { replace: true });
				}
			})
			.catch((e: ErrorProps) => {
				assignErrorToInput(form, e?.errors);
				setSaving(false);
			});
	};

	return (
		<section className="authPagesSection">
			<div className="authLeft">
				<div className="thumb">
					<img src={MacImg} alt="" />
				</div>
				<div className="contentWrap">
					<h1>The Entrepreneur&apos;s Stack</h1>
					<p>Take Control of Your Business and Create Radical Results.</p>
				</div>
			</div>
			<div className="authRight">
				<LogoComponent />
				<div className="formWrap">
					<h2>Login</h2>
					<FormBox form={form} onFinish={handleSubmit}>
						<InputBox.Text
							name="username"
							addonAfter={t("labels_email")}
							autoFocus={true}
							size="large"
							rules={LoginRequest(t).email}
						/>
						<InputBox.Password
							name="password"
							addonAfter={t("labels_password")}
							size="large"
							className="passwordField"
							rules={LoginRequest(t).password}
						// extra={[
						// 	<Link
						// 		key={"recovery"}
						// 		style={{ border: "0px", display: "inline" }}
						// 		to={"/recover-password"}
						// 	>
						// 		Forgot Password
						// 	</Link>,
						// ]}
						/>
						<Row className="formRow">
						<Form.Item
							name="remember"
							valuePropName="checked"
							// wrapperCol={{ offset: 8, span: 16 }}
							className="rememberMe"
						>
							<Checkbox>Remember me</Checkbox>
						</Form.Item>
							<div className="forgetPassword">
						<Link
							key={"recovery"}
							style={{ border: "0px", display: "inline" }}
							to={"/recover-password"}
						>
							Forgot Password
						</Link>
						</div>
					
						</Row>
						<Button
							className="btn-primary"
							loading={saving}
							size="large"
							htmlType="submit"
						>
							Login
						</Button>
						{/* <div className="registerNowText">
							<p>
								Don’t Have An Account? <Link to="/register">Sign Up</Link>
							</p>
						</div> */}
					</FormBox>
				</div>
			</div>
		</section>
	);
};

export default Login;
