import React from "react";
import GoalStack from "./StackViews/GoalStack";
const ScorecardStack = React.lazy(
	() => import("./StackViews/ScorecardStack")
);
const TeamStack = React.lazy(() => import("./StackViews/TeamStack"));

const StackView = ({ stack }: any) => {
	const stackCategories = stack.stackCategory.map((tab: any) => {
		return {
			key: tab.category_id,
			label: tab.name,
			children: tab.name,
		};
	});
	return stack.key === "SCORECARD_STACK" ? (
		<ScorecardStack stackCategories={stackCategories} />
	) : stack.key === "TEAM_STACK" ? (
		<TeamStack stackCategories={stackCategories} />
	) :stack.key === "GOAL_STACK" ? (
		<GoalStack stackCategories={stackCategories} />
	) : (
		<h2>Coming Soon..</h2>
	);
};

export default StackView;
