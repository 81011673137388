import { Card, Checkbox, Popover } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ReactComponent as SettingIcon } from "../../../../../../../../assets/images/settingIcon.svg";
import { ReactComponent as DownArrow } from "../../../../../../../../assets/images/downIcon.svg";
import { ReactComponent as DownRedIcon } from "../../../../../../../../assets/images/downFill.svg";
import { ReactComponent as UpGreenIcon } from "../../../../../../../../assets/images/upfillIcon.svg";
// import { ReactComponent as UserIcon } from "../../../../../../../../assets/images/userLogo.svg";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import DateRange from "./DateRange";
import useStore from "../../../../../../../../store";
import { Link } from "react-router-dom";

interface ButtonProps {
	open?: any;
	graphAxes?: any;
	chartId?: any;
	mode?: any;
}
const AdSpend: React.FC<ButtonProps> = observer(
	({ open, graphAxes, chartId, mode }) => {
		//const [graphData, setGraphData] = useState<any>();
		const { SCORECARD_STACK } = useStore();
		const [showDatePicker, setShowDatePicker] = useState(false);
		const {
			setUniqueId,
			setEditGraphData,
			setDefaultDateChacked,
			defaultDateChecked,
		} = SCORECARD_STACK;

		useEffect(() => {
			if (graphAxes?.bar_name === 3) {
				setDefaultDateChacked(graphAxes?.dateChecked);
			}
		}, [graphAxes]);

		const onChange = (e: CheckboxChangeEvent) => {
			setDefaultDateChacked(e.target.checked);
		};

		const title = (
			<div>
				<Checkbox onChange={onChange} checked={defaultDateChecked}>
					Set As Default
				</Checkbox>
			</div>
		);
		const content = (
			<div>
				<DateRange chartId={chartId} setShowDatePicker={setShowDatePicker} />
			</div>
		);
		const openDrawer = (id: any) => {
			open(id);
			setUniqueId(chartId);
			setEditGraphData(graphAxes);
		};

		const handleVisibleChange = (newVisible: any) => {
			setShowDatePicker(newVisible);
		};

		return (
			<>
				<div className="horizontalContainer">
					<Card>
						<div className="cardHeader">
							<div className="profileContent">
								<h4>
									{graphAxes?.bar_name === 3 ? graphAxes?.graph_name : ""}
								</h4>
							</div>
							{mode === "edit" && (
								<div className="headerIcon" onDoubleClick={() => openDrawer(3)}>
									<Link to="/">
										{!graphAxes?.matricData ? "Configure This Graph" : ""}
									</Link>
									<SettingIcon />
								</div>
							)}
						</div>
						<div className="cardContent">
							<Popover
								content={content}
								title={title}
								trigger="click"
								open={showDatePicker}
								placement="bottomLeft"
								overlayClassName={"graphPopover"}
								onOpenChange={handleVisibleChange}
							>
								{mode === "view" ? (
									<div
										className="popoverLink"
										onClick={() => {
											setShowDatePicker(true);
											setEditGraphData({
												date_label: graphAxes?.date_label,
												graph_from_date: graphAxes?.graph_from_date,
												graph_to_date: graphAxes?.graph_to_date,
											});
										}}
									>
										{graphAxes?.date_label}
										<DownArrow />
									</div>
								) : (
									""
								)}
							</Popover>
							<div className="sessionHeader">
								<div className="updownRatio">
									<h2>
										{graphAxes?.format_of_metric === "$" ? graphAxes?.format_of_metric + graphAxes?.display_value
											: (graphAxes?.format_of_metric === "%" ? graphAxes?.display_value + graphAxes?.format_of_metric
											: (!graphAxes?.display_value ? 0 : graphAxes?.display_value))}
									</h2>
									{graphAxes?.compare_goal == true && (
										<>
											<div
												className={
													graphAxes?.ad_spent >= graphAxes?.total_goals
														? "greenBg"
														: "redBg"
												}
											>
												<span className="numberUp">
													{graphAxes?.ad_spent >= graphAxes?.total_goals ? (
														<UpGreenIcon />
													) : (
														<DownRedIcon />
													)}
													{graphAxes?.display_goal_difference}
												</span>
											</div>
											<div
												style={{
													backgroundColor: "#c5c5f5a3",
													color: "#000",
													borderRadius: "20px",
													padding: "2px 6px",
													marginLeft: "10px",
												}}
											>
												<span className="numberUp">
													{!graphAxes?.goal_percentage
														? "0 %"
														: graphAxes?.goal_percentage + " %"}
												</span>
											</div>
										</>
									)}
								</div>
								{graphAxes?.compare_goal == true && (
									<div className="prevData">
										<span>
											{graphAxes?.bar_name === 3
												? `vs ${graphAxes?.display_total_goals} (goal)`
												: ""}
										</span>
									</div>
								)}
							</div>
						</div>
						{/* <div className="cardFooter">
								<ResizeIcon />
							</div> */}
					</Card>
				</div>
			</>
		);
	}
);

export default AdSpend;
